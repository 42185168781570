<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <!-- Czaty -->
        <div class="card mb-4" style="min-height: 750px">
          <div class="card-header pb-1">
            <h5>Czaty</h5>
          </div>
          <div class="card-body px-3 pt-1">
            <SimpleTypeahead
              ref="filter_fullName"
              v-model="filter_fullName"
              :items="usersAC"
              :min-input-length="1"
              class="w-100 px-3 py-2 text-sm"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              style="border: none; border-radius: 10px"
              :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
              @select-item="selectItem"
            ></SimpleTypeahead>
            
            <div
              v-for="item in conversations"
              :key="item"
              class="d-flex align-items-center mt-3 cursor-pointer p-3 bg"
              :style="conversation && conversation.id === item.id ? isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)' : ''"
              style="border-radius: 8px" @click="item.seen ? patchMessage(item.seen, item.id) : getConversation(item.id, true)"
            >
              <material-avatar
                :img="$getAvatar(item.recipient.fullName, item.recipient.avatar)"
                alt="bruce"
                size="md"
                shadow="sm"
                circular
              />

              <div class="h-100 ms-3">
                <h6 class="mb-0 font-weight-bolder">
                  {{ item.recipient.fullName }}
                  <span v-if="item.recipient.status === 'Aktywny'" class="ms-1" style="color: #25DA28">&bull;</span>
                </h6>

                <p
                  v-if="item.messages.length !== 0"
                  class="mb-0 text-xs"
                >
                  {{ [item.messages[item.messages.length - 1].text.length >= 18 ? item.messages[item.messages.length - 1].text.substring(0, 18) + "..." : item.messages[item.messages.length - 1].text] + " &bull; " + moment(item.messages[item.messages.length -1].createdAt).fromNow() }}</p>
              </div>

              <span v-if="item.seen" class="ms-auto bg-danger" style="width: 5px; height: 5px; border-radius: 50%"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Wiadomości -->
      <div class="col-lg-9">
        <div class="card" style="height: 750px">
          <div v-if="conversation" class="card-header pb-1">
            <div class="d-flex align-items-center">
              <material-avatar
                :img="$getAvatar(conversation.recipient.fullName, conversation.recipient.avatar)"
                alt="bruce"
                size="md"
                shadow="sm"
                circular
              />

              <div class="h-100 ms-3">
                <h6 class="mb-0 font-weight-bolder">{{ conversation.recipient.fullName }}</h6>

                <p v-if="conversation.recipient.roles.includes('ROLE_MESSAGES') && conversation.recipient.status === 'Aktywny'" class="mb-0 text-xs d-flex align-items-center">
                  <span class="text-success">&#x25cf; &nbsp;</span> Aktywny/a teraz
                </p>

                <p v-if="conversation.recipient.roles.includes('ROLE_MESSAGES') && conversation.recipient.status === 'Nieaktywny'" class="mb-0 text-xs d-flex align-items-center">
                  <span class="text-dark">&#x25cf; &nbsp;</span> Nieaktywny/a teraz
                </p>

                <p v-if="!conversation.recipient.roles.includes('ROLE_MESSAGES') || conversation.recipient.status === 'Dezaktywowany' || conversation.recipient.status === 'Usunięty'" class="mb-0 text-xs d-flex align-items-center">
                  <span class="text-danger">&#x25cf; &nbsp;</span> Brak dostępu
                </p>
              </div>
            </div>
          </div>

          <hr v-if="conversation" class="w-100 horizontal mb-0" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />

          <div v-if="conversation" id="messages" class="card-body py-1 mx-1 h-100 d-flex flex-column" style="overflow-y: auto">
            <div v-for="(item, index) in messages" :key="item" class="mb-3" :class="index === 0 ? 'mt-3' : ''">
              <div
                v-if="item && !item.id"
                class="text-center text-xs text-capitalize"
              >
                {{ moment(item).format("dddd DD/MM/YYYY") }}
              </div>

              <div
                v-if="item && item.id"
                class="d-inline-block position-relative px-3 py-2 text-sm"
                :class="item.sender.id === me.id ? 'float-end' : ''"
                style="max-width: 60%; border-radius: 10px"
                :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
              >
                {{ item.text }}

                <div class="w-100 d-flex align-items-center justify-content-between m-0 mt-1 text-xs">
                  <div class="d-flex align-items-center m-0 me-1 text-xs">
                    <i class="material-icons me-1 text-xs">done_all</i>
                    {{ moment(item.createdAt).format("HH:mm") }}
                  </div>

                  <i
                    v-if="item.sender.id === me.id && item.isLiked"
                    class="material-symbols-outlined ms-1 text-xs"
                  >
                    thumb_up
                  </i>
                </div>

                <a
                  v-if="item.sender.id !== me.id"
                  class="position-absolute rounded-circle text-center cursor-pointer"
                  :class="item.isLiked ? `bg-gradient-${ color } text-light` : 'bg-gradient-light text-dark'"
                  style="width: 26px; height: 26px; bottom: -13px; right: -13px"
                  @click="item.isLiked = !item.isLiked; patchMessageLike(item.id, item.isLiked)"
                >
                  <i class="material-symbols-outlined text-sm" style="margin-top: 2px">thumb_up</i>
                </a>
              </div>
            </div>
          </div>

          <hr v-if="conversation" class="w-100 horizontal mt-0" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />

          <div v-if="conversation" class="d-flex mx-3 mb-3">
            <div class="input-group input-group-outline">
              <input
                v-model="message"
                class="form-control"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
              />
            </div>

            <material-button
              class="ms-2"
              :color="color"
              variant="gradient"
              @click="postMessage()"
            >
              <i class="material-icons">send</i>
            </material-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import ConversationDataService from "@/services/ConversationDataService"
import MessageDataService from "@/services/MessageDataService"
import { mapState } from "vuex"

export default {
  name: "Messages",
  components: {
    MaterialAvatar,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      conversations: [], // Tablica konwersacji użytkownika
      conversation: null, // Obiekt obecnie wybranej konwersacji

      messages: [], // Tablica wiadomości użytkownika
      message: "", // Treść wiadomości

      users: [], // Tablica dostępnych użytkowników
      usersAC: [], // Tablica imion i nazwisk dostępnych użytkowników
      filter_fullName: "", // Filtr: Imię i nazwisko
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    if (this.$route.query.asom === '1') {
      this.$store.state.showSidenav = false
      this.$store.state.showNavbar = false
      this.$store.state.showFooter = false
    }
    
    this.getMe()
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getConversations()
      if (this.conversation) {
        this.getConversation(this.conversation.id, false)
      }
    }, 5000)
  },
  methods: {
    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MESSAGES")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getUsers()
          this.getConversations()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          if (item.id !== this.me.id) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca konwersacje użytkownika
    getConversations() {
      ConversationDataService.getAll(`?members.id=${ this.me.id }&order[messages.createdAt]=desc&exists[messages]=true`)
      .then(res => {
        this.conversations = []

        res.data["hydra:member"].forEach(item => {
          item.members.forEach(member => {
            if (member.id !== this.me.id) {
              item.recipient = member
            }
          })

          item.seen = null

          if (item.messages.length !== 0 && item.messages[item.messages.length - 1].sender.id !== this.me.id && !item.messages[item.messages.length - 1].seen) {
            item.seen = item.messages[item.messages.length - 1].id
          }

          this.conversations.push(item)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybraną konwersacje
    getConversation(id, opened) {
      ConversationDataService.get(id)
      .then(res => {
        this.getConversations()
        this.conversation = res.data

        this.conversation.members.forEach(item => {
          if (item.id !== this.me.id) {
            this.conversation.recipient = item
          }
        })

        this.getMessages(res.data.id)

        if (opened) {
          setTimeout(function() {
            document.getElementById("messages").scrollTop = document.getElementById("messages").scrollHeight
          }, 100)
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybranej konwersacji
    getMessages(id) {
      MessageDataService.getAll(`?conversation.id=${ id }&order[createdAt]=desc`)
      .then(res => {
        this.messages = []
        let date = ""
        
        res.data["hydra:member"].forEach(item => {
          if (this.moment(item.createdAt).format("YYYY-MM-DD") !== date) {
            date = this.moment(item.createdAt).format("YYYY-MM-DD")
            this.messages.push(item.createdAt)
            this.messages.push(item)
          }
          else {
            this.messages.push(item)
          }
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca istnienie konwersacji
    checkConversation(sender, recipient) {
      ConversationDataService.getAll(`?members.id=${ sender }`)
      .then(res => {
        let exist = false

        res.data["hydra:member"].forEach(item => {
          item.members.forEach(member => {
            if (member.id === recipient) {
              this.getConversation(item.id, true)
              exist = true
            }
          })
        })

        if (!exist) {
          this.postConversation(sender, recipient)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nową konwersacje
    postConversation(sender, recipient) {
      ConversationDataService.post(
        {
          members: [`/users/${ sender }`, `/users/${ recipient }`]
        }
      )
      .then(res => {
        console.log(res.data)
        this.getConversations()
        this.getConversation(res.data.id, true)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nową wiadomość
    postMessage() {
      MessageDataService.post(
        {
          sender: `/users/${ this.me.id }`,
          text: this.message,
          createdAt: this.moment(),
          seen: false,
          isLiked: false,
          conversation: `/conversations/${ this.conversation.id }`
        }
      )
      .then(res => {
        console.log(res.data)
        this.message = ""
        this.getConversations()
        this.getConversation(this.conversation.id, true)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wiadomość
    patchMessage(id, cid) {
      MessageDataService.patch(id,
        {
          seen: true
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getConversations()
        this.getConversation(cid, true)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wiadomość
    patchMessageLike(id, like) {
      MessageDataService.patch(id,
        {
          isLiked: like
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybrane imię i nazwisko do zmiennej
    selectItem(item) {
      this.users.forEach(user => {
        if (user.fullName === item) {
          this.checkConversation(this.me.id, user.id)
        }
      })

      this.$refs.filter_fullName.clearInput()
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.simple-typeahead {
  color: #000;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.2);
  border-radius: 10px;
}
</style>>