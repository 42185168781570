import http from "@/http-common"

class TaskGroupDataService {
    getAll(url) {
        return http.get(`task_groups${ url }`)
    }

    get(id) {
        return http.get(`task_groups/${ id }`)
    }

    post(data) {
        return http.post('task_groups', data)
    }

    patch(id, data, headers) {
        return http.patch(`task_groups/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`task_groups/${ id }`)
    }
}

export default new TaskGroupDataService()