import http from "@/http-common"

class NotificationDataService {
    getAll(url) {
        return http.get(`notifications${ url }`)
    }

    get(id) {
        return http.get(`notifications/${ id }`)
    }

    post(data) {
        return http.post('notifications', data)
    }

    patch(id, data, headers) {
        return http.patch(`notifications/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`notifications/${ id }`)
    }
}

export default new NotificationDataService()