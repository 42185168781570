<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedProducts.length === 1">Czy na pewno chcesz usunąć {{ checkedProducts.length }} produkt?</div>
            <div v-if="checkedProducts.length > 1 && checkedProducts.length < 5">Czy na pewno chcesz usunąć {{ checkedProducts.length }} produkty?</div>
            <div v-if="checkedProducts.length > 4">Czy na pewno chcesz usunąć {{ checkedProducts.length }} produktów?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteProducts(); unmarkProducts(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Import zakończył się sukcesem -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Import zakończył się sukcesem</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Pomyślnie zaimportowano asortyment!

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="closeModal()"
              >
                OK
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex justify-content-between mb-4">
      <!-- Export / Import -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        + &nbsp; Excel
      </material-button>
      <ul class="dropdown-menu px-2 shadow">
        <!-- Export asortymentu -->
        <li>
          <a class="dropdown-item border-radius-md d-flex align-items-center ps-2 cursor-pointer" @click="exportProducts()">
            <i class="material-symbols-outlined me-2">file_download</i> Export
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <!-- Import asortymentu -->
        <li>
          <label class="w-100 m-0 p-0 cursor-pointer">
            <a class="dropdown-item border-radius-md d-flex align-items-center ps-2">
              <i class="material-symbols-outlined me-2">file_upload</i> Import
              <input type="file" class="d-none" @change="importProducts($event)">
            </a>
          </label>
        </li>
      </ul>

      <div class="d-flex align-items-center">
        <!-- Filtr: Stworzony przez -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Stworzony przez
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li v-for="item in users" :key="item">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeCreator(item.fullName)"
            >{{ item.fullName }}</a>
          </li>
          <li v-if="users.length != 0">
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_creators = []; getProducts(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Kategoria -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Kategoria
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li v-for="item in categories" :key="item">
            <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeCategory(item.name)">
              {{ item.name }}
            </a>
          </li>
          <li v-if="categories.length !== 0">
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_categories = []; getProducts(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Typ -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Typ
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeType('service')"
            >Usługa</a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeType('product')"
            >Towar</a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeType('external')"
            >Usługa zewnętrzna</a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_types = []; getProducts(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Przycisk: Nowy produkt -->
        <material-button
          :color="color"
          variant="gradient"
          @click="product && product.operation === 'new' ? product = null : getNewProduct()"
        >
          + &nbsp; Nowy produkt
        </material-button>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_creators"
        :key="item"
        color="danger"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCreator(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_categories"
        :key="item"
        color="warning"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCategory(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_types"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item === "service" ? "Usługa" : item === "product" ? "Towar" : "Usługa zewnętrzna" }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeType(item)">close</i>
      </material-badge>
    </div>

    <div class="row">
      <div class="mb-4" :class="product ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Asortyment</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="searchRef"
                      v-model="filter_name"
                      :items="productsAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="markProducts($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 30%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('symbol')">Symbol</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('category.name')">Kategoria</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('sellingPriceNet')">Netto</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="checkedProducts.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(0)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in products"
                        :key="item"
                        :class="product && product.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null"
                      >
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="markProduct($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Symbol -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.symbol ? item.symbol : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Kategoria -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.category ? item.category.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Cena sprzedaży (Netto) -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.type === 'service' ? item.sellingPriceNet * item.rbh : item.sellingPriceNet }} PLN</p>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getProduct(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getProduct(item.id, 'edit')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unmarkProducts(); item.mark = true; checkedProducts.push(item); openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak produktów -->
                      <tr :class="products.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="6">Nie znaleziono produktów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="products.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowy produkt -->
      <div v-if="product && product.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'product'"
                    @click="product.type = 'product'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = 0; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Towar</span>
                </label>
              </div>
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'service'"
                    @click="product.type = 'service'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = defaultRate; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Usługa</span>
                </label>
              </div>
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'external'"
                    @click="product.type = 'external'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = 0; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Usługa zewnętrzna</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa produktu
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="product.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1 d-flex">
                  Kategoria
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="product.category"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="onChangeVAT($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in categories" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">Jednostka miary</label>
                <select
                  v-model="product.measureUnit"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Szt">Szt</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Metry bieżące">Metry bieżące</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Opakowania">Opakowania</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Kpl">Kpl</option>
                  <option v-if="product.type === 'service'" value="Rbh">Rbh</option>
                  <option v-if="product.type === 'service'" value="Usł">Usł</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Symbol</label>
                  <input
                    v-model="product.symbol"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Producent</label>
                  <input
                    v-model="product.producer"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Notatka</label>
                  <input
                    v-model="product.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <h5 class="my-3">Ceny</h5>

            <div v-if="product.type === 'service'" class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Ilość roboczogodzin</label>
                  <input
                    v-model="product.rbh"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Cena zakupu (Netto)</label>
                  <input
                    v-model="product.purchasePriceNet"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Cena sprzedaży (Netto)</label>

                  <input
                    v-if="product.type === 'service'"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :value="product.sellingPriceNet * product.rbh"
                    disabled
                  />

                  <input
                    v-else
                    v-model="product.sellingPriceNet"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">VAT</label>
                <select
                  v-model="product.vat"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="8">8%</option>
                  <option value="23">23%</option>
                  <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                </select>
              </div>
            </div>

            <h5 class="mt-3">Powiązane produkty</h5>

            <SimpleTypeahead
              ref="productsRef"
              :items="availableProductsAC"
              :min-input-length="0"
              class="w-100 pb-2 text-sm border-secondary"
              style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              @select-item="selectProduct"
            ></SimpleTypeahead>

            <div v-if="product.subproducts.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak powiązanych produktów
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in product.subproducts"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.name }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="availableProducts.push(item); availableProductsAC.push(item.name); product.subproducts.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostProduct()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="product = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja produktu -->
      <div v-if="product && product.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'product'" @click="product.type = 'product'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = 0; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Towar</span>
                </label>
              </div>
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'service'" @click="product.type = 'service'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = defaultRate; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Usługa</span>
                </label>
              </div>
              <div class="col-md-4">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="product.type === 'external'" @click="product.type = 'external'; product.rbh = 1; product.purchasePriceNet = 0; product.sellingPriceNet = 0; product.measureUnit = ''"
                  />
                  <span class="text-sm mt-1">Usługa zewnętrzna</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa produktu
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="product.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1 d-flex">
                  Kategoria
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="product.category"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="onChangeVAT($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in categories" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">Jednostka miary</label>
                <select
                  v-model="product.measureUnit"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Szt">Szt</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Metry bieżące">Metry bieżące</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Opakowania">Opakowania</option>
                  <option v-if="product.type === 'product' || product.type === 'external'" value="Kpl">Kpl</option>
                  <option v-if="product.type === 'service'" value="Rbh">Rbh</option>
                  <option v-if="product.type === 'service'" value="Usł">Usł</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Symbol</label>
                  <input
                    v-model="product.symbol"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Producent</label>
                  <input
                    v-model="product.producer"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Notatka</label>
                  <input
                    v-model="product.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <h5 class="my-3">Ceny</h5>

            <div v-if="product.type === 'service'" class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Ilość roboczogodzin</label>
                  <input
                    v-model="product.rbh"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Cena zakupu (Netto)</label>
                  <input
                    v-model="product.purchasePriceNet"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Cena sprzedaży (Netto)</label>

                  <input
                    v-if="product.type === 'service'"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :value="product.sellingPriceNet * product.rbh"
                    disabled
                  />

                  <input
                    v-else
                    v-model="product.sellingPriceNet"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">VAT</label>
                <select
                  v-model="product.vat"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="8">8%</option>
                  <option value="23">23%</option>
                  <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                </select>
              </div>
            </div>

            <h5 class="mt-3">Powiązane produkty</h5>

            <SimpleTypeahead
              ref="productsRef"
              :items="availableProductsAC"
              :min-input-length="0"
              class="w-100 pb-2 text-sm border-secondary"
              style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              @select-item="selectProduct"
            ></SimpleTypeahead>

            <div v-if="product.subproducts.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak powiązanych produktów
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in product.subproducts"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.name }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="availableProducts.push(item); availableProductsAC.push(item.name); product.subproducts.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchProduct()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="product = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Podgląd produktu -->
      <div v-if="product && product.operation === 'overview'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>{{ product.name }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Typ: <span class="font-weight-normal ms-2">{{ product.type === "service" ? "Usługa" : product.type === "product" ? "Towar" : "Usługa zewnętrzna" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kategoria: <span class="font-weight-normal ms-2">{{ product.category ? product.category.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Jednostka miary: <span class="font-weight-normal ms-2">{{ product.measureUnit ? product.measureUnit : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Symbol: <span class="font-weight-normal ms-2">{{ product.symbol ? product.symbol : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Producent: <span class="font-weight-normal ms-2">{{ product.producer ? product.producer : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Notatka: <span class="font-weight-normal ms-2">{{ product.note ? product.note : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stworzony przez: <span class="font-weight-normal ms-2">{{ product.createdBy ? product.createdBy.fullName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="d-flex my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="getProduct(product.id, 'edit')"
              >
                Edycja
              </material-button>
            </div>

            <h5>Ceny</h5>

            <div v-if="product.type === 'service'" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Roboczogodziny: <span class="font-weight-normal ms-2">{{ product.rbh ? product.rbh : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena zakupu (Netto): <span class="font-weight-normal ms-2">{{ product.purchasePriceNet.toFixed(2) }} PLN</span>
                </label>
              </div>
            </div>

            <div v-if="product.type === 'product' || product.type === 'external'" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena zakupu (Brutto): <span class="font-weight-normal ms-2">{{ product.purchasePriceBru.toFixed(2) }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena sprzedaży (Netto): <span class="font-weight-normal ms-2">{{ product.sellingPriceNet.toFixed(2) }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena sprzedaży (Brutto): <span class="font-weight-normal ms-2">{{ product.sellingPriceBru.toFixed(2) }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  VAT: <span class="font-weight-normal ms-2">{{ isNaN(parseInt(product.vat, 10)) ? product.vat : `${ product.vat }%` }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Powiązane produkty</h5>

            <div v-if="product.subproducts.length === 0" class="d-flex align-items-center mt-3 text-sm">
              Brak powiązanych produktów
            </div>

            <div v-else class="d-flex align-items-center mt-3" style="flex-wrap: wrap">
              <material-badge
                v-for="item in product.subproducts"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1 cursor-pointer"
                @click="getProduct(item.id)"
              >
                {{ item.name }}
              </material-badge>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import ProductDataService from "@/services/ProductDataService"
import CategoryDataService from "@/services/CategoryDataService"
import VatRateDataService from "@/services/VatRateDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Assortment",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialRadio,
    MaterialSnackbar,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      product: null, // Obiekt wybranego produktu

      availableProducts: [], // Tablica zawierająca wszystkie dostępne produkty z API
      availableProductsAC: [], // Tablica zawierająca wszystkie nazwy produktów z API
      
      vatRates: [], // Tablica customowych stawek VAT
      defaultRate: 0, // Wartość domyślnej stawki

      products: [], // Tablica zawierająca dane produktów z API
      productsAC: [], // Tablica zawierająca nazwy produktów z API
      
      categories: [], // Tablica zawierająca wszystkie kategorie z API
      users: [], // Tablica zawierająca wszystkich użytkowników z API

      checkedProducts: [], // Tablica zawierająca wszystkie zaznaczone produkty
      modals: [], // Tablica odpowiedzialna za status modali
      
      error: "", // Zmienna przechowująca treść błędu
      
      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "name", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_name: "", // Filtr: Nazwa
      filter_creators: [], // Filtr: Stworzony przez
      filter_categories: [], // Filtr: Kategoria
      filter_types: [], // Filtr: Typ
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getProductsDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_ASSORTMENTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getProducts(this.page)
          this.getProductsAC()
          this.getCategories()
          this.getUsers()
          this.getVatRates()
          this.getDefaultRate()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobiera wybrany produkt z API
    getProduct(id, operation = "overview") {
      ProductDataService.get(id)
      .then(res => {
        this.product = res.data
        this.product.operation = operation

        if (operation === "edit") {
          this.product.category = this.product.category ? this.product.category.id : ""
        }

        this.getCategories()
        this.getAvailableProducts()

        this.error = ""
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca objekt nowego produktu
    getNewProduct() {
      this.product = null
      this.product = {
        name: "",
        category: "",
        symbol: "",
        producer: "",
        note: "",
        measureUnit: "",
        rbh: 1,
        purchasePriceNet: 0,
        sellingPriceNet: 0,
        vat: "0",
        subproducts: [],
        type: "product",
        operation: "new"
      }

      this.getCategories()
      this.getAvailableProducts()

      this.error = ""
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPostProduct() {
      let success = true
      this.error = ""

      if (this.product.name === "") {
        success = false
        this.error = "Nazwa produktu nie może być pusta!"
      }

      if (this.product.name.length > 65) {
        success = false
        this.error = "Nazwa produktu jest za długa (max. 65 znaków)"
      }

      if (this.product.symbol.length > 65) {
        success = false
        this.error = "Nazwa symbolu jest za długa (max. 65 znaków)"
      }

      if (this.product.category === "") {
        success = false
        this.error = "Wybierz kategorię!"
      }

      this.product.rbh = this.product.rbh ? this.product.rbh : 0
      this.product.purchasePriceNet = this.product.purchasePriceNet ? parseFloat(this.product.purchasePriceNet) : parseFloat(0)
      this.product.sellingPriceNet = this.product.sellingPriceNet ? parseFloat(this.product.sellingPriceNet) : parseFloat(0)

      if (success) {
        this.postProduct()
      }
    },

    // Funkcja dodająca nowy produkt do API
    postProduct() {
      console.log(this.product)
      ProductDataService.post(this.product)
      .then(res => {
        console.log(res.data)

        this.getProducts(this.page)
        this.getProductsAC()
        this.product = null
      })
      .catch(error => {
        console.log(error)
        
        if (JSON.stringify(error.response.status) == 400) {
          this.error = error.response.data.error
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchProduct() {
      let success = true
      this.error = ""

      if (this.product.name === "") {
        success = false
        this.error = "Nazwa produktu nie może być pusta!"
      }

      if (this.product.name.length > 65) {
        success = false
        this.error = "Nazwa produktu jest za długa (max. 65 znaków)"
      }

      if (this.product.symbol && this.product.symbol.length > 65) {
        success = false
        this.error = "Nazwa symbolu jest za długa (max. 65 znaków)"
      }

      if (this.product.category === "") {
        success = false
        this.error = "Wybierz kategorię!"
      } 

      this.product.rbh = this.product.rbh ? this.product.rbh : 0
      this.product.purchasePriceNet = this.product.purchasePriceNet ? parseFloat(this.product.purchasePriceNet) : 0
      this.product.sellingPriceNet = this.product.sellingPriceNet ? parseFloat(this.product.sellingPriceNet) : 0

      if (success) {
        this.patchProduct()
      }
    },

    // Funkcja edytująca wybrany produkt w API
    patchProduct() {
      ProductDataService.patch(this.product.id, this.product,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.snackbar = "success"

        this.getProducts(this.page)
        this.getProductsAC()
        this.product = null
      })
      .catch(error => {
        console.log(error)
        
        if (JSON.stringify(error.response.status) == 400) {
          this.error = error.response.data.error
        }
      })
    },

    // Funkcja pobierająca dostępne produkty z API
    getAvailableProducts() {
      ProductDataService.getAll("")
      .then(res => {
        this.availableProducts = []
        this.availableProductsAC = []

        res.data["hydra:member"].forEach(item => {
          let add = true

          if (item.id === this.product.id) {
            add = false
          }

          this.product.subproducts.forEach(product => {
            if (item.id === product.id) {
              add = false
            }
          })

          if (add) {
            this.availableProducts.push(item)
            this.availableProductsAC.push(item.name)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystie customowe stawki VAT
    getVatRates() {
      VatRateDataService.getAll("")
      .then(res => {
        this.vatRates = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca domyślną stawkę z API
    getDefaultRate() {
      AppSettingsDataService.getAll("?name=mh_rate")
      .then(res => {
        this.defaultRate = parseInt(res.data["hydra:member"][0].value)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca vat w produkcie
    onChangeVAT(id) {
      if (id !== "") {
        CategoryDataService.get(id)
        .then(res => {
          this.product.vat = res.data.vat
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja usuwająca wybrany produkt z API
    deleteProduct(id) {
      ProductDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getProducts(this.page)
        this.getProductsAC()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane produkty z API
    deleteProducts() {
      this.checkedProducts.forEach(item => {
        this.deleteProduct(item.id)
      })
    },

    // Funkcja dodająca nowy produkt do produktu
    selectProduct(subproduct) {
      this.availableProducts.forEach((item, index) => {
        if (item.name === subproduct) {
          this.product.subproducts.push(item)
          this.availableProducts.splice(index, 1)
          this.availableProductsAC.splice(index, 1)
        }
      })

      this.$refs.productsRef.clearInput()
    },

    // Funkcja exportująca asortyment do pliku
    exportProducts() {
      ProductDataService.export()
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "asortyment.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja importująca asortyment z wybranego pliku
    importProducts(event) {
      const formData = new FormData()
      formData.append("file", event.target.files[0])

      ProductDataService.import(this.me.id, formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getProducts(1)
        this.getProductsAC()
        this.openModal(1)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca produkty z API
    getProducts(page) {
      let creators = ""
      this.filter_creators.forEach(item => {
        creators += `&createdBy.fullName[]=${ item }`
      })

      let categories = ""
      this.filter_categories.forEach(item => {
        categories += `&category.name[]=${ item }`
      })

      let types = ""
      this.filter_types.forEach(item => {
        types += `&type[]=${ item }`
      })

      ProductDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ creators }${ categories }${ types }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.products = res.data["hydra:member"]
        this.unmarkProducts()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca produkty z API z opóźnieniem
    getProductsDelay: _.debounce(function(page) {
      let creators = ""
      this.filter_creators.forEach(item => {
        creators += `&createdBy.fullName[]=${ item }`
      })

      let categories = ""
      this.filter_categories.forEach(item => {
        categories += `&category.name[]=${ item }`
      })

      let types = ""
      this.filter_types.forEach(item => {
        types += `&type[]=${ item }`
      })

      ProductDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ creators }${ categories }${ types }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.products = res.data["hydra:member"]
        this.unmarkProducts()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja pobierająca nazwy produktów z API
    getProductsAC() {
      ProductDataService.getAll("")
      .then(res => {
        this.productsAC = []
        res.data["hydra:member"].forEach(item => {
          this.productsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca kategorie z API
    getCategories() {
      CategoryDataService.getAll("?order[name]=ASC")
      .then(res => {
        this.categories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystich użytkowników
    getUsers() {
      UserDataService.getAll("?order[fullName]=asc")
      .then(res => {
        this.users = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zaznaczająca wybrany produkt
    markProduct(event, item) {
      item.mark = event.target.checked
      if (event.target.checked) {
        this.checkedProducts.push(item)
      }
      else {
        this.checkedProducts.forEach((product, index) => {
          if (item.id === product.id) {
            this.checkedProducts.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie produkty
    markProducts(event) {
      this.checkedProducts = []
      if (event.target.checked) {
        this.products.forEach(item => {
          item.mark = true
          this.checkedProducts.push(item)
        })
      }
      else {
        this.products.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie produkty
    unmarkProducts() {
      this.checkedProducts = []
      this.products.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getProducts(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeCreator(event) {
      let exist = false

      this.filter_creators.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_creators.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_creators.push(event)
      }

      this.getProducts(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeCategory(event) {
      let exist = false

      this.filter_categories.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_categories.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_categories.push(event)
      }

      this.getProducts(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeType(event) {
      let exist = false

      this.filter_types.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_types.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_types.push(event)
      }

      this.getProducts(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getProducts(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getProducts(page)
      }
      else {
        this.getProducts(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getProducts(page)
      }
      else {
        this.getProducts(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getProducts(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getProducts(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>