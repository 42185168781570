import http from "@/http-common"

class ProductDataService {
    getAll(url) {
        return http.get(`products${ url }`)
    }

    export() {
        return http.get("products/export", { responseType: "blob" })
    }

    get(id) {
        return http.get(`products/${ id }`)
    }

    post(data) {
        return http.post('products', data)
    }

    import(id, data) {
        return http.post(`products/import?id=${ id }`, data)
    }

    patch(id, data, headers) {
        return http.patch(`products/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`products/${ id }`)
    }
}

export default new ProductDataService()