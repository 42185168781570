import http from "@/http-common"

class SubtaskDataService {
    getAll(url) {
        return http.get(`subtasks${ url }`)
    }

    get(id) {
        return http.get(`subtasks/${ id }`)
    }

    post(data) {
        return http.post('subtasks', data)
    }

    patch(id, data, headers) {
        return http.patch(`subtasks/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`subtasks/${ id }`)
    }
}

export default new SubtaskDataService()