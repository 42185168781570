<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć tą grupę?
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteGroup()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me && group" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Grupy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>
    
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#members" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Przypisani użytkownicy</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#delete" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">delete</i>
                <span class="text-sm">Usuń grupę</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje ogólne -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="error !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ error }}</p>
        </div>

        <!-- Przypisani użytkownicy -->
        <div id="members" class="card mb-4 z-index-2">
          <div class="card-header pb-0">
            <h5>Przypisani użytkownicy</h5>
            <p class="mb-0 text-sm">
              Wybierz użytkowników, którzy mają mieć dostęp do tej grupy.
            </p>
          </div>
          <div class="card-body pt-0">
            <div v-if="group.members.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak podłączonych użytkowników
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in group.members"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.fullName }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="users.push(item); usersAC.push(item.fullName); group.members.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0">Podłącz użytkownika</label>
                <SimpleTypeahead
                  ref="usersAC"
                  :items="usersAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-2"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectItem"
                >
                </SimpleTypeahead>
              </div>
            </div>
          </div>
        </div>

        <!-- Usuń grupę -->
        <div id="delete" class="card mb-4 z-index-1">
          <div class="card-header d-flex align-items-center justify-content-between">
            <div>
              <h5>Usuń grupę</h5>
              <p class="mb-0 text-sm">
                Po usunięciu grupy, przywrócenie jej będzie niemożliwe.
              </p>
            </div>

            <material-button
              color="danger"
              variant="outline"
              class="float-end mb-0"
              type="button"
              name="button"
              @click="openModal(0)"
            >
              Usuń
            </material-button>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Zapisz
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Grupy')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import { mapState } from "vuex"

export default {
  name: "EditGroup",
  components: {
    MaterialBadge,
    MaterialSnackbar,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      group: null, // Obiekt edytowanej grupy

      users: [], // Tablica wszystkich użytkowników nie należących do tej grupy
      usersAC: [], // Tablica imion i nazwisk wszystkich użytkowników nie należących do tej grupy

      modals: [], // Tablica odpowiedzialna za status modali

      error: "", // Zmienna odpowiedzialna za błąd danych

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_GROUPS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getGroup()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane edytowanej grupy
    getGroup() {
      UserGroupDataService.get(this.$route.params.id)
      .then(res => {
        this.group = res.data
        this.getUsers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      if (this.group.name === "") {
        success = false
        this.error = "Nazwa grupy nie może być pusta!"
      }

      if (success) {
        this.patchGroup()
      }
    },

    // Funkcja zmieniająca dane grupy
    patchGroup() {
      let members = []
      this.group.members.forEach(item => {
        members.push(`/users/${ item.id }`)
      })

      UserGroupDataService.patch(this.group.id,
        {
          name: this.group.name,
          members: members,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.snackbar = "success"
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.status) == 422) {
          this.error = "Nazwa grupy jest już zajęta!"
        }
      })
    },

    // Funckja usuwająca edytowaną grupę
    deleteGroup() {
      UserGroupDataService.delete(this.group.id)
      .then(res => {
        console.log(res.data)
        this.$redirect("Grupy")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich użytkowników, którzy nie należą do tej grupy
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          let add = true

          item.groups.forEach(group => {
            if (group.id == this.group.id) {
              add = false
            }
          })

          if (add) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o użytkowniku z listy rozwijanej
    selectItem(user) {
      this.users.forEach((item, index) => {
        if(item.fullName == user) {
          this.group.members.push(item)
          this.users.splice(index, 1)
          this.usersAC.splice(index, 1)
        }
      })

      this.$refs.usersAC.clearInput()
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
}

select {
  color: #7b809a;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #eeeeee;
}

.simple-typeahead {
  color: #000;
}
</style>