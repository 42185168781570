import http from "@/http-common"

class CategoryDataService {
    getAll(url) {
        return http.get(`categories${ url }`)
    }

    get(id) {
        return http.get(`categories/${ id }`)
    }

    post(data) {
        return http.post('categories', data)
    }

    patch(id, data, headers) {
        return http.patch(`categories/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`categories/${ id }`)
    }
}

export default new CategoryDataService()