<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Raporty cykliczne</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">
                          ID
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 90%">
                          Data
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in reports"
                        :key="item"
                        class="cursor-pointer"
                        @click="goToReport(item.month, item.year)"
                      >
                        <!-- Tabela: ID -->
                        <td class="text-wrap">
                          <p class="m-0 text-xs">{{ item.id }}</p>
                        </td>

                        <!-- Tabela: Data -->
                        <td class="ps-0 text-wrap">
                          <p class="m-0 text-xs">{{ item.month + " - " + item.year }}</p>
                        </td>
                      </tr>

                      <!-- Brak raportów -->
                      <tr :class="reports.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="6">Nie znaleziono raportów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService"
import { mapState } from "vuex"

export default {
  name: "PeriodicReports",
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
      reports: [], // Tablica zawierająca dane raportów
    }
  },
  computed: {
    ...mapState(["color"]),
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_PERIODIC_REPORTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getReports()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca tablicę z danymi raportów
    getReports() {
      let start = this.moment()
      let end = this.moment("2023-01")
      let id = 1

      while (start.isSameOrAfter(end)) {
        this.reports.push({
          id: id,
          month: start.format("MM"),
          year: start.format("YYYY"),
        })

        start.subtract(1, "month")
        id++
      }
    },

    // Funkcja przekierowująca do szczegółów raportu
    goToReport(month, year) {
      this.$router.push({ name: "Raporty", query: { date: `${ year }-${ month }` } })
    },
  },
}
</script>