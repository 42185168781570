import http from "@/http-common"

class CustomerGroupDataService {
    getAll(url) {
        return http.get(`customer_groups${ url }`)
    }

    get(id) {
        return http.get(`customer_groups/${ id }`)
    }

    post(data) {
        return http.post('customer_groups', data)
    }

    patch(id, data, headers) {
        return http.patch(`customer_groups/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`customer_groups/${ id }`)
    }
}

export default new CustomerGroupDataService()