import http from "@/http-common"

class TaskDataService {
    getAll(url) {
        return http.get(`tasks${ url }`)
    }

    get(id) {
        return http.get(`tasks/${ id }`)
    }

    post(data) {
        return http.post('tasks', data)
    }

    patch(id, data, headers) {
        return http.patch(`tasks/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`tasks/${ id }`)
    }
}

export default new TaskDataService()