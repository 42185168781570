<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <!-- Profil -->
      <li v-if="me" class="nav-item pt-3 pb-1" :class="getRoute() === 'profil' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="profil"
          :nav-text="me.fullName.length >= 16 ? me.fullName.substring(0, 16) + '...' : me.fullName"
          :class="getRoute() === 'Zarządzanie kontem' || getRoute() === 'Personalizacja' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <material-avatar
              :img="$getAvatar(me.fullName, me.avatar)"
              class="avatar shadow"
              circular
              alt="avatar image"
            />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Zarządaj kontem -->
              <sidenav-item
                :to="{ name: 'Zarządzanie kontem' }"
                mini-icon="account_circle"
                text="Zarządzanie kontem"
              />

              <!-- Personalizacja -->
              <sidenav-item
                :to="{ name: 'Personalizacja' }"
                mini-icon="settings"
                text="Personalizacja"
              />

              <!-- Wyloguj -->
              <sidenav-item
                :to="{ name: 'Login' }"
                mini-icon="logout"
                text="Wyloguj"
                @click="logout"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <!-- Kokpit -->
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Kokpit"
          :collapse="false"
          collapse-ref="/home/dashboard"
          :class="getRoute() === 'Kokpit' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Kokpit' ? 'color: white !important' : ''">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li
        v-if="me && (me.roles.includes('ROLE_USERS') || me.roles.includes('ROLE_GROUPS') || me.roles.includes('ROLE_MAIL_ACCOUNTS') || me.roles.includes('ROLE_PERMISSIONS') || me.roles.includes('ROLE_PAYMENTS') || me.roles.includes('ROLE_PACKAGES') || me.roles.includes('ROLE_APP_SETTINGS'))"
        class="mt-3 nav-item"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Administrator
        </h6>
      </li>

      <!-- Panel administratora -->
      <li
        v-if="me && (me.roles.includes('ROLE_USERS') || me.roles.includes('ROLE_GROUPS') || me.roles.includes('ROLE_MAIL_ACCOUNTS') || me.roles.includes('ROLE_PERMISSIONS') || me.roles.includes('ROLE_PAYMENTS') || me.roles.includes('ROLE_PACKAGES') || me.roles.includes('ROLE_APP_SETTINGS'))"
        class="nav-item pb-1"
        :class="getRoute() === 'admin' ? 'active' : ''"
      >
        <sidenav-collapse
            collapse-ref="admin"
            nav-text="Panel administratora"
            :class="getRoute() === 'Użytkownicy' || getRoute() === 'Grupy' || getRoute() === 'Konta pocztowe' || getRoute() === 'Uprawnienia' || getRoute() === 'Płatności' || getRoute() === 'Pakiety' || getRoute() === 'Ustawienia aplikacji' ? 'active' : 'bg-transparent shadow-none'"
          >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">person</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Użytkownicy -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_USERS')"
                :to="{ name: 'Użytkownicy' }"
                mini-icon="group"
                text="Użytkownicy"
              />

              <!-- Grupy -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_GROUPS')"
                :to="{ name: 'Grupy' }"
                mini-icon="groups"
                text="Grupy"
              />

              <!-- Konta pocztowe -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_MAIL_ACCOUNTS')"
                :to="{ name: 'Konta pocztowe' }"
                mini-icon="mark_as_unread"
                text="Konta pocztowe"
              />

              <!-- Uprawnienia -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_PERMISSIONS')"
                :to="{ name: 'Uprawnienia' }"
                mini-icon="security"
                text="Uprawnienia"
              />

              <!-- Płatności -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_PAYMENTS')"
                :to="{ name: 'Płatności' }"
                mini-icon="payments"
                text="Płatności"
              />

              <!-- Pakiety -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_PACKAGES')"
                :to="{ name: 'Pakiety' }"
                mini-icon="inventory"
                text="Pakiety"
              />

              <!-- Ustawienia aplikacji -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_APP_SETTINGS')"
                :to="{ name: 'Ustawienia aplikacji' }"
                mini-icon="settings"
                text="Ustawienia aplikacji"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li
        v-if="me && (me.roles.includes('ROLE_MAILBOX') || me.roles.includes('ROLE_CUSTOMERS') || me.roles.includes('ROLE_SALE') || me.roles.includes('ROLE_OFFERS') || me.roles.includes('ROLE_TEMPLATES') || me.roles.includes('ROLE_PRINT_TEMPLATES') || me.roles.includes('ROLE_ASSORTMENTS') || me.roles.includes('ROLE_CATEGORIES') || me.roles.includes('ROLE_CALENDAR') || me.roles.includes('ROLE_TASKS') || me.roles.includes('ROLE_REPORTS') || me.roles.includes('ROLE_MESSAGES'))"
        class="mt-3 nav-item"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Strony
        </h6>
      </li>

      <!-- Poczta -->
      <li v-if="me && me.roles.includes('ROLE_MAILBOX')" class="nav-item">
        <sidenav-collapse 
          nav-text="Poczta"
          :collapse="false"
          collapse-ref="/home/mailbox"
          :class="getRoute() === 'Poczta' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Poczta' ? 'color: white !important' : ''">mail</i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Kontrahenci -->
      <li v-if="me && me.roles.includes('ROLE_CUSTOMERS')" class="nav-item">
        <sidenav-collapse
          nav-text="Kontrahenci"
          :collapse="false"
          collapse-ref="/home/customers"
          :class="getRoute() === 'Kontrahenci' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Kontrahenci' ? 'color: white !important' : ''">group</i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Oferty i zamówienia -->
      <li
        v-if="me && (me.roles.includes('ROLE_SALE') || me.roles.includes('ROLE_OFFERS') || me.roles.includes('ROLE_TEMPLATES') || me.roles.includes('ROLE_PRINT_TEMPLATES') || me.roles.includes('ROLE_ASSORTMENTS') || me.roles.includes('ROLE_CATEGORIES'))"
        class="nav-item pb-1"
        :class="getRoute() === 'sale' ? 'active' : ''"
      >
        <sidenav-collapse
          collapse-ref="sale"
          nav-text="Oferty i zamówienia"
          :class="getRoute() === 'Sprzedaż' || getRoute() === 'Oferty' || getRoute() === 'Szablony ofert' || getRoute() === 'Szablony wydruku' || getRoute() === 'Asortyment' || getRoute() === 'Ustawienia' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Sprzedaż -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_SALE')"
                :to="{ name: 'Sprzedaż' }"
                mini-icon="format_list_bulleted"
                text="Sprzedaż"
              />

              <!-- Oferty -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_OFFERS')"
                :to="{ name: 'Oferty' }"
                mini-icon="sell"
                text="Oferty"
              />

              <!-- Szablony ofert -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_TEMPLATES')"
                :to="{ name: 'Szablony ofert' }"
                mini-icon="file_copy"
                text="Szablony ofert"
              />

              <!-- Szablony wydruku -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_PRINT_TEMPLATES')"
                :to="{ name: 'Szablony wydruku' }"
                mini-icon="print"
                text="Szablony wydruku"
              />

              <!-- Asortyment -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_ASSORTMENTS')"
                :to="{ name: 'Asortyment' }"
                mini-icon="category"
                text="Asortyment"
              />

              <!-- Ustawienia -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_CATEGORIES')"
                :to="{ name: 'Ustawienia' }"
                mini-icon="bookmarks"
                text="Ustawienia"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Kalendarz -->
      <li v-if="me && me.roles.includes('ROLE_CALENDAR')" class="nav-item">
        <sidenav-collapse
          nav-text="Kalendarz"
          :collapse="false"
          collapse-ref="/home/calendar"
          :class="getRoute() === 'Kalendarz' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Kalendarz' ? 'color: white !important' : ''">calendar_month</i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Zadania -->
      <li v-if="me && me.roles.includes('ROLE_TASKS')" class="nav-item">
        <sidenav-collapse
          nav-text="Zadania"
          :collapse="false"
          collapse-ref="/home/tasks"
          :class="getRoute() === 'Zadania' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Zadania' ? 'color: white !important' : ''">done_all</i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Wiadomości -->
      <li v-if="me && me.roles.includes('ROLE_MESSAGES')" class="nav-item">
        <sidenav-collapse
          nav-text="Wiadomości"
          :collapse="false"
          collapse-ref="/home/messages"
          :class="getRoute() === 'Wiadomości' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Wiadomości' ? 'color: white !important' : ''">chat_bubble</i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Raporty -->
      <li
        v-if="me && (me.roles.includes('ROLE_REPORTS') || me.roles.includes('ROLE_PERIODIC_REPORTS'))"
        class="nav-item pb-1"
        :class="getRoute() === 'reports' ? 'active' : ''"
      >
        <sidenav-collapse
          collapse-ref="reports"
          nav-text="Raporty"
          :class="getRoute() === 'Raporty' || getRoute() === 'Raporty cyklicze' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">content_paste</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Raporty -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_REPORTS')"
                :to="{ name: 'Raporty' }"
                mini-icon="monitoring"
                text="Raporty"
              />

              <!-- Raporty cykliczne -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_PERIODIC_REPORTS')"
                :to="{ name: 'Raporty cykliczne' }"
                mini-icon="history"
                text="Raporty cykliczne"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- ASOM -->
      <li
        v-if="me && (me.roles.includes('ROLE_ASOM_INSPECTIONS') || me.roles.includes('ROLE_ASOM_WORKSHEETS'))"
        class="nav-item pb-1"
        :class="getRoute() === 'asom' ? 'active' : ''"
      >
        <sidenav-collapse
          collapse-ref="asom"
          nav-text="ASOM"
          :class="getRoute() === 'Przeglądy' || getRoute() === 'Karty pracy' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">widgets</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Przeglądy -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_ASOM_INSPECTIONS')"
                :to="{ name: 'Przeglądy' }"
                mini-icon="overview"
                text="Przeglądy"
              />

              <!-- Karty pracy -->
              <sidenav-item
                v-if="me.roles.includes('ROLE_ASOM_WORKSHEETS')"
                :to="{ name: 'Karty pracy' }"
                mini-icon="article"
                text="Karty pracy"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import SidenavItem from "./SidenavItem.vue"
import SidenavCollapse from "./SidenavCollapse.vue"
import LoginDataService from "@/services/LoginDataService"
import UserDataService from "@/services/UserDataService"
import { mapState } from "vuex"

export default {
  name: "SidenavList",
  components: {
    MaterialAvatar,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
    }
  },
  computed: {
    ...mapState(["isRTL", "color"])
  },
  created() {
    if(localStorage.getItem('token')) {
      this.getMe()
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.name
      return routeArr
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getMe() {
      UserDataService.me()
        .then(res => {
          this.getUser(res.data.id)
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        this.me = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wylogowująca użytkownika
    logout() {
      LoginDataService.logout(
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        console.log(res.data)
        localStorage.removeItem("token")
        if (window.location.search.includes('?asom=1')) {
          this.$router.push({ name: "Login", query: { asom: 1 } })
        }
        else {
          this.$router.push({ name: "Login" })
        } 
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>