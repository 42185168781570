<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-lg-4">
        <!-- Metoda płatności -->
        <div class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Metoda płatności</h6>
          </div>
          <div class="card-body pt-1">
            <div
              class="card card-body border card-plain border-radius-lg align-items-center flex-row px-3 py-3 mb-3 cursor-pointer"
              :class="type ? `border-${ color }` : ''"
              @click="type = true"
            >
              <h6 class="mb-0">
                Przelew tradycyjny
                <span class="d-block text-sm font-weight-normal">
                  W tytule przelewu wpisz numer faktury
                </span>
              </h6>
            </div>

            <div
              class="card card-body border card-plain border-radius-lg align-items-center flex-row px-3 py-3 cursor-pointer"
              :class="!type ? `border-${ color }` : ''"
              @click="type = false" 
            >
              <h6 class="mb-0">
                Płatność gotówką
                <span class="d-block text-sm font-weight-normal">
                  Płatność gotówką w oddziale
                </span>
              </h6>
            </div>
          </div>
        </div>

        <!-- Subskrypcja -->
        <div class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="mb-0 p font-weight-bold">Subskrypcja</h6>
          </div>
          <div class="card-body pt-1 text-sm">
            <strong>Obecny plan: Premium</strong>
            <span class="d-block mt-2">Okres rozliczeniowy: miesięczny</span>
            <span class="d-block mt-2">Następna faktura: 9 września 2022</span>
          </div>
        </div>
      </div>

      <!-- Dane do faktury -->
      <div class="col-lg-8">
        <div class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Dane do faktury</h6>
          </div>
          <div v-if="app" class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-4">
                <div class="input-group input-group-static">
                  <label>Nazwa firmy</label>
                  <input
                    v-model="app[0].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="input-group input-group-static">
                  <label>NIP firmy</label>
                  <input
                    v-model="app[1].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-4">
                <div class="input-group input-group-static">
                  <label>Miasto</label>
                  <input
                    v-model="app[2].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="input-group input-group-static">
                  <label>Ulica</label>
                  <input
                    v-model="app[3].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-4 mb-4">
                <div class="input-group input-group-static">
                  <label>Kod pocztowy</label>
                  <input
                    v-model="app[4].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group input-group-static">
                <label>E-mail do wysyłki faktury</label>
                <input
                  class="form-control"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="border: 1px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Faktury -->
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Faktury</h6>
          </div>
          <div class="card-body pt-1">
            <div class="p-0 table-responsive">
              <table class="table mb-0 align-items-center">
                <tbody>
                  <tr>
                    <td>
                      <div class="px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Zakup subskrypcji</h6>
                          <p class="mb-0 text-sm text-secondary">#FV-415646</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-center mb-0 text-sm">9 sierpnia 2022</p>
                    </td>
                    <td>
                      <p class="text-center mb-0 text-sm">19,99 PLN</p>
                    </td>
                    <td>
                      <p class="text-center mb-0 text-sm font-weight-bold">PDF</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import { mapState } from "vuex"

export default {
  name: "Payments",
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      app: null, // Obiekt zawierający ustawienia aplikacji
      type: true,
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_PAYMENTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getApp()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca ustawienia aplikacji
    getApp() {
      AppSettingsDataService.getAll("")
      .then(res => {
        this.app = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>