<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia etapu -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="markedStages.length === 1">Czy na pewno chcesz usunąć {{ markedStages.length }} etap?</div>
            <div v-if="markedStages.length > 1 && markedStages.length < 5">Czy na pewno chcesz usunąć {{ markedStages.length }} etapy?</div>
            <div v-if="markedStages.length > 4">Czy na pewno chcesz usunąć {{ markedStages.length }} etapów?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteStages(); unmarkStages(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia kategorii -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="markedCategories.length === 1">Czy na pewno chcesz usunąć {{ markedCategories.length }} kategorię?</div>
            <div v-if="markedCategories.length > 1 && markedCategories.length < 5">Czy na pewno chcesz usunąć {{ markedCategories.length }} kategorie?</div>
            <div v-if="markedCategories.length > 4">Czy na pewno chcesz usunąć {{ markedCategories.length }} kategorii?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteCategories(); unmarkCategories(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Nie można usunąć -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nie można usunąć kategorii</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Nie można usunąć kategorii powiązanej z produktem!

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="closeModal()"
              >
                OK
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Przycisk: Nowy etap / Nowa kategoria -->
      <material-button
        :color="color"
        variant="gradient"
        @click="table === 'Stages' ? [stage && stage.operation === 'new' ? stage = null : getNewStage()] : [category && category.operation === 'new' ? category = null : getNewCategory()]"
      >
        {{ table === "Stages" ? "+ &nbsp; Nowy etap" : "+ &nbsp; Nowa kategoria" }}
      </material-button>
    </div>

    <div class="row">
      <!-- Etapy -->
      <div class="mb-4" :class="stage || category ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1 d-flex align-items-center">
            <h5 class="me-3" :class="table === 'Stages' ? '' : 'opacity-3 cursor-pointer'" @click="table !== 'Stages' ? onChangeTable('Stages') : null">Etapy</h5>
            <h5 :class="table === 'Categories' ? '' : 'opacity-3 cursor-pointer'" @click="table !== 'Categories' ? onChangeTable('Categories') : null">Kategorie</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="searchRef"
                      v-model="filter_name"
                      :items="recordsAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="table === 'Stages' ? markStages($event) : markCategories($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 90%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="markedStages.length !== 0 || markedCategories.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="table === 'Stages' ? openModal(0) : openModal(1)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in records"
                        :key="item"
                        :class="table === 'Stages' ? [stage && stage.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null] : [category && category.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null]"
                      >
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="table === 'Stages' ? markStage($event, item) : markCategory($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="table === 'Stages' ? [stage && stage.id === item.id ? stage = null : getStage(item.id)] : [category && category.id === item.id ? category = null : getCategory(item.id)]">
                          <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="table === 'Stages' ? getStage(item.id) : getCategory(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="table === 'Stages' ? [unmarkStages(), item.mark = true, markedStages.push(item), openModal(0)] : [unmarkCategories(), item.mark = true, markedCategories.push(item), openModal(1)]">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak -->
                      <tr :class="records.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="3">Nie znaleziono etapów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="records.length !== 0" class="dataTable-bottom ob-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dateaTable-selector text-sm p-2 border-secondary me-2"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowy etap -->
      <div v-if="stage && stage.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa etapu
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="stage.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <label class="d-flex align-items-center text-sm mb-2">
              <material-checkbox class="me-1" checked disabled />
              Data rozpoczęcia
            </label>

            <label class="d-flex align-items-center text-sm mb-2">
              <material-checkbox class="me-1" :checked="stage.closedAtStatus" @change="stage.closedAtStatus = !stage.closedAtStatus" />
              Data zakończenia
            </label>

            <label class="d-flex align-items-center text-sm mb-3">
              <material-checkbox class="me-1" :checked="stage.calendarStatus" @change="stage.calendarStatus = !stage.calendarStatus" />
              Kalendarz
            </label>

            <p v-if="stageError !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ stageError }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostStage()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="stage = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja etapu -->
      <div v-if="stage && stage.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa etapu
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="stage.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <label class="d-flex align-items-center text-sm mb-2">
              <material-checkbox class="me-1" checked disabled />
              Data rozpoczęcia
            </label>

            <label class="d-flex align-items-center text-sm mb-2">
              <material-checkbox class="me-1" :checked="stage.closedAtStatus" @change="stage.closedAtStatus = !stage.closedAtStatus" />
              Data zakończenia
            </label>

            <label class="d-flex align-items-center text-sm mb-3">
              <material-checkbox class="me-1" :checked="stage.calendarStatus" @change="stage.calendarStatus = !stage.calendarStatus" />
              Kalendarz
            </label>

            <p v-if="stageError !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ stageError }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchStage()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="stage = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowa kategoria -->
      <div v-if="category && category.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa kategorii
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="category.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">VAT</label>
                <select
                  v-model="category.vat"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="8">8%</option>
                  <option value="23">23%</option>
                  <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                </select>
              </div>
            </div>

            <p v-if="categoryError !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ categoryError }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostCategory()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="category = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja kategorii -->
      <div v-if="category && category.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa kategorii
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="category.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label mx-0 mb-1">VAT</label>
                <select
                  v-model="category.vat"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="8">8%</option>
                  <option value="23">23%</option>
                  <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                </select>
              </div>
            </div>

            <p v-if="categoryError !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ categoryError }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchCategory()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="category = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import CategoryDataService from "@/services/CategoryDataService"
import SaleStageDataService from "@/services/SaleStageDataService"
import VatRateDataService from "@/services/VatRateDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Categories",
  components: {
    MaterialCheckbox,
    MaterialSnackbar,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
      
      table: "Stages",

      stage: null, // Obiekt wybranego etapu
      category: null, // Obiekt wybranej kategorii

      calendars: [], // Tablica kalendarzy użytkownika
      vatRates: [], // Tablica customowych stawek VAT
      defaultVAT: "", // Wartość domyślnej stawki VAT

      records: [], // Tablica zawierająca dane rekordów z API
      recordsAC: [], // Tablica zawierająca nazwy rekordów z API

      markedStages: [], // Tablica zawierająca wszystkie zaznaczone etapy
      markedCategories: [], // Tablica zawierająca wszystkie zaznaczone kategorie

      modals: [], // Tablica odpowiedzialna za status modali
      
      stageError: "", // Zmienna przechowująca treść błędu
      categoryError: "", // Zmienna przechowująca treść błędu

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "name", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_name: "", // Filtr: Nazwa
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      if (this.table === "Stages") {
        this.getStagesDelay(1)
      }
      else {
        this.getCategoriesDelay(1)
      }
      
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_CATEGORIES")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getStages(this.page)
          this.getStagesAC()
          this.getVatRates()
          this.getDefaultVat()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja zmieniająca wybraną tabelę
    onChangeTable(table) {
      this.table = table
      this.stage = null
      this.category = null
      this.page = 1
      this.lastPage = 1
      this.filter_name = ""
      this.unmarkStages()
      this.unmarkCategories()

      if (table === "Stages") {
        this.getStages(1)
        this.getStagesAC()
      }
      else {
        this.getCategories(1)
        this.getCategoriesAC()
      }
    },

    // Funkcja pobiera wybrany etap z API
    getStage(id, operation = "edit") {
      SaleStageDataService.get(id)
      .then(res => {
        this.category = null
        this.stage = res.data
        this.stage.operation = operation
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca nowy etap
    getNewStage() {
      this.stage = null
      this.category = null

      this.stage = {
        name: "",
        closedAtStatus: false,
        calendarStatus: false,
        operation: "new",
      }

      this.stageError = ""
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPostStage() {
      let success = true
      this.stageError = ""

      if (this.stage.name === "") {
        success = false
        this.stageError = "Nazwa etapu nie może być pusta!"
      }

      if (this.stage.name.length > 65) {
        success = false
        this.stageError = "Nazwa etapu jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.postStage()
      }
    },

    // Funkcja dodająca nowy etap do API
    postStage() {
      SaleStageDataService.post(this.stage)
      .then(res => {
        console.log(res.data)
        this.getStages(this.page)
        this.getStagesAC()
        this.stage = null
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 422) {
          this.stageError = "Nazwa etapu jest już zajęta!"
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchStage() {
      let success = true
      this.stageError = ""

      if (this.stage.name === "") {
        success = false
        this.stageError = "Nazwa etapu nie może być pusta!"
      }

      if (this.stage.name.length > 65) {
        success = false
        this.stageError = "Nazwa etapu jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.patchStage()
      }
    },

    // Funkcja edytująca wybrany etap w API
    patchStage() {
      SaleStageDataService.patch(this.stage.id, this.stage,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.snackbar = "success"

        this.getStages(this.page)
        this.getStagesAC()
        this.stage = null
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 422) {
          this.stageError = "Nazwa etapu jest już zajęta!"
        }
      })
    },

    // Funkcja usuwająca wybrany etap z API
    deleteStage(id) {
      SaleStageDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getStages(this.page)
        this.getStagesAC()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane etapy z API
    deleteStages() {
      this.markedStages.forEach(item => {
        this.deleteStage(item.id)
      })
    },

    // Funkcja pobiera wybraną kategorię z API
    getCategory(id, operation = "edit") {
      CategoryDataService.get(id)
      .then(res => {
        this.stage = null
        this.category = res.data
        this.category.operation = operation
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca nową kategorię
    getNewCategory() {
      this.category = null
      this.stage = null

      this.category = {
        name: "",
        vat: this.defaultVAT,
        operation: "new",
      }

      this.categoryError = ""
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPostCategory() {
      let success = true
      this.categoryError = ""

      if (this.category.name === "") {
        success = false
        this.categoryError = "Nazwa kategorii nie może być pusta!"
      }

      if (this.category.name.length > 65) {
        success = false
        this.categoryError = "Nazwa kategorii jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.postCategory()
      }
    },

    // Funkcja dodająca nową kategorię do API
    postCategory() {
      this.category.vat = this.category.vat.toString()
      CategoryDataService.post(this.category)
      .then(res => {
        console.log(res.data)
        this.getCategories(this.page)
        this.getCategoriesAC()
        this.category = null
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 422) {
          this.categoryError = "Nazwa kategorii jest już zajęta!"
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchCategory() {
      let success = true
      this.categoryError = ""

      if (this.category.name === "") {
        success = false
        this.categoryError = "Nazwa kategorii nie może być pusta!"
      }

      if (this.category.name.length > 65) {
        success = false
        this.categoryError = "Nazwa kategorii jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.patchCategory()
      }
    },

    // Funkcja edytująca wybraną kategorię w API
    patchCategory() {
      this.category.vat = this.category.vat.toString()
      CategoryDataService.patch(this.category.id, this.category,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.snackbar = "success"

        this.getCategories(this.page)
        this.getCategoriesAC()
        this.category = null
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 422) {
          this.categoryError = "Nazwa kategorii jest już zajęta!"
        }
      })
    },

    // Funkcja pobierająca wszystie customowe stawki VAT
    getVatRates() {
      VatRateDataService.getAll("")
      .then(res => {
        this.vatRates = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca domyślną stawkę VAT
    getDefaultVat() {
      AppSettingsDataService.getAll("?name=vat_rate")
      .then(res => {
        // this.defaultVAT = isNaN(parseInt(res.data["hydra:member"][0].value, 10)) ? "0" : res.data["hydra:member"][0].value
        this.defaultVAT = res.data["hydra:member"][0].value
        console.log(this.defaultVAT)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybraną kategorie z API
    deleteCategory(id) {
      CategoryDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getCategories(this.page)
        this.getCategoriesAC()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.status) == 500) {
          this.openModal(2)
        }
      })
    },

    // Funkcja usuwająca wybrane kategorie z API
    deleteCategories() {
      this.markedCategories.forEach(item => {
        this.deleteCategory(item.id)
      })
    },

    // Funkcja pobierająca etapy z API
    getStages(page) {
      SaleStageDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.records = res.data["hydra:member"]
        this.unmarkStages()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca etapy z API z opóźnieniem
    getStagesDelay: _.debounce(function(page) {
      SaleStageDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.records = res.data["hydra:member"]
        this.unmarkStages()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja pobierająca nazwy etapów z API
    getStagesAC() {
      SaleStageDataService.getAll("")
      .then(res => {
        this.recordsAC = []
        res.data["hydra:member"].forEach(item => {
          this.recordsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca kategorie z API
    getCategories(page) {
      CategoryDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.records = res.data["hydra:member"]
        this.unmarkCategories()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca kategorie z API z opóźnieniem
    getCategoriesDelay: _.debounce(function(page) {
      CategoryDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.records = res.data["hydra:member"]
        this.unmarkCategories()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja pobierająca nazwy kategorii z API
    getCategoriesAC() {
      CategoryDataService.getAll("")
      .then(res => {
        this.recordsAC = []
        res.data["hydra:member"].forEach(item => {
          this.recordsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja zaznaczająca wybrany etap
    markStage(event, item) {
      item.mark = event.target.checked
      if (event.target.checked) {
        this.markedStages.push(item)
      }
      else {
        this.markedStages.forEach((stage, index) => {
          if (item.id === stage.id) {
            this.markedStages.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie etapy
    markStages(event) {
      this.markedStages = []
      if (event.target.checked) {
        this.records.forEach(item => {
          item.mark = true
          this.markedStages.push(item)
        })
      }
      else {
        this.records.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie etapy
    unmarkStages() {
      this.markedStages = []
      this.records.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja zaznaczająca wybraną kategorię
    markCategory(event, item) {
      item.mark = event.target.checked
      if (event.target.checked) {
        this.markedCategories.push(item)
      }
      else {
        this.markedCategories.forEach((category, index) => {
          if (item.id === category.id) {
            this.markedCategories.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie kategorie
    markCategories(event) {
      this.markedCategories = []
      if (event.target.checked) {
        this.records.forEach(item => {
          item.mark = true
          this.markedCategories.push(item)
        })
      }
      else {
        this.records.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie kategorie
    unmarkCategories() {
      this.markedCategories = []
      this.records.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      if (this.table === "Stages") {
        this.getStages(1)
      }
      else {
        this.getCategories(1)
      }
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      if (this.table === "Stages") {
        this.getStages(1)
      }
      else {
        this.getCategories(1)
      }
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1

        if (this.table === "Stages") {
          this.getStages(page)
        }
        else {
          this.getCategories(page)
        }
      }
      else {
        if (this.table === "Stages") {
          this.getStages(page)
        }
        else {
          this.getCategories(page)
        }
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage

        if (this.table === "Stages") {
          this.getStages(page)
        }
        else {
          this.getCategories(page)
        }
      }
      else {
        if (this.table === "Stages") {
          this.getStages(page)
        }
        else {
          this.getCategories(page)
        }
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"

        if (this.table === "Stages") {
          this.getStages(this.page)
        }
        else {
          this.getCategories(this.page)
        }
      }
      else {
        this.columnOrder = "asc"

        if (this.table === "Stages") {
          this.getStages(this.page)
        }
        else {
          this.getCategories(this.page)
        }
      }
    },
    
    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>