<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#profile" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Profil</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#user-data" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Dane użytkownika</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#password" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">lock</i>
                <span class="text-sm">Zmiana hasła</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#history" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">desktop_windows</i>
                <span class="text-sm">Historia logowania</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Profil -->
        <div id="profile" class="card mb-4">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <div>
                    <label class="avatar avatar-xxl m-0">
                      <material-avatar
                        :img="$getAvatar(me.fullName, me.avatar.slice(1))"
                        class="cursor-pointer"
                        alt="bruce"
                        size="xl"
                        shadow="sm"
                        circular
                      />

                      <input
                        v-model="me.avatar"
                        type="color"
                      >
                    </label>
                  </div>

                  <h5 class="m-0 font-weight-bolder">
                    {{ me.fullName }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Dane użytkownika -->
        <div id="user-data" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dane użytkownika</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres e-mail
                  </label>
                  <input
                    v-model="me.email"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Numer telefonu
                  </label>
                  <input
                    v-model="me.phoneNumber"
                    v-mask="'+48 ### ### ###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorData !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorData }}</p>
        </div>

        <!-- Zmiana hasła -->
        <div id="password" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Zmiana hasła</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Aktualne hasło
                  </label>
                  <input
                    v-model="me.password"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nowe hasło
                  </label>
                  <input
                    v-model="me.newPassword"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Powtórz nowe hasło
                  </label>
                  <input
                    v-model="me.confirmNewPassword"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorPassword !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorPassword }}</p>
        </div>

        <!-- Historia logowania -->
        <div id="history" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Historia logowania</h5>
          </div>
          <div class="card-body pt-1">
            <div v-if="sessions.length === 0" class="text-sm">Brak sesji</div>
            <div v-for="(item, index) in sessions" v-else :key="item">
              <div class="d-flex align-items-center">
                <i class="material-symbols-outlined me-2">computer</i>
                <p class="my-auto text-sm">{{ item.createdAt }}</p>
                <material-badge v-if="index === 0" color="success" size="sm" class="ms-auto">Aktywne</material-badge>
              </div>
              <hr v-if="index !== sessions.length - 1" class="horizontal dark" />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Zapisz zmiany
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Kokpit')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import SessionDataService from "@/services/SessionDataService"
import { mapState } from "vuex"

export default {
  name: "Overview",
  components: {
    MaterialAvatar,
    MaterialBadge,
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      sessions: [], // Lista ostatnich 3 sesji tego użytkownika

      errorData: "", // Zmienna odpowiedzialna za błąd danych
      errorPassword: "", // Zmienna odpowiedzialna za błąd hasła

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(uid) {
      UserDataService.get(uid)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.me.avatar = `#${ this.me.avatar }`
          this.getSessions()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true

      this.errorData = ""
      this.errorPassword = ""

      if (this.me.email === "" || this.me.phoneNumber === "") {
        success = false
        this.errorData = "Pola nie mogą być puste!"
      }

      if (this.me.newPassword !== "" && this.me.confirmNewPassword !== "") {
        if (this.me.newPassword !== this.me.confirmNewPassword) {
          success = false
          this.errorPassword = "Podane hasła różnią się od siebie!"
        }
      }

      if (success) {
        this.patchUser()
      }
    },

    // Funkcja aktualizująca dane użytkownika
    patchUser() {
      UserDataService.patch(this.me.id,
        {
          id: this.me.id,
          email: this.me.email,
          password: this.me.password,
          newPassword: this.me.newPassword,
          phoneNumber: this.me.phoneNumber,
          avatar: this.me.avatar.slice(1),
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.$router.go(0)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o sesjach zalogowanego użytkownika
    getSessions() {
      SessionDataService.getAll(`?itemsPerPage=3&owner.id=${ this.me.id }&order[createdAt]=desc`)
      .then(res => {
        this.sessions = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
}

input[type="color"], input[type="color"]::-webkit-color-swatch {
  width: 0 !important;
  height: 0 !important;
  background: none !important;
  border: none !important;
  outline: none !important;
}
</style>