import http from "@/http-common"

class CustomerDataService {
    getAll(url) {
        return http.get(`customers${ url }`)
    }

    export() {
        return http.get("customers/export", { responseType: "blob" })
    }

    get(id) {
        return http.get(`customers/${ id }`)
    }

    post(data) {
        return http.post("customers", data)
    }

    import(id, data) {
        return http.post(`customers/import?id=${ id }`, data)
    }

    patch(id, data, headers) {
        return http.patch(`customers/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`customers/${ id }`)
    }
}

export default new CustomerDataService()