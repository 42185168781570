import http from "@/http-common"

class OfferDataService {
    getAll(url) {
        return http.get(`offers${ url }`)
    }

    get(id) {
        return http.get(`offers/${ id }`)
    }

    replace(id) {
        return http.get(`offers/replace/${ id }`)
    }

    post(data) {
        return http.post('offers', data)
    }

    patch(id, data, headers) {
        return http.patch(`offers/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`offers/${ id }`)
    }
}

export default new OfferDataService()