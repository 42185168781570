<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedMailAccounts.length === 1">Czy na pewno chcesz usunąć {{ checkedMailAccounts.length }} konto pocztowe?</div>
            <div v-if="checkedMailAccounts.length > 1 && checkedMailAccounts.length < 5">Czy na pewno chcesz usunąć {{ checkedMailAccounts.length }} konta pocztowe?</div>
            <div v-if="checkedMailAccounts.length > 4">Czy na pewno chcesz usunąć {{ checkedMailAccounts.length }} kont pocztowych?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteMailAccounts(); unselectMailAccounts(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Filtr: Status -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Status
      </material-button>
      <ul class="px-2 dropdown-menu shadow" style="max-height: 400px; overflow-y: auto;">
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeStatus('Aktywne')">
            Aktywne
          </a>
        </li>
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeStatus('Zablokowane')">
            Zablokowane
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_status = []; getMailAccounts(1)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Przycisk: Nowe konto pocztowe -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Nowe konto pocztowe')"
      >
        + &nbsp; Nowe konto pocztowe
      </material-button>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_status"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeStatus(item)">close</i>
      </material-badge>
    </div>

    <!-- Tabela: Konta pocztowe -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Konta pocztowe</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_accountName"
                      v-model="filter_accountName"
                      :items="mailAccountsAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    >
                    </SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="selectMailAccounts($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('accountName')">Nazwa konta</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('accountMail')">Adres e-mail</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('status')">Status</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="checkedMailAccounts.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(0)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <!-- Konta pocztowe -->
                      <tr v-for="item in mailAccounts" :key="item">
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="selectMailAccount($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa konta -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd konta pocztowego', item.id)">
                          {{ item.accountName }}
                        </td>

                        <!-- Tabela: Adres e-mail -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd konta pocztowego', item.id)">
                          {{ item.accountMail }}
                        </td>

                        <!-- Tabela: Status -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd konta pocztowego', item.id)">
                          <span class="me-1" :class="item.status === 'Aktywne' ? 'text-success' : 'text-dark'">&#x25cf;</span>
                          {{ item.status }}
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
                          </a>
                          <ul class="px-2 py-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="$redirectID('Podgląd konta pocztowego', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$redirectID('Edycja konta pocztowego', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unselectMailAccounts(); item.mark = true; checkedMailAccounts.push(item); openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak kont pocztowych -->
                      <tr :class="mailAccounts.length > 0 ? 'd-none' : null">
                        <td class="dataTables-empty text-sm" colspan="5">Nie znaleziono kont pocztowych</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="mailAccounts.length !== 0" class="dataTable-bottom">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "MailAccounts",
  components: {
    MaterialCheckbox,
    MaterialBadge,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      mailAccounts: [], // Tablica zawierająca dane kont pocztowych z API
      mailAccountsAC: [], // Tablica zawierająca nazwę konta pocztowego z API (AutoComplete)

      checkedMailAccounts: [], // Tablica zawierająca wszystkie zaznaczone konta pocztowe
      modals: [], // Tablica odpowiedzialna za status modali

      column: "accountName", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania
      
      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_accountName: "", // Filtr: Nazwa konta
      filter_status: [], // Filtr: Status
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_accountName(newSearch) {
      this.filter_accountName = newSearch
      this.getMailAccountsDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MAIL_ACCOUNTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getMailAccounts(this.page)
          this.getMailAccountsAC()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane kont pocztowych z API
    getMailAccounts(page) {
      let status = ""
      this.filter_status.forEach(item => {
        status += `&status[]=${ item }`
      })

      MailAccountDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&accountName=${ this.filter_accountName }${ status }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.mailAccounts = res.data["hydra:member"]
        this.unselectMailAccounts()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane kont pocztowych z API z opóźnieniem
    getMailAccountsDelay: _.debounce(function(page) {
      let status = ""
      this.filter_status.forEach(item => {
        status += `&status[]=${ item }`
      })

      MailAccountDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&accountName=${ this.filter_accountName }${ status }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.mailAccounts = res.data["hydra:member"]
        this.unselectMailAccounts()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja wczytuje wszystkich użytkowników z API
    getMailAccountsAC() {
      MailAccountDataService.getAll("")
      .then(res => {
        this.mailAccountsAC = []
        res.data["hydra:member"].forEach(item => {
          this.mailAccountsAC.push(item.accountName)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca zaznaczone konto pocztowe do listy
    selectMailAccount(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedMailAccounts.push(item)
      }
      else {
        this.checkedMailAccounts.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedMailAccounts.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie konta pocztowe
    selectMailAccounts(e) {
      this.checkedMailAccounts = []
      if(e.target.checked) {
        this.mailAccounts.forEach(item => {
          item.mark = true
          this.checkedMailAccounts.push(item)
        })
      }
      else {
        this.mailAccounts.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie konta pocztowe
    unselectMailAccounts() {
      this.checkedMailAccounts = []
      this.mailAccounts.forEach(item => {
        item.mark = false
      })
    },

    // Funckja usuwająca konta pocztowe
    deleteMailAccounts() {
      this.checkedMailAccounts.forEach(item => {
        MailAccountDataService.delete(item.id)
        .then(res => {
          console.log(res.data)
          this.getMailAccounts(this.page)
          this.getMailAccountsAC()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
      })
    },

    // Funkcja przypisująca wybraną nazwę konta do zmiennej
    selectItem(item) {
      this.filter_accountName = item
      this.getMailAccounts(1)
    },

    // Funkcja przypisująca do zmiennej wybrany status
    onChangeStatus(event) {
      let exist = false

      this.filter_status.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_status.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_status.push(event)
      }

      this.getMailAccounts(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getMailAccounts(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getMailAccounts(page)
      }
      else {
        this.getMailAccounts(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getMailAccounts(page)
      }
      else {
        this.getMailAccounts(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder == "asc") {
        this.columnOrder = "desc"
        this.getMailAccounts(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getMailAccounts(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>