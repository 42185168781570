import http from "@/http-common"

class ConversationDataService {
    getAll(url) {
        return http.get(`conversations${ url }`)
    }

    get(id) {
        return http.get(`conversations/${ id }`)
    }

    post(data) {
        return http.post('conversations', data)
    }

    patch(id, data, headers) {
        return http.patch(`conversations/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`conversations/${ id }`)
    }
}

export default new ConversationDataService()