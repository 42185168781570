<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      :class="color"
      type="radio"
      :name="name"
      :checked="checked"
    />
    <label class="custom-control-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MaterialRadio",
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false,
      default: "radio"
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["color"])
  }
}
</script>

<style scoped>
.primary:checked::after {
  background: #e91e63 !important;
}

.dark:checked::after {
  background: #344767 !important;
}

.info:checked::before {
  background: transparent !important;
}

.info:checked::after {
  background: #03a9f4 !important;
}

.success:checked::after {
  background: #4caf50 !important;
}

.warning:checked::after {
  background: #fb8c00 !important;
}

.danger:checked::after {
  background: #f44335 !important;
}
</style>