<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-start mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Szablony wydruku')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-md-8 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Nowy szablon wydruku</h5>
          </div>
          <div class="card-body pt-1 pb-6">
            <div class="row">
              <div class="col-12">
                <!-- eslint-disable -->
                <div
                  id="editor"
                  class="p-2"
                  style="min-height: 500px"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-1">
            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa szablonu wydruku
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="printTemplate.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <h5 class="my-3">Moduły</h5>

            <p
              v-for="(item, index) in modules"
              :key="item"
              class="d-flex align-items-center text-sm cursor-pointer"
              :class="index !== modules.length - 1 ? 'mb-2' : ' mb-0'"
              @click="pushToText(item.value)"
            >
              <i class="material-icons text-sm me-1">add</i>
              {{ item.name }}
            </p>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validation()"
              >
                Dodaj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import PrintTemplateDataService from "@/services/PrintTemplateDataService"
import { mapState } from "vuex"
import Quill from "quill"

export default {
  name: "NewPrintTemplate",
  components: {
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      printTemplate: {
        name: ""
      }, // Obiekt tworzonego szablonu wydruku

      modules: [
        {
          name: "Logotyp firmy",
          value: "<logotyp_firmy>"
        },
        {
          name: "Data oferty",
          value: "<data_oferty>"
        },
        {
          name: "Tytuł oferty",
          value: "<tytul_oferty>"
        },
        {
          name: "Opis oferty",
          value: "<opis_oferty>"
        },
        {
          name: "Dane kontrahenta",
          value: "<dane_kontrahenta>"
        },
        {
          name: "Dane firmy",
          value: "<dane_firmy>"
        },
        {
          name: "Zestawienie towarów",
          value: "<zestawienie_towarow>"
        },
        {
          name: "Zestawienie usług",
          value: "<zestawienie_uslug>"
        },
        {
          name: "Zestawienie usług zewnętrznych",
          value: "<zestawienie_uslug_zewnetrznych>"
        },
        {
          name: "Podsumowanie oferty",
          value: "<podsumowanie_oferty>"
        },
        {
          name: "Podział strony",
          value: "<podzial_strony>"
        },
        {
          name: "Osoba przygotowująca umowę",
          value: "<osoba_przygotowywujaca_umowe>"
        }
      ], // Wszystkie dostępne moduły

      quill: null, // Obiekt stworzonego edytora

      error: "", // Zmienna odpowiedzialna za błąd danych
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_PRINT_TEMPLATES")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.initializeQuill()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      if (this.printTemplate.name === "") {
        success = false
        this.error = "Nazwa szablonu wydruku nie może być pusta"
      }

      if (this.printTemplate.name.length > 65) {
        success = false
        this.error = "Nazwa szablonu wydruku jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.postPrintTemplate()
      }
    },

    // Funkcja dodająca nowy szablon wydruku do API
    postPrintTemplate() {
      PrintTemplateDataService.post(
        {
          name: this.printTemplate.name,
          text: this.quill.root.innerHTML
        }
      )
      .then(res => {
        console.log(res.data)
        this.$redirect("Szablony wydruku")
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if (JSON.stringify(error.response.status) == 422) {
          this.error = "Nazwa szablonu wydruku jest już zajęta!"
        }
      })
    },

    // Funkcja inicjalizująca Quill
    initializeQuill () {
      this.$nextTick(() => {
        if (this.quill) {
          this.quill.destroy()
          this.quill = null
        }

        this.quill = new Quill("#editor", {
          modules: {
            toolbar: [
              [{ "size": ["small", false, "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              [{ "color": [] }, { "background": [] }],
              [{ "list": "ordered" }, { "list": "bullet" }, { "align": [] }],
              ["image"]
            ]
          },
          theme: "snow"
        })
      })
    },

    // Funkcja dodająca moduł do szablonu wydruku
    pushToText(text) {
      this.quill.focus()
      const range = this.quill.getSelection(true)
      if (range && range.index !== undefined && this.quill.getText().trim().length > 0) {
        this.quill.insertText(range.index, text)
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>