<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="markedOffers.length === 1">Czy na pewno chcesz usunąć {{ markedOffers.length }} ofertę?</div>
            <div v-if="markedOffers.length > 1 && markedOffers.length < 5">Czy na pewno chcesz usunąć {{ markedOffers.length }} oferty?</div>
            <div v-if="markedOffers.length > 4">Czy na pewno chcesz usunąć {{ markedOffers.length }} ofert?</div>
            
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteOffers(); unmarkOffers(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Szablon wydruku -->
    <div v-if="modals[1]" id="templateCss" class="modal-dialog">
      <div class="modal-content">
        <div class="card mb-3 p-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
          <div class="d-flex align-items-center justify-content-between px-3 py-2">
            <h5 class="my-auto">{{ offer.printTemplate.name }}</h5>
            <span class="d-flex align-items-center" @click="closeModal()">
              <i class="material-symbols-outlined text-lg cursor-pointer me-3" @click="offerAsAttachment()">attach_email</i>
              <i class="material-symbols-outlined text-lg cursor-pointer me-3" @click="printOffer()">print</i>
              <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
            </span>
          </div>
        </div>
        
        <!-- eslint-disable-next-line -->
        <div v-for="item in pages" :key="item" id="print-overview" class="card mb-3 p-4 bg-white text-dark" v-html="item"></div>

        <div class="d-none">
          <div id="printable">
            <div v-for="(item, index) in pages" :key="item">
              <!-- eslint-disable-next-line -->
              <span v-html="item" style="color: black !important"></span>
              <div v-if="index !== pages.length - 1" class="html2pdf__page-break"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex justify-content-end mb-4">
      <!-- Filtr: Kontrahent -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Kontrahent
      </material-button>
      <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li v-for="item in customers" :key="item">
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeCustomer(item.displayName)">
            {{ item.displayName }}
          </a>
        </li>
        <li v-if="customers.length !== 0">
          <hr class="horizontal bg-light my-2">
        </li>
        <li @click="filter_customers = []; getOffers(page)">
          <a class="dropdown-item border-radius-md text-danger cursor-pointer"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Przycisk: Nowa oferta -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Nowa oferta')"
      >
        + &nbsp; Nowa oferta
      </material-button>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_customers"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCustomer(item)">close</i>
      </material-badge>
    </div>
    
    <!-- Tabela: Oferty -->
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Oferty</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="searchRef"
                      v-model="filter_name"
                      :items="offersAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="markOffers($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 35%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('customer.displayName')">Kontrahent</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter">Wartość (Netto)</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('createdAt')">Data założenia</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="markedOffers.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(0)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in offers" :key="item">
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="markOffer($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                          <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Kontrahent -->
                        <td class="ps-0 cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                          <p class="m-0 text-xs">{{ item.customer ? item.customer.displayName : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Wartość (Netto) -->
                        <td class="ps-0 cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                          <p class="m-0 text-xs">{{ total(item).toFixed(2) }} PLN</p>
                        </td>

                        <!-- Tabela: Data założenia -->
                        <td class="ps-0 cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                          <p class="m-0 text-xs">{{ moment(item.createdAt).format("DD/MM/YYYY") }}</p>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$redirectID('Edycja oferty', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr v-if="item.printTemplate" class="horizontal my-1 bg-light">
                            <li v-if="item.printTemplate" class="cursor-pointer" @click="offer = item; replaceText(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd wydruku
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unmarkOffers(); item.mark = true; markedOffers.push(item); openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak ofert -->
                      <tr :class="offers.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="6">Nie znaleziono ofert</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Podsumowanie -->
                <div v-if="offers.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown text-xs">
                    <span class="me-3">Ilość ofert: {{ offers.length }}</span>
                    Wartość ofert: {{ `${ totalPLN.toFixed(2) } PLN` }}
                  </div>
                  <div class="dataTable-pagination"></div>
                </div>

                <!-- Paginacja -->
                <div v-if="offers.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import OfferDataService from "@/services/OfferDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"
import _ from "lodash"
import $ from "jquery"

export default {
  name: "Offers",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      offers: [], // Tablica zawierająca dane ofert z API
      offersAC: [], // Tablica zawierająca nazwy ofert z API

      customers: [], // Tablica zawierająca wszystkich kontrahentów z API

      markedOffers: [], // Tablica zawierająca wszystkie zaznaczone oferty
      modals: [], // Tablic odpowiedzialna za status modali

      pages: [], // Tablica zawierająca strony pdfa
      offer: null, // Obiekt zawierający ofertę drukowanego pdfa

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "name", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_name: "", // Filtr: Nazwa
      filter_customers: [], // Filtr: Kontrahent
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    totalPLN: function() {
      let result = 0

      this.offers.forEach(item => {
        result += this.total(item)
      })

      return result
    }
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getOffersDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_OFFERS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getOffers(this.page)
          this.getOffersAC()
          this.getCustomers()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca oferty z API
    getOffers(page) {
      let customers = ""
      this.filter_customers.forEach(item => {
        customers += `&customer.displayName[]=${ item }`
      })

      OfferDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ customers }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.offers = res.data["hydra:member"]
        this.unmarkOffers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca oferty z API z opóźnieniem
    getOffersDelay: _.debounce(function (page) {
      let customers = ""
      this.filter_customers.forEach(item => {
        customers += `&customer.displayName[]=${ item }`
      })

      OfferDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ customers }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.offers = res.data["hydra:member"]
        this.unmarkOffers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja pobierająca nazwy ofert z API
    getOffersAC() {
      OfferDataService.getAll("")
      .then(res => {
        this.offersAC = []
        res.data["hydra:member"].forEach(item => {
          this.offersAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich kontrahentów z API
    getCustomers() {
      OfferDataService.getAll("")
      .then(res => {
        this.customers = res.data["hydra:member"]
          .map(item => item.customer)
          .filter(customer => customer != null)
          .filter((customer, index, self) => 
            index == self.findIndex(t => t.id == customer.id))

        console.log(this.customers)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca ofertę z API
    deleteOffer(id) {
      OfferDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getOffers(this.page)
        this.getOffersAC()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane oferty z API
    deleteOffers() {
      this.markedOffers.forEach(item => {
        this.deleteOffer(item.id)
      })
    },

    // Funkcja zaznaczająca wybraną ofertę
    markOffer(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.markedOffers.push(item)
      }
      else {
        this.markedOffers.forEach((offer, index) => {
          if (item.id === offer.id) {
            this.markedOffers.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie oferty
    markOffers(e) {
      this.markedOffers = []
      if (e.target.checked) {
        this.offers.forEach(item => {
          item.mark = true
          this.markedOffers.push(item)
        })
      }
      else {
        this.offers.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie oferty
    unmarkOffers() {
      this.markedOffers = []
      this.offers.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja podmieniająca zmienne w szablonie wydruku na odpowiedni tekst
    replaceText(id) {
      OfferDataService.replace(id)
      .then(res => {
        this.pages = res.data["hydra:member"]

        setTimeout(function() {
          $("#print-overview p").each(function() {
            var $this = $(this)
            if ($this.css("color") === "rgb(255, 255, 255)") {
              $this.attr("style", function() {
                return "color: black !important;"
              })
            }
          })
        }, 10)

        setTimeout(function() {
          $("img").addClass("w-100")
        }, 10)

        this.openModal(1)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wysyłająca szablon wydruku oferty
    offerAsAttachment() {
      const element = document.getElementById("printable")
      const parameters = {
        margin: 10,
        filename: this.moment(this.offer.createdAt).format("YYYY.MM.DD") + [this.offer.customer ? "_" + this.offer.customer.displayName : ""] + " - " + this.offer.name + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).toPdf().get("pdf").then(pdf => {
        const blob = new Blob([pdf.output("blob")], { type: "application/pdf" })
        const file = new File([blob], parameters.filename, { type: "application/pdf" })
        this.$store.state.mail.attachments.push(file)
        this.$redirect("Nowa wiadomość")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja drukująca szablon wydruku oferty
    printOffer() {
      const element = document.getElementById("printable")
      const parameters = {
        margin: 10,
        filename: this.moment(this.offer.createdAt).format("YYYY.MM.DD") + [this.offer.customer ? "_" + this.offer.customer.displayName : ""] + " - " + this.offer.name + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).save()
    },

    // Funkcja licząca wartość wybranej oferty
    total(item) {
      let result = 0

      let discountServices = item.discountServices > 100 ? 100 : item.discountServices
      let discountProducts = item.discountProducts > 100 ? 100 : item.discountProducts
      let discountExternals = item.discountExternals > 100 ? 100 : item.discountExternals

      item.products.forEach(product => {
        if (product.type === "product") {
          result += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
        }

        if (product.type === "service") {
          result += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
        }

        if (product.type === "external") {
          result += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
        }
      })

      return result
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getOffers(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeCustomer(event) {
      let exist = false

      this.filter_customers.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_customers.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_customers.push(event)
      }

      this.getOffers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getOffers(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getOffers(page)
      }
      else {
        this.getOffers(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getOffers(page)
      }
      else {
        this.getOffers(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getOffers(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getOffers(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

#templateCss {
  max-width: 650px !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>