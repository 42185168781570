import http from "@/http-common"

class DefaultInputDataService {
    getAll(url) {
        return http.get(`default_inputs${ url }`)
    }

    get(id) {
        return http.get(`default_inputs/${ id }`)
    }

    post(data) {
        return http.post('default_inputs', data)
    }

    patch(id, data, headers) {
        return http.patch(`default_inputs/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`default_inputs/${ id }`)
    }
}

export default new DefaultInputDataService()