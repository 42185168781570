<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Karty pracy</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          Klient
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">
                          Data
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">
                          Status
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 65%">
                          Stawka klient
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in worksheets"
                        :key="item"
                        class="cursor-pointer"
                        @click="goToWorksheet(item.id)"
                      >
                        <!-- Tabela: Klient -->
                        <td class="text-wrap">
                          <p class="m-0 text-xs">{{ item.klient }}</p>
                        </td>

                        <!-- Tabela: Data -->
                        <td class="ps-0 text-wrap">
                          <p class="m-0 text-xs">{{ item.data }}</p>
                        </td>

                        <!-- Tabela: Status -->
                        <td class="ps-0">
                          <material-badge v-if="item.status == 0" color="primary" size="sm" class="m-0">Nowa</material-badge>
                          <material-badge v-if="item.status == 1" color="warning" size="sm" class="m-0">Gotowa</material-badge>
                          <material-badge v-if="item.status == 2" color="dark" size="sm" class="m-0">Anulowana</material-badge>
                          <material-badge v-if="item.status == 4" color="danger" size="sm" class="m-0">Odrzucona</material-badge>
                          <material-badge v-if="item.status == 5" color="success" size="sm" class="m-0">Zaakceptowana</material-badge>
                        </td>

                        <!-- Tabela: Stawka klient -->
                        <td class="ps-0 text-wrap">
                          <p class="m-0 text-xs">{{ item.stawkaKlient }} PLN</p>
                        </td>
                      </tr>

                      <!-- Brak kart pracy -->
                      <tr :class="worksheets.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="2">Nie znaleziono kart pracy</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import UserDataService from "@/services/UserDataService"
import AsomDataService from "@/services/AsomDataService"
import { mapState } from "vuex"

export default {
  name: "Worksheets",
  components: {
    MaterialBadge,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
      worksheets: [], // Tablica zawierająca dane kart pracy z API
    }
  },
  computed: {
    ...mapState(["color"]),
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_ASOM_WORKSHEETS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getWorksheets()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca przeglądy z API
    getWorksheets() {
      AsomDataService.getWorksheets("")
      .then(res => {
        this.worksheets = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja przenosząca do karty pracy
    goToWorksheet(id) {
      window.open(`https://system.asom.pl/panel/nowy_layout/oferty/karta/pobierz/${ id }`, "_blank")
    },
  },
}
</script>