<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Szablon wydruku -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card mb-3 p-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
          <div class="d-flex align-items-center justify-content-between px-3 py-2">
            <h5 class="my-auto">{{ offer.printTemplate.name }}</h5>
            <span class="d-flex align-items-center" @click="closeModal()">
              <i class="material-symbols-outlined text-lg cursor-pointer me-3" @click="offerAsAttachment()">attach_email</i>
              <i class="material-symbols-outlined text-lg cursor-pointer me-3" @click="printOffer()">print</i>
              <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
            </span>
          </div>
        </div>
        
        <!-- eslint-disable-next-line -->
        <div v-for="item in pages" :key="item" id="print-overview" class="card mb-3 p-4 bg-white text-dark" v-html="item"></div>

        <div class="d-none">
          <div id="printable">
            <div v-for="(item, index) in pages" :key="item">
              <!-- eslint-disable-next-line -->
              <span v-html="item" style="color: black !important"></span>
              <div v-if="index !== pages.length - 1" class="html2pdf__page-break"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me && offer" class="container-fluid">
    <div class="d-sm-flex justify-content-between mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Oferty')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>

      <!-- Przycisk: Podgląd wydruku -->
      <material-button
        v-if="offer.printTemplate"
        class="d-flex align-items-center"
        :color="color"
        variant="gradient"
        @click="replaceText()"
      >
        <i class="material-symbols-outlined text-lg me-2">print</i> Podgląd wydruku
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item mb-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <div v-if="offer.template">
              <li v-for="category in offer.template.categories" :key="category" class="nav-item mb-2">
                <a class="nav-link text-dark d-flex align-items-center" data-scroll :href="`#products${ category.id }`" :class="isDarkMode ? 'text-white' : 'text-dark'">
                  <i class="material-symbols-outlined text-lg me-2">category</i>
                  <span class="text-sm">Kategoria: {{ category.name }}</span>
                </a>
              </li>
            </div>
            <li class="nav-item mb-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#products" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">category</i>
                <span class="text-sm">Kategoria: Wszystkie</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#summary" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">summarize</i>
                <span class="text-sm">Podsumowanie</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Nazwa oferty: <span class="font-weight-normal ms-2">{{ offer.name ? offer.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Szablon: <span class="font-weight-normal ms-2">{{ offer.template ? offer.template.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kontrahent: <span class="font-weight-normal ms-2" :class="offer.customer ? 'cursor-pointer' : ''" @click="$store.state.openedCustomer = offer.customer.id; $redirect('Kontrahenci')">{{ offer.customer ? offer.customer.displayName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Szablon wydruku: <span class="font-weight-normal ms-2">{{ offer.printTemplate ? offer.printTemplate.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Oferta do sprzedaży: <span class="font-weight-normal ms-2" :class="offer.sale ? 'cursor-pointer' : ''" @click="$store.state.openedSale = offer.sale.id; $redirect('Sprzedaż')">{{ offer.sale ? offer.sale.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Notatka: <span class="font-weight-normal ms-2">{{ offer.note ? offer.note : "Brak" }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="offer.template">
          <div v-for="category in offer.template.categories" :id="`products${ category.id }`" :key="category" class="card mb-4 z-index-0">
            <div class="card-header pb-1">
              <h5>Kategoria: {{ category.name }}</h5>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive pt-1 overflow-hidden">
                <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                  <!-- Tabela -->
                  <div class="dataTable-container">
                    <table class="table table-flush dataTable-table">
                      <!-- Kolumny -->
                      <thead class="thead-light">
                        <tr>
                          <td class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">Typ</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 45%">Nazwa</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">Cena za jednostkę</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">RBH</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Ilość</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">VAT</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Wartość</td>
                        </tr>
                      </thead>

                      <!-- Wiersze -->
                      <tbody>
                        <tr
                          v-for="(item, index) in offer.products"
                          :key="index"
                          :class="item.category && item.category.id === category.id ? '' : 'd-none'"
                        >
                          <!-- Typ -->
                          <td class="text-center">
                            <p class="m-0 text-xs">{{ item.type === "product" ? "TW" : item.type === "service" ? "US" : "UZ" }}</p>
                          </td>

                          <!-- Nazwa -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                          </td>

                          <!-- Cena za sztukę -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ item.price ? item.price.toFixed(2) : 0 }}</p>
                          </td>

                          <!-- RBH -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ item.type === "service" ? item.rbh : "-" }}</p>
                          </td>

                          <!-- Ilość -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ item.quantity ? item.quantity : 0 }}</p>
                          </td>

                          <!-- VAT -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ isNaN(parseInt(item.vat, 10)) ? item.vat : `${ item.vat }%` }}</p>
                          </td>

                          <!-- Wartość -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ (item.price * item.rbh * item.quantity).toFixed(2) }} PLN</p>
                          </td>
                        </tr>

                        <!-- Brak produktów -->
                        <tr :class="offer.products.length > 0 ? 'd-none' : ''">
                          <td class="dataTables-empty text-xs" colspan="8">Brak produktów</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="products" class="card mb-4 z-index-0">
          <div class="card-header pb-1">
            <h5>Kategoria: Wszystkie</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1 overflow-hidden">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">Typ</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 45%">Nazwa</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">Cena za jednostkę</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">RBH</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Ilość</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">VAT</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Wartość</td>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <tr
                        v-for="(item, index) in offer.products"
                        :key="index"
                        :class="item.category ? 'd-none' : ''"
                      >
                        <!-- Typ -->
                        <td class="text-center">
                          <p class="m-0 text-xs">{{ item.type === "product" ? "TW" : item.type === "service" ? "US" : "UZ" }}</p>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Cena za sztukę -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.price ? item.price.toFixed(2) : 0 }}</p>
                        </td>

                        <!-- RBH -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.type === "service" ? item.rbh : "-" }}</p>
                        </td>

                        <!-- Ilość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.quantity ? item.quantity : 0 }}</p>
                        </td>

                        <!-- VAT -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ isNaN(parseInt(item.vat, 10)) ? item.vat : `${ item.vat }%` }}</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ (item.price * item.rbh * item.quantity).toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <!-- Brak produktów -->
                      <tr :class="offer.products.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-xs" colspan="8">Brak produktów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="summary" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Podsumowanie</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="table-responsive pt-1 overflow-hidden">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 80%">Nazwa</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">Wartość</td>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość usług</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalServices.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość towarów</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalProducts.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość usług zewnętrznych</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalExternals.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <td class="ps-0" style="text-align: right">
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Netto:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na towary ( {{ offer.discountProducts }}% ):
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na usługi ( {{ offer.discountServices }}% ):
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na usługi zewnętrzne ( {{ offer.discountExternals }}% ):
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Netto:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">VAT:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Brutto:</p>
                        </td>

                        <td class="ps-0">
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ total.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountProducts.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountServices.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountExternals.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ (total - (discountServices + discountProducts + discountExternals)).toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ vat.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ (total - (discountServices + discountProducts + discountExternals) + vat).toFixed(2) }} PLN</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="$redirectID('Edycja oferty', offer.id)"
          >
            Edytuj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import OfferDataService from "@/services/OfferDataService"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"
import $ from "jquery"

export default {
  name: "OverviewOffer",
  components: {
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika
      offer: null, // Obiekt podglądanej oferty
      pages: [], // Tablica zawierająca strony pdfa
      modals: [], // Tablic odpowiedzialna za status modali
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    totalProducts: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "product") {
          result += (item.price * item.rbh * item.quantity)
        }
      })

      return result
    },

    totalServices: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "service") {
          result += (item.price * item.rbh * item.quantity)
        }
      })

      return result
    },

    totalExternals: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "external") {
          result += (item.price * item.rbh * item.quantity)
        }
      })

      return result
    },

    discountProducts: function() {
      let result = 0
      let discount = this.offer.discountProducts > 100 ? 100 : this.offer.discountProducts

      this.offer.products.forEach(item => {
        if (item.type === "product") {
          result += (item.price * item.rbh * item.quantity) * (discount / 100)
        }
      })

      return result
    },

    discountServices: function() {
      let result = 0
      let discount = this.offer.discountServices > 100 ? 100 : this.offer.discountServices

      this.offer.products.forEach(item => {
        if (item.type === "service") {
          result += (item.price * item.rbh * item.quantity) * (discount / 100)
        }
      })

      return result
    },

    discountExternals: function() {
      let result = 0
      let discount = this.offer.discountExternals > 100 ? 100 : this.offer.discountExternals

      this.offer.products.forEach(item => {
        if (item.type === "external") {
          result += (item.price * item.rbh * item.quantity) * (discount / 100)
        }
      })

      return result
    },

    vat: function() {
      let result = 0

      let discountServices = this.offer.discountServices > 100 ? 100 : this.offer.discountServices
      let discountProducts = this.offer.discountProducts > 100 ? 100 : this.offer.discountProducts
      let discountExternals = this.offer.discountExternals > 100 ? 100 : this.offer.discountExternals
      
      this.offer.products.forEach(item => {
        if (item.type === "product") {
          if (discountProducts > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (discountProducts / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }

        if (item.type === "service") {
          if (discountServices > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (discountServices / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }

        if (item.type === "external") {
          if (discountExternals > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (discountExternals / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }
      })

      return result
    },

    total: function() {
      let result = 0

      this.offer.products.forEach(item => {
        result += (item.price * item.rbh * item.quantity)
      })

      return result
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_OFFERS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getOffer()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybraną ofertę
    getOffer() {
      OfferDataService.get(this.$route.params.id)
      .then(res => {
        this.offer = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja obliczająca VAT dla produktu
    countVAT(value) {
      const parsedVAT = parseInt(value, 10)
      return isNaN(parsedVAT) ? 0 : (parsedVAT / 100)
    },

    // Funkcja podmieniająca zmienne w szablonie wydruku na odpowiedni tekst
    replaceText() {
      OfferDataService.replace(this.offer.id)
      .then(res => {
        this.pages = res.data["hydra:member"]

        setTimeout(function() {
          $("#print-overview p").each(function() {
            var $this = $(this)
            if ($this.css("color") === "rgb(255, 255, 255)") {
              $this.attr("style", function() {
                return "color: black !important;"
              })
            }
          })
        }, 10)

        setTimeout(function() {
          $("img").addClass("w-100")
        }, 10)

        this.openModal(0)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wysyłająca szablon wydruku oferty
    offerAsAttachment() {
      const element = document.getElementById("printable")
      const parameters = {
        margin: 10,
        filename: this.moment(this.offer.createdAt).format("YYYY.MM.DD") + [this.offer.customer ? "_" + this.offer.customer.displayName : ""] + " - " + this.offer.name + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).toPdf().get("pdf").then(pdf => {
        const blob = new Blob([pdf.output("blob")], { type: "application/pdf" })
        const file = new File([blob], parameters.filename, { type: "application/pdf" })
        this.$store.state.mail.attachments.push(file)
        this.$redirect("Nowa wiadomość")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja drukująca szablon wydruku oferty
    printOffer() {
      const element = document.getElementById("printable")
      const parameters = {
        margin: 10,
        filename: this.moment(this.offer.createdAt).format("YYYY.MM.DD") + [this.offer.customer ? "_" + this.offer.customer.displayName : ""] + " - " + this.offer.name + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).save()
    },
    
    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-dialog {
  max-width: 650px !important;
}

.modal-content {
  background: none;
  border: none;
}
</style>