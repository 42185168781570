<template>
  <div v-if="me" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Konta pocztowe')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#incoming" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">south</i>
                <span class="text-sm">Konto przychodzące</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#outgoing" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">north</i>
                <span class="text-sm">Konto wychodzące</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#members" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Przypisani użytkownicy</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje ogólne -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa konta
                  </label>
                  <input
                    v-model="mailAccount.accountName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres e-mail
                  </label>
                  <input
                    v-model="mailAccount.accountMail"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="error !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ error }}</p>
        </div>

        <!-- Konto przychodzące -->
        <div id="incoming" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Konto przychodzące</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-2">
                <label class="d-flex align-items-center mx-0 text-sm">
                  <material-checkbox class="me-1" :checked="mailAccount.iaSsl" @change="mailAccount.iaSsl = !mailAccount.iaSsl" /> Użyj SSL
                </label>
              </div>        
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label class="form-label ms-0">Protokół odczytu poczty</label>
                <select
                  class="px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                >
                  <option value="imap" selected>IMAP</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label class="form-label ms-0">Odbieraj co</label>
                <select
                  class="px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                >
                  <option value="1" selected>1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres serwera
                  </label>
                  <input
                    v-model="mailAccount.iaServerAddress"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Port
                  </label>
                  <input
                    v-model="mailAccount.iaPort"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa użytkownika
                  </label>
                  <input
                    v-model="mailAccount.iaUsername"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Hasło
                  </label>
                  <input
                    v-model="mailAccount.iaPassword"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorIA !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorIA }}</p>
        </div>

        <!-- Konto wychodzące -->
        <div id="outgoing" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Konto wychodzące</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-2">
                <label class="d-flex align-items-center mx-0 text-sm">
                  <material-checkbox class="me-1" :checked="mailAccount.oaSsl" @change="mailAccount.oaSsl = !mailAccount.oaSsl" /> Użyj SSL
                </label>
              </div>        
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres serwera
                  </label>
                  <input
                    v-model="mailAccount.oaServerAddress"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Port
                  </label>
                  <input
                    v-model="mailAccount.oaPort"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa użytkownika
                  </label>
                  <input
                    v-model="mailAccount.oaUsername"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Hasło
                  </label>
                  <input
                    v-model="mailAccount.oaPassword"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorOA !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorOA }}</p>
        </div>

        <!-- Przypisani użytkownicy -->
        <div id="members" class="card mb-4">
          <div class="card-header pb-0">
            <h5>Przypisani użytkownicy</h5>
            <p class="mb-0 text-sm">
              Wybierz użytkowników, którzy mają mieć dostęp do tego konta.
            </p>
          </div>
          <div class="card-body pt-0">
            <div v-if="mailAccount.members.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak podłączonych użytkowników
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in mailAccount.members"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.fullName }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="users.push(item); usersAC.push(item.fullName); mailAccount.members.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0">Podłącz użytkownika</label>
                <SimpleTypeahead
                  ref="usersAC"
                  :items="usersAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-2"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectItem"
                >
                </SimpleTypeahead>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color" 
            variant="gradient"
            @click="validation()"
          >
            Dodaj
          </material-button>

          <material-button
            class="ms-3"
            :color="color"
            variant="gradient"
            @click="addAnother = true; validation()"
          >
            Dodaj i utwórz kolejne
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Konta pocztowe')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import { mapState } from "vuex"

export default {
  name: "NewMailAccount",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      mailAccount: {
        accountName: "",
        accountMail: "",
        iaSsl: false,
        iaServerAddress: "",
        iaPort: null,
        iaUsername: "",
        iaPassword: "",
        oaSsl: false,
        oaServerAddress: "",
        oaPort: null,
        oaUsername: "",
        oaPassword: "",
        members: [],
      }, // Obiekt tworzonego konta pocztowego 

      users: [], // Tablica wszystkich użytkowników
      usersAC: [], // Tablica wszystkich imion i nazwisk użytkowników

      error: "", // Zmienna odpowiedzialna za błąd danych
      errorIA: "", // Zmienna odpowiedzialna za błąd danych
      errorOA: "", // Zmienna odpowiedzialna za błąd danych

      addAnother: false, // Zmienna sprawdzająca czy dodać kolejne konto
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MAIL_ACCOUNTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getUsers()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true

      this.error = ""
      this.errorIA = ""
      this.errorOA = ""

      if (this.mailAccount.accountName === "" || this.mailAccount.accountMail === "") {
        success = false
        this.error = "Pola nie mogą być puste!"
      }

      if (this.mailAccount.iaServerAddress === "" || this.mailAccount.iaPort === "" || this.mailAccount.iaUsername === "" || this.mailAccount.iaPassword === "") {
        success = false
        this.errorIA = "Pola nie mogą być puste!"
      }

      if (this.mailAccount.oaServerAddress === "" || this.mailAccount.oaPort === "" || this.mailAccount.oaUsername === "" || this.mailAccount.oaPassword === "") {
        success = false
        this.errorOA = "Pola nie mogą być puste!"
      }

      if (success) {
        this.postMailAccount()
      }
    },

    // Funkcja dodająca nowe konto pocztowe do bazy danych
    postMailAccount() {
      MailAccountDataService.post(this.mailAccount)
      .then(res => {
        console.log(res.data)
        if(this.addAnother) {
          this.mailAccount.accountName = ""
          this.mailAccount.accountMail = ""
          this.mailAccount.iaSsl = false
          this.mailAccount.iaServerAddress = ""
          this.mailAccount.iaPort = null
          this.mailAccount.iaUsername = ""
          this.mailAccount.iaPassword = ""
          this.mailAccount.oaSsl = false
          this.mailAccount.oaServerAddress = ""
          this.mailAccount.oaPort = null
          this.mailAccount.oaUsername = ""
          this.mailAccount.oaPassword = ""
          this.mailAccount.members = []
          this.getUsers()
        }
        else {
          this.$redirect("Konta pocztowe")
        }
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 400) {
          this.error = error.response.data.error
        }
      })
    },

    // Funkcja dodająca do tablicy tylko użytkowników bez konta użytkownika
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.users = []
        this.usersAC = []

        res.data["hydra:member"].forEach(item => {
          this.users.push(item)
          this.usersAC.push(item.fullName)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o użytkowniku z listy rozwijanej
    selectItem(user) {
      this.users.forEach((item, index) => {
        if(item.fullName == user) {
          this.mailAccount.members.push(item)
          this.users.splice(index, 1)
          this.usersAC.splice(index, 1)
        }
      })

      this.$refs.usersAC.clearInput()
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #eeeeee;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>