import { createRouter, createWebHistory } from "vue-router"
import SignIn from "@/views/SignIn.vue"
import ResetPassword from "@/views/ResetPassword.vue"
import Dashboard from "@/views/home/Dashboard.vue"
import Mailbox from "@/views/home/mailbox/Mailbox.vue"
import NewMail from "@/views/home/mailbox/NewMail.vue"
import Customers from "@/views/home/Customers.vue"
import Sale from "@/views/home/sale/Sale.vue"
import Offers from "@/views/home/sale/offers/Offers.vue"
import NewOffer from "@/views/home/sale/offers/NewOffer.vue"
import EditOffer from "@/views/home/sale/offers/EditOffer.vue"
import OverviewOffer from "@/views/home/sale/offers/OverviewOffer.vue"
import Templates from "@/views/home/sale/templates/Templates.vue"
import NewTemplate from "@/views/home/sale/templates/NewTemplate.vue"
import EditTemplate from "@/views/home/sale/templates/EditTemplate.vue"
import PrintTemplates from "@/views/home/sale/print-templates/PrintTemplates.vue"
import NewPrintTemplate from "@/views/home/sale/print-templates/NewPrintTemplate.vue"
import EditPrintTemplate from "@/views/home/sale/print-templates/EditPrintTemplate.vue"
import Categories from "@/views/home/sale/Categories.vue"
import Assortment from "@/views/home/sale/Assortment.vue"
import Calendar from "@/views/home/calendar/Calendar.vue"
import Tasks from "@/views/home/Tasks.vue"
import Reports from "@/views/home/reports/Reports.vue"
import PeriodicReports from "@/views/home/reports/PeriodicReports.vue"
import Inspections from "@/views/home/asom/Inspections.vue"
import Worksheets from "@/views/home/asom/Worksheets.vue"
import Overview from "@/views/home/profile/Overview.vue"
import Settings from "@/views/home/profile/Settings.vue"
import Users from "@/views/home/admin/users/Users.vue"
import NewUser from "@/views/home/admin/users/NewUser.vue"
import EditUser from "@/views/home/admin/users/EditUser.vue"
import OverviewUser from "@/views/home/admin/users/OverviewUser.vue"
import Groups from "@/views/home/admin/groups/Groups.vue"
import NewGroup from "@/views/home/admin/groups/NewGroup.vue"
import EditGroup from "@/views/home/admin/groups/EditGroup.vue"
import OverviewGroup from "@/views/home/admin/groups/OverviewGroup.vue"
import MailAccounts from "@/views/home/admin/mail-accounts/MailAccounts.vue"
import NewMailAccount from "@/views/home/admin/mail-accounts/NewMailAccount.vue"
import EditMailAccount from "@/views/home/admin/mail-accounts/EditMailAccount.vue"
import OverviewMailAccount from "@/views/home/admin/mail-accounts/OverviewMailAccount.vue"
import Permissions from "@/views/home/admin/Permissions.vue"
import Payments from "@/views/home/admin/Payments.vue"
import Packages from "@/views/home/admin/packages/Packages.vue"
import AppSettings from "@/views/home/admin/AppSettings.vue"
import Messages from "@/views/home/Messages.vue"

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in"
  },
  {
    path: "/sign-in",
    name: "Login",
    component: SignIn
  },
  {
    path: "/reset-password",
    name: "Resetowanie hasła",
    component: ResetPassword
  },
  {
    path: "/home/dashboard",
    name: "Kokpit",
    component: Dashboard
  },
  {
    path: "/home/mailbox",
    name: "Poczta",
    component: Mailbox
  },
  {
    path: "/home/new-mail",
    name: "Nowa wiadomość",
    component: NewMail
  },
  {
    path: "/home/customers",
    name: "Kontrahenci",
    component: Customers
  },
  {
    path: "/home/sale",
    name: "Sprzedaż",
    component: Sale
  },
  {
    path: "/home/offers",
    name: "Oferty",
    component: Offers
  },
  {
    path: "/home/new-offer",
    name: "Nowa oferta",
    component: NewOffer
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/edit-offer/:id",
    name: "Edycja oferty",
    component: EditOffer
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/overview-offer/:id",
    name: "Podgląd oferty",
    component: OverviewOffer
  },
  {
    path: "/home/templates",
    name: "Szablony ofert",
    component: Templates
  },
  {
    path: "/home/new-template",
    name: "Nowy szablon",
    component: NewTemplate
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/edit-template/:id",
    name: "Edycja szablonu",
    component: EditTemplate
  },
  {
    path: "/home/print-templates",
    name: "Szablony wydruku",
    component: PrintTemplates
  },
  {
    path: "/home/new-print-template",
    name: "Nowy szablon wydruku",
    component: NewPrintTemplate
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/edit-print-template/:id",
    name: "Edycja szablonu wydruku",
    component: EditPrintTemplate
  },
  {
    path: "/home/settings",
    name: "Ustawienia",
    component: Categories
  },
  {
    path: "/home/sale/assortment",
    name: "Asortyment",
    component: Assortment
  },
  {
    path: "/home/calendar",
    name: "Kalendarz",
    component: Calendar
  },
  {
    path: "/home/tasks",
    name: "Zadania",
    component: Tasks
  },
  {
    path: "/home/reports",
    name: "Raporty",
    component: Reports
  },
  {
    path: "/home/periodic-reports",
    name: "Raporty cykliczne",
    component: PeriodicReports
  },
  {
    path: "/home/asom/inspections",
    name: "Przeglądy",
    component: Inspections
  },
  {
    path: "/home/asom/worksheets",
    name: "Karty pracy",
    component: Worksheets
  },
  {
    path: "/home/profile/overview",
    name: "Zarządzanie kontem",
    component: Overview
  },
  {
    path: "/home/profile/settings",
    name: "Personalizacja",
    component: Settings
  },
  {
    path: "/home/admin/users",
    name: "Użytkownicy",
    component: Users
  },
  {
    path: "/home/admin/new-user",
    name: "Nowy użytkownik",
    component: NewUser
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/edit-user/:id",
    name: "Edycja użytkownika",
    component: EditUser
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/overview-user/:id",
    name: "Podgląd użytkownika",
    component: OverviewUser
  },
  {
    path: "/home/admin/groups",
    name: "Grupy",
    component: Groups
  },
  {
    path: "/home/admin/new-group",
    name: "Nowa grupa",
    component: NewGroup
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/edit-group/:id",
    name: "Edycja grupy",
    component: EditGroup
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/overview-group/:id",
    name: "Podgląd grupy",
    component: OverviewGroup
  },
  {
    path: "/home/admin/mail-accounts",
    name: "Konta pocztowe",
    component: MailAccounts
  },
  {
    path: "/home/admin/new-mail-account",
    name: "Nowe konto pocztowe",
    component: NewMailAccount
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/edit-mail-account/:id",
    name: "Edycja konta pocztowego",
    component: EditMailAccount
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/overview-mail-account/:id",
    name: "Podgląd konta pocztowego",
    component: OverviewMailAccount
  },
  {
    path: "/home/admin/permissions",
    name: "Uprawnienia",
    component: Permissions
  },
  {
    path: "/home/admin/payments",
    name: "Płatności",
    component: Payments
  },
  {
    path: "/home/admin/packages",
    name: "Pakiety",
    component: Packages
  },
  {
    path: "/home/admin/app-settings",
    name: "Ustawienia aplikacji",
    component: AppSettings
  },
  {
    path: "/home/messages",
    name: "Wiadomości",
    component: Messages
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
})

export default router