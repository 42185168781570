<template>
  <div v-show="me" class="container">
    <div class="row mb-5">
      <div class="col-lg-4 col-md-6 col-7 mx-auto text-center">
        <div class="nav-wrapper mt-5 position-relative z-index-2">
          <ul
            id="tabs-pricing"
            class="nav nav-pills nav-fill flex-row p-1"
            role="tablist"
          >
            <li class="nav-item">
              <a
                id="tabs-iconpricing-tab-1"
                class="nav-link mb-0 active"
                data-bs-toggle="tab"
                href="#monthly"
                role="tab"
                aria-controls="monthly"
                aria-selected="true"
                >Miesięczne</a
              >
            </li>
            <li class="nav-item">
              <a
                id="tabs-iconpricing-tab-2"
                class="nav-link mb-0"
                data-bs-toggle="tab"
                href="#annual"
                role="tab"
                aria-controls="annual"
                aria-selected="false"
                >Roczne</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tab-content tab-space">
      <div id="monthly" class="tab-pane active">
        <div class="row">
          <!-- Miesiąc Basic -->
          <pricing-card
            :badge="{ color: 'secondary', label: 'Basic' }"
            :price="{ currency: 'PLN', value: '59', charged: 'miesięcznie' }"
            :specifications="[
              { label: '5 użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: false },
              { label: 'Moduł raportów', includes: false },
              { label: 'Moduł ofertowania', includes: false },
            ]"
            :action="{
              color: 'light',
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
          />

          <!-- Miesiąc Premium -->
          <pricing-card
            :badge="{ color: color, label: 'Premium' }"
            :price="{ currency: 'PLN', value: '89', charged: 'miesięcznie' }"
            :specifications="[
              { label: 'Nielimitowana liczba użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: true },
              { label: 'Moduł raportów', includes: true },
              { label: 'Moduł ofertowania', includes: true },
            ]"
            :action="{
              color: color,
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
            dark-mode
          />

          <!-- Miesiąc Optimum -->
          <pricing-card
            :badge="{ color: 'secondary', label: 'Optimum' }"
            :price="{ currency: 'PLN', value: '69', charged: 'miesięcznie' }"
            :specifications="[
              { label: '10 użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: true },
              { label: 'Moduł raportów', includes: false },
              { label: 'Moduł ofertowania', includes: false },
            ]"
            :action="{
              color: 'light',
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
          />
        </div>
      </div>
      <div id="annual" class="tab-pane">
        <div class="row">
          <!-- Rok Basic -->
          <pricing-card
            :badge="{ color: 'secondary', label: 'Basic' }"
            :price="{ currency: 'PLN', value: '59', charged: 'rocznie' }"
            :specifications="[
              { label: '5 użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: false },
              { label: 'Moduł raportów', includes: false },
              { label: 'Moduł ofertowania', includes: false },
            ]"
            :action="{
              color: 'light',
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
          />

          <!-- Rok Premium -->
          <pricing-card
            :badge="{ color: color, label: 'premium' }"
            :price="{ currency: 'PLN', value: '89', charged: 'rocznie' }"
            :specifications="[
              { label: 'Nielimitowana liczba użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: true },
              { label: 'Moduł raportów', includes: true },
              { label: 'Moduł ofertowania', includes: true },
            ]"
            :action="{
              color: color,
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
            dark-mode
          />

          <!-- Rok Optimum -->
          <pricing-card
            :badge="{ color: 'secondary', label: 'Optimum' }"
            :price="{ currency: 'PLN', value: '69', charged: 'rocznie' }"
            :specifications="[
              { label: '10 użytkowników', includes: true },
              { label: 'Dokumentacja', includes: true },
              { label: 'Synchronizacja z pocztą', includes: true },
              { label: 'Aplikacja mobilna', includes: true },
              { label: 'Moduł raportów', includes: false },
              { label: 'Moduł ofertowania', includes: false },
            ]"
            :action="{
              color: 'light',
              route: 'javascript:;',
              label: 'Wyślij prośbę o zmianę',
            }"
          />
        </div>
      </div>
    </div>
  </div>
  
  <div v-if="me" class="container my-6">
    <!-- Q&A -->
    <div class="row">
      <div class="mx-auto col-md-10">
        <div id="accordionRental" class="accordion">
          <accordion-item
            accordion-id="headingOne"
            collapse-id="collapseOne"
            active
          >
            <template #question>Jak zmienić aktualny pakiet?</template>
            <template #answer>
              Zmiana abonamentu realizowana jest na kilka sposobów. Można to zrobić przez zgłoszenie się bezpośrednio do placówki. Można też wybrać jeden dostępnych 
              z wariantów i na tej podstawie skontaktować się z infolinią telefonicznie lub mailowo.
            </template>
          </accordion-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PricingCard from "./components/PricingCard.vue"
import AccordionItem from "./components/AccordionItem.vue"
import setNavPills from "@/assets/js/nav-pills.js"
import UserDataService from "@/services/UserDataService"
import { mapState } from "vuex"

export default {
  name: "Packages",
  components: {
    PricingCard,
    AccordionItem,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika
    }
  },
  computed: {
    ...mapState(["color"])
  },
  created() {
    this.getMe()
  },
  mounted() {
    setNavPills()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_PACKAGES")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
        }
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>