<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Wiadomość -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-1 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <h5 class="m-0">{{ mail.subject }}</h5>
                <i v-if="mail.flagged" class="material-icons ms-2" :class="`text-${ color }`">star</i>
              </div>

              <div class="d-flex align-items-center">
                <a
                  v-if="mail.attachments && mail.attachments.length !== 0"
                  class="d-flex align-items-center me-3 text-sm"
                  :class="[color]"
                  data-bs-toggle="dropdown"
                >
                  {{ mail.attachments.length }}
                  <i class="material-icons cursor-pointer text-md">attach_file</i>
                  <i class="material-icons cursor-pointer text-md">expand_more</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li v-for="item in mail.attachments" :key="item">
                    <a :href="'data:' + item.type + ';base64,' + item.blob" class="px-2 dropdown-item border-radius-md" :download="item.name">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                            <span>{{ item.name }}</span>
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>

                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </div>
            </div>
          </div>
          <div class="card-body pt-1 pb-3" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="d-flex mb-0">
              <div>
                <span class="text-sm"><b>Od: </b>{{ mail.from ? mail.from : mail.fromAddress.accountMail ? mail.fromAddress.accountMail : '<Brak>' }}</span><br>
                <p class="text-sm"><b>Do: </b><span v-for="(item, key) in mail.to" :key="item" class="me-2">{{ key }}</span><span v-if="mail.toAddress" class="me-2">{{ mail.toAddress }}</span></p>
              </div>

              <span class="text-xs font-weight-normal ms-auto opacity-7">
                {{ mail.date ? moment(mail.date).format("DD/MM/YYYY HH:mm") : '' }}
              </span>
            </div>

            <!-- eslint-disable-next-line -->
            <p v-if="mail.textHtml" class="mb-0" v-html="mail.textHtml"></p>
            <p v-else class="mb-0" style="white-space: pre">{{ mail.textPlain }}</p>

            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />

            <!-- Przyciski -->
            <material-button
              :color="isDarkMode ? 'light' : 'dark'"
              variant="outline"
              class="btn-sm mb-0"
              type="button"
              name="button"
              @click="composeMail(mailbox.id, mail.id, true)"
              >Odpowiedz</material-button
            >
            <material-button
              :color="isDarkMode ? 'light' : 'dark'"
              variant="outline"
              class="btn-sm mx-2 mb-0"
              type="button"
              name="button"
              @click="composeMail(mailbox.id, mail.id, true)"
              >Prześlij dalej</material-button
            >

            <!-- Ikony -->
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'event')">date_range</i>
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 mx-3 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'task')">checklist</i>
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'customer')">badge</i>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Udostępnianie folderu -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content" style="width: 500px !important">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Udostępnianie folderu</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <p class="d-flex align-items-center m-0 mb-3 text-sm">
              Udostępniany folder: 
              <span class="ms-2 font-weight-bold">
                {{ sharedMailbox.name === "INBOX" ? "Skrzynka odbiorcza" : sharedMailbox.name === "Drafts" ? "Wersje robocze" : sharedMailbox.name === "Trash" ? "Elementy usunięte" : sharedMailbox.name === "Sent" ? "Elementy wysłane" : sharedMailbox.name === "Spam" ? "Spam" : sharedMailbox.name }}
              </span>
            </p>

            <SimpleTypeahead
              ref="filter_users"
              :items="usersAC"
              :min-input-length="1"
              class="w-100 text-sm pb-2"
              style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              @select-item="selectUser"
            ></SimpleTypeahead>

            <div v-if="sharedMailbox.sharedUsers.length > 0 || sharedMailbox.createdSharedUsers.length > 0" class="row mt-3">
              <div class="col-12">
                <div class="d-flex align-items-center" style="flex-wrap: wrap">
                  <material-badge
                    v-for="item in sharedMailbox.sharedUsers"
                    :key="item"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center me-1 mb-1"
                  >
                    {{ item.member.fullName }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="users.push(item.member); usersAC.push(item.member.fullName); sharedMailbox.deletedSharedUsers.push(item); sharedMailbox.sharedUsers.splice(index, 1)">close</i>
                  </material-badge>

                  <material-badge
                    v-for="item in sharedMailbox.createdSharedUsers"
                    :key="item"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center me-1 mb-1"
                  >
                    {{ item.fullName }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="users.push(item); usersAC.push(item.fullName); sharedMailbox.createdSharedUsers.splice(index, 1)">close</i>
                  </material-badge>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchSharedMailbox(); closeModal()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content" style="width: 500px !important">
        <div class="card px-2 p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedMails.length === 1">Czy na pewno chcesz usunąć {{ checkedMails.length }} maila?</div>
            <div v-if="checkedMails.length > 1 && checkedMails.length < 5">Czy na pewno chcesz usunąć {{ checkedMails.length }} maile?</div>
            <div v-if="checkedMails.length > 4">Czy na pewno chcesz usunąć {{ checkedMails.length }} maili?</div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteMails(); unselectMails(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content" style="width: 500px !important">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć folder {{ deletedMailbox.name }}?

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteMailbox(deletedMailbox); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-sm-flex justify-content-end mb-4">
      <!-- Przycisk: Nowa wiadomość -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Nowa wiadomość')"
      >
        + &nbsp; Nowa wiadomość
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <!-- Filtry -->
        <div class="card mb-4">
          <div class="card-header pb-1">
            <h5>Filtry</h5>
          </div>
          <div class="card-body pt-1 pb-4">
            <SimpleTypeahead
              ref="filter_from"
              v-model="filter_from"
              :items="mailboxAC"
              :min-input-length="1"
              class="w-100 text-sm pb-2"
              style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
              :class="isDarkMode ? 'text-white' : 'text-dark'"
              @select-item="select-item"
            ></SimpleTypeahead>

            <material-button
              class="w-100 mt-3"
              color="light"
              variant="gradient"
              @click="resetData()"
            >
              Resetuj filtry
            </material-button>
          </div>
        </div>

        <!-- Foldery -->
        <div v-for="item in mailboxes" :key="item" class="card mb-4">
          <div class="card-header pt-3 pb-1 cursor-pointer" @click="item.expand = !item.expand">
            <div class="d-flex justify-content-between">
              <h6 class="my-auto">{{ item.name }}</h6>
              <i class="material-symbols-outlined text-md my-auto">
                {{ item.expand ? "expand_less" : "expand_more" }}
              </i>
            </div>
          </div>
          <div class="card-body pb-2" :class="item.expand ? 'pt-3' : 'pt-0'">
            <div v-if="item.expand">
              <div v-for="folder in item.folders" :key="folder" class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center" @click="shared = false; folders = item.folders; getMailbox(item.id, folder, item.name, true)">
                  <div class="text-center cursor-pointer">
                    <i class="material-symbols-outlined mb-3 font-weight-light">
                      {{ folder === "INBOX" ? "mail" : folder === "Drafts" ? "edit" : folder === "Trash" ? "delete" : folder === "Sent" ? "send" : folder === "Spam" ? "campaign" : "label_important" }}
                    </i>
                  </div>
                  
                  <p class="ms-2 cursor-pointer text-sm">
                    {{ folder === "INBOX" ? "Skrzynka odbiorcza" : folder === "Drafts" ? "Wersje robocze" : folder === "Trash" ? "Elementy usunięte" : folder === "Sent" ? "Elementy wysłane" : folder === "Spam" ? "Spam" : folder }}
                  </p>
                </div>

                <a
                  class="mb-2"
                  :class="[color]"
                  data-bs-toggle="dropdown"
                >
                  <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'"> more_vert </i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                  <li class="cursor-pointer" @click="getSharedMailbox(item.id, folder)">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                            Udostępnij
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li v-if="folder !== 'INBOX' && folder !== 'Drafts' && folder !== 'Trash' && folder !== 'Sent' && folder !== 'Spam'" class="cursor-pointer" @click="deletedMailbox = { id: item.id, name: folder }; openModal(3)">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                            Usuń
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="item.createdMailboxStatus" class="d-flex align-items-center">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.createdMailbox"
                    class="form-control mb-3"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>

                <span class="cursor-pointer" @click="createMailbox(item)">
                  <i class="material-icons text-lg ms-2" :class="isDarkMode ? 'text-light' : 'text-dark'">
                    add
                  </i>
                </span>

                <span class="cursor-pointer" @click="item.createdMailboxStatus = false">
                  <i class="material-icons text-lg ms-2" :class="isDarkMode ? 'text-light' : 'text-dark'">
                    close
                  </i>
                </span>
              </div>

              <div v-else class="d-flex align-items-center" @click="item.createdMailboxStatus = true">
                <div class="text-center cursor-pointer">
                  <i class="material-symbols-outlined mb-3 font-weight-light">add</i>
                </div>
                <p class="ms-2 cursor-pointer text-sm">Dodaj nowy folder</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Foldery współdzielone -->
        <div class="card mb-4">
          <div class="card-header pb-1">
            <h6>Foldery współdzielone</h6>
          </div>
          <div class="card-body pt-0 pb-3">
            <span v-if="sharedMailboxes.length === 0" class="text-sm">Brak udostępnionych folderów</span>
            <div v-for="(item, index) in sharedMailboxes" :key="item" class="d-flex align-items-center justify-content-between" :class="index !== sharedMailboxes.length - 1 ? 'mb-1' : ''">
              <div class="d-flex align-items-center" @click="shared = true; folders = []; getMailbox(item.owner.id, item.name, item.owner.accountMail, true)">
                <div class="text-center cursor-pointer">
                  <i class="material-symbols-outlined font-weight-light">
                    {{ item.name === "INBOX" ? "mail" : item.name === "Drafts" ? "edit" : item.name === "Trash" ? "delete" : item.name === "Sent" ? "send" : item.name === "Spam" ? "campaign" : "label_important" }}
                  </i>
                </div>
                
                <span class="ms-2 mb-2 text-sm cursor-pointer">
                  {{ item.name === "INBOX" ? "Skrzynka odbiorcza" : item.name === "Drafts" ? "Wersje robocze" : item.name === "Trash" ? "Elementy usunięte" : item.name === "Sent" ? "Elementy wysłane" : item.name === "Spam" ? "Spam" : item.name }}
                </span>
              </div>

              <a
                :class="[color]"
                data-bs-toggle="dropdown"
              >
                <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
              </a>
              <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                <li class="cursor-pointer" @click="deleteSharedMailbox(item.id)">
                  <a class="px-2 dropdown-item border-radius-md">
                    <div class="mx-0 px-0 py-1 d-flex">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                          Odłącz
                        </h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Wiadomości -->
      <div class="col-lg-9">
        <div class="card mb-4" style="max-height: 500px">
          <div class="card-header d-flex align-items-center pb-1">
            <h5>
              <span v-if="mailbox && mailbox.unread > 0">({{ mailbox.unread }})</span>
              {{ mailbox.name === "INBOX" ? "Skrzynka odbiorcza" : mailbox.name === "Drafts" ? "Wersje robocze" : mailbox.name === "Trash" ? "Elementy usunięte" : mailbox.name === "Sent" ? "Elementy wysłane" : mailbox.name === "Spam" ? "Spam" : mailbox.name }}
              <span v-if="mailbox && mailbox.account">({{ mailbox.account }})</span>
            </h5>

            <label class="d-flex my-auto ms-auto mt-1">
              <span class="text-sm my-auto me-2">PopUp</span>
              <material-switch id="switch" name="switch" :checked="me.settings.displayMail" @change="me.settings.displayMail = !me.settings.displayMail; patchSettings()" />
            </label>
          </div>
          <div class="table-responsive">
            <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
              <div class="dataTable-container">
                <table id="order-list" class="table table-flush dataTable-table">
                  <thead class="thead-light" :style="isDarkMode ? 'background-color: #202940' : 'background-color: #ffffff'">
                    <tr>
                      <!-- Kolumny -->
                      <td class="text-center" style="width: 7%">
                        <material-checkbox @change="selectMails($event)" />
                      </td>

                      <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%" @click="[column = mailbox.name === 'Sent' ? 'to' : 'from'] [columnOrder === 'asc' ? columnOrder = 'desc' : columnOrder = 'asc']">
                        <a href="#" class="dataTable-sorter">
                          {{ mailbox.name === "Sent" ? "Odbiorca" : "Nadawca" }}
                        </a>
                      </th>

                      <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 43%" @click="[column = 'subject'] [columnOrder === 'asc' ? columnOrder = 'desc' : columnOrder = 'asc']">
                        <a href="#" class="dataTable-sorter">
                          Temat
                        </a>
                      </th>

                      <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%" @click="[column = 'date'] [columnOrder === 'asc' ? columnOrder = 'desc' : columnOrder = 'asc']">
                        <a href="#" class="dataTable-sorter">
                          Data otrzymania
                        </a>
                      </th>

                      <th class="my-0 py-0" style="width: 10%">
                        <div v-if="checkedMails.length > 0 && !shared" class="d-flex justify-content-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-symbols-outlined h5 mt-2 cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'"> compare_arrows </i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                            <li v-for="item in folders" :key="item" class="cursor-pointer" :class="item === mailbox.name ? 'd-none' : ''" @click="onChangeFolders(item)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal text-capitalize" style="color: #344767 !important">
                                      {{ item === "INBOX" ? "Skrzynka odbiorcza" : item === "Drafts" ? "Wersje robocze" : item === "Trash" ? "Elementy usunięte" : item === "Sent" ? "Elementy wysłane" : item === "Spam" ? "Spam" : item }}
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>

                          <i
                            class="material-symbols-outlined h5 mt-2 cursor-pointer ms-3"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(2)"
                          >
                            delete
                          </i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="item in sorted"
                      :key="item"
                      :class="mail && mail.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null"
                      :style="!item.seen ? 'font-weight: bold' : null"
                    >
                      <!-- Tabela: Checkbox -->
                      <td class="text-center" style="width: 5%">
                        <material-checkbox class="mx-0 px-0" :checked="item.mark" @change="selectMail($event, item)" />
                      </td>

                      <!-- Tabela: Odbiorca -->
                      <td v-if="mailbox.name === 'Sent'" class="ps-0 text-xs cursor-pointer" @click="mailbox.name !== 'Drafts' ? getMail(mailbox.id, item.id, mailbox.name, item.flagged) : composeDraft(mailbox.id, item.id)">
                        <span>{{ item.to ? item.to.length >= 18 ? item.to.substring(0, 18) + '...' : item.to : item.toAddress ? item.toAddress.accountMail.length >= 18 ? item.to.substring(0, 18) + '...' : item.to : '<Brak>' }}</span>
                      </td>

                      <!-- Tabela: Nadawca -->
                      <td v-else class="ps-0 text-xs cursor-pointer" @click="mailbox.name !== 'Drafts' ? getMail(mailbox.id, item.id, mailbox.name, item.flagged) : composeDraft(mailbox.id, item.id)">
                        <span>{{ item.from ? item.from.length >= 18 ? item.from.substring(0, 18) + '...' : item.from : item.fromAddress ? item.fromAddress.accountMail.length >= 18 ? item.fromAddress.accountMail.substring(0, 18) + '...' : item.fromAddress.accountMail : '<Brak>' }}</span>
                      </td>

                      <!-- Tabela: Temat -->
                      <td class="ps-0 text-xs cursor-pointer" @click="mailbox.name !== 'Drafts' ? getMail(mailbox.id, item.id, mailbox.name, item.flagged) : composeDraft(mailbox.id, item.id)">
                        {{ item.subject ? item.subject : "Brak" }}
                      </td>

                      <!-- Tabela: Data otrzymania -->
                      <td class="ps-0 text-xs cursor-pointer" @click="mailbox.name !== 'Drafts' ? getMail(mailbox.id, item.id, mailbox.name, item.flagged) : composeDraft(mailbox.id, item.id)">
                        {{ item.date ? moment(item.date).format("DD/MM/YYYY HH:mm") : "Brak" }}
                      </td>

                      <!-- Tabela: Edycja -->
                      <td class="my-0 py-0 text-end" style="width: 5%">
                        <span class="my-2 text-xs">
                          <i v-if="item.flagged" class="material-icons me-2" :class="`text-${ color }`">
                            star
                          </i>

                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                            <li class="cursor-pointer" @click="onChangeSeen(mailbox.id, item)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      {{ item.seen ? "Oznacz jako nieprzeczytane" : "Oznacz jako przeczytane" }}
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="onChangeFlagged(mailbox.id, item)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      {{ item.flagged ? "Odflaguj" : "Oflaguj" }}
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li v-if="mailbox.name !== 'Drafts'" class="cursor-pointer" @click="composeMail(mailbox.id, item.id, true)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Odpowiedz
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li v-if="mailbox.name !== 'Drafts'" class="cursor-pointer" @click="composeMail(mailbox.id, item.id, false)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Prześlij dalej
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr v-if="!shared" class="horizontal my-1 bg-light">
                            <li v-if="!shared" class="cursor-pointer" @click="$event.stopPropagation()">
                              <a
                                class="px-2 dropdown-item border-radius-md"
                                :class="[color]"
                                data-bs-toggle="dropdown"
                              >
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Przenieś do...
                                    </h6>
                                  </div>
                                </div>
                              </a>
                              <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                                <li v-for="folder in folders" :key="folder" class="cursor-pointer" :class="folder === mailbox.name ? 'd-none' : ''" @click="onChangeFolder(mailbox.id, item.id, folder)">
                                  <a class="px-2 dropdown-item border-radius-md">
                                    <div class="mx-0 px-0 py-1 d-flex">
                                      <div class="d-flex flex-column justify-content-center">
                                        <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                          {{ folder === "INBOX" ? "Skrzynka odbiorcza" : folder === "Drafts" ? "Wersje robocze" : folder === "Trash" ? "Elementy usunięte" : folder === "Sent" ? "Elementy wysłane" : folder === "Spam" ? "Spam" : folder }}
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li v-if="mailbox.name === 'Trash' && !shared" class="cursor-pointer" @click="deleteMail(mailbox.id, item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="getEmlFile(item.id, mailbox.id, mailbox.name, 'customer')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowego kontrahenta
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getEmlFile(item.id, mailbox.id, mailbox.name, 'event')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowe wydarzenie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getEmlFile(item.id, mailbox.id, mailbox.name, 'task')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowe zadanie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getEmlFile(item.id, mailbox.id, mailbox.name, 'sale')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nową sprzedaż
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </span>
                      </td>
                    </tr>

                    <!-- Brak wiadomości -->
                    <tr :class="mailbox.mails.length > 0 ? 'd-none' : null">
                      <td class="dataTables-empty text-sm" colspan="5">Twoja poczta jest pusta</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Wiadomość -->
        <div v-if="mail && !me.settings.displayMail" class="card z-index-2">
          <div class="card-header pb-1">
            <h5 class="d-flex align-items-center">
              {{ mail.subject }}
              <i v-if="mail.flagged" class="material-icons ms-2 my-auto" :class="`text-${ color }`" style="margin-top: 2px">star</i>
              <span class="ms-auto d-flex text-sm align-items-center font-weight-normal my-auto">
                <a
                  v-if="mail.attachments && mail.attachments.length !== 0"
                  class="mx-2 d-flex align-items-center text-sm"
                  :class="[color]"
                  data-bs-toggle="dropdown"
                >
                  {{ mail.attachments.length }}
                  <i class="material-icons cursor-pointer text-md">attach_file</i>
                  <i class="material-icons cursor-pointer text-md">expand_more</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li v-for="item in mail.attachments" :key="item">
                    <a :href="'data:' + item.type + ';base64,' + item.blob" class="px-2 dropdown-item border-radius-md" :download="item.name">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                            <span>{{ item.name }}</span>
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="text-xs opacity-7 mt-1">{{ mail.date ? moment(mail.date).format("DD/MM/YYYY HH:mm") : '' }}</div>
              </span>
            </h5>
          </div>
          <div class="card-body pt-1">
            <div class="mb-3">
              <span class="text-sm"><b>Od: </b>{{ mail.from ? mail.from : mail.fromAddress.accountMail ? mail.fromAddress.accountMail : '<Brak>' }}</span><br>
              <p class="text-sm"><b>Do: </b><span v-for="(item, key) in mail.to" :key="item" class="me-2">{{ key }}</span><span v-if="mail.toAddress" class="me-2">{{ mail.toAddress }}</span></p>
            </div>

            <!-- eslint-disable-next-line -->
            <p v-if="mail.textHtml" class="mb-0" v-html="mail.textHtml"></p>
            <p v-else class="mb-0" style="white-space: pre">{{ mail.textPlain }}</p>

            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />

            <!-- Przyciski -->
            <material-button
              :color="isDarkMode ? 'light' : 'dark'"
              variant="outline"
              class="btn-sm mb-0"
              type="button"
              name="button"
              @click="composeMail(mailbox.id, mail.id, true)"
              >Odpowiedz</material-button
            >
            <material-button
              :color="isDarkMode ? 'light' : 'dark'"
              variant="outline"
              class="btn-sm mx-2 mb-0"
              type="button"
              name="button"
              @click="composeMail(mailbox.id, mail.id, false)"
              >Prześlij dalej</material-button
            >

            <!-- Ikony -->
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'event')">date_range</i>
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 mx-3 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'task')">checklist</i>
            <i class="material-symbols-outlined float-end h5 font-weight-normal mt-1 mb-0 cursor-pointer" @click="getEmlFile(mail.id, mailbox.id, mailbox.name, 'customer')">badge</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import SettingsDataService from "@/services/SettingsDataService"
import SharedMailboxDataService from "@/services/SharedMailboxDataService"
import { mapState } from "vuex"
import { orderBy } from "lodash"

export default {
  name: "Mailbox",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialSwitch,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      mailboxes: [], // Tablica zawierająca informację o wszystkich skrzynkach pocztowych
      sharedMailboxes: [], // Tablica udostępnionych folderów

      mailbox: {
        id: null,
        name: "",
        account: "",
        mails: [],
        unread: 0,
      }, // Obiekt przechowujący dane obecnie wybranej skrzynki pocztowej 
      mailboxAC: [], // Tablica zawierająca wszystkich nadawców maili z API (AutoComplete)
      mail: null, // Obiekt obecnie wybranego maila

      folders: [], // Tablica folderów dla wybranej skrzynki pocztowej

      sharedMailbox: null, // Zmienna przechowująca obiekt udostępnianego folderu
      deletedMailbox: null, // Zmienna przechowująca obiekt usuwanego folderu

      shared: false, // Zmienna sprawdzająca czy przeglądana skrzynka jest udostępniona

      users: [], // Tablica dostępnych użytkowników
      usersAC: [], // Tablica imion i nazwisk dostępnych użytkowników

      checkedMails: [], // Tablica zawierająca wszystkie zaznaczone maile
      modals: [], // Tablica odpowiedzialna za status modali
      
      column: "date", // Nazwa filtrowanej kolumny
      columnOrder: "desc", // Wartość filtrowania

      filter_from: "", // Filtr: Nadawca
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    sorted() {
      return orderBy(this.mailbox.mails, [(u) => u[this.column] ? u[this.column].toLowerCase() : null], [this.columnOrder])
    }
  },
  watch: {
    filter_from() {
      this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MAILBOX")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.me.mailAccounts.forEach(item => {
            this.mailboxes.push(
              {
                id: item.id,
                name: item.accountMail,
                folders: [],
                createdMailbox: "",
                createdMailboxStatus: false,
                expand: true,
              }
            )
          })

          this.getMailboxes()
          this.getSharedMailboxes()

          if (this.$store.state.openedMail) {
            this.getMail(this.$store.state.openedMail.account, this.$store.state.openedMail.id, "INBOX", this.$store.state.openedMail.flagged)
            this.$store.state.openedMail = null
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca foldery dla wybranej skrzynki pocztowej
    getMailboxes() {
      this.mailboxes.forEach((mailbox, index) => {
        MailAccountDataService.mailbox(mailbox.id, "/folders")
        .then(res => {
          mailbox.folders = []
          res.data["hydra:member"].forEach(folder => {
            mailbox.folders.push(folder)
          })

          mailbox.folders = mailbox.folders.reverse()

          if (!this.mailbox.id && index === 0) {
            this.getMailbox(mailbox.id, mailbox.folders[0], mailbox.name, true)
            this.folders = mailbox.folders
          }
        })
        .catch(error => {
          console.log(error)
        })
      })
    },

    // Funkcja pobierająca udostępnione foldery
    getSharedMailboxes() {
      SharedMailboxDataService.getAll(`?member.id=${ this.me.id }`)
      .then(res => {
        this.sharedMailboxes = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybraną skrzynkę pocztową
    getMailbox(id, folder, account, reload = false) {
      if (reload) {
        this.mailbox.id = id
        this.mailbox.name = folder
        this.mailbox.account = account
        this.mailbox.mails = []
        this.mail = null
      }

      MailAccountDataService.mailbox(id, `?folder=${ folder }&filter=${ this.filter_from }`)
      .then(res => {
        this.mailbox.mails = res.data["hydra:member"]

        this.mailboxAC = []
        this.mailbox.unread = 0

        this.mailbox.mails.forEach(item => {
          if (item.from) {
            let add = true

            this.mailboxAC.forEach(from => {
              if (item.from === from) {
                add = false
              }
            })

            if (add) {
              this.mailboxAC.push(item.from)
            }
          }

          if (item.seen === 0) {
            this.mailbox.unread += 1
          }
        })

        this.unselectMails()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybranego maila
    getMail(id, mid, folder, flagged) {
      if (!this.mail || (this.mail && this.mail.id !== mid)) {
        MailAccountDataService.mailbox(id, `?mid=${ mid }&folder=${ folder }${ this.isDarkMode ? '&isDarkMode=true' : ''}`)
        .then(res => {
          this.mail = res.data['hydra:member'][0]
          this.mail.flagged = flagged
          
          this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
          this.openModal(0)
        })
        .catch(error => {
          console.log(error)
        })
      }
      else {
        this.mail = null
      }
    },

    // Funkcja pobierająca maila udostępnionego folderu
    getSharedMailbox(id, folder) {
      SharedMailboxDataService.getAll(`?owner.id=${ id }&name=${ folder }`)
      .then(res => {
        this.sharedMailbox = {
          id: id,
          name: folder,
          sharedUsers: res.data["hydra:member"],
          createdSharedUsers: [],
          deletedSharedUsers: [],
        }

        this.getUsers()
        this.openModal(1)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca członków folderu
    patchSharedMailbox() {
      this.sharedMailbox.createdSharedUsers.forEach(item => {
        this.postSharedMailbox(item.id)
      })

      this.sharedMailbox.createdSharedUsers = []

      this.sharedMailbox.deletedSharedUsers.forEach(item => {
        this.deleteSharedMailbox(item.id)
      })

      this.sharedMailbox.deletedSharedUsers = []
    },

    // Funkcja pobierająca maila udostępnionego folderu
    postSharedMailbox(uid) {
      SharedMailboxDataService.post(
        {
          name: this.sharedMailbox.name,
          owner: `/mail_accounts/${ this.sharedMailbox.id }`,
          member: `/users/${ uid }`
        }
      )
      .then(res => {
        console.log(res.data)
        this.getSharedMailboxes()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja odpinająca użytkownika od udostępnionego folderu
    deleteSharedMailbox(id) {
      SharedMailboxDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getSharedMailboxes()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowy folder na serwerze
    createMailbox(item) {
      if (item.createdMailbox !== "") {
        MailAccountDataService.mailbox(item.id, `/folders?folder=${ item.createdMailbox }`)
        .then(res => {
          console.log(res.data)

          item.createdMailbox = ""
          item.createdMailboxStatus = false

          this.getMailboxes()
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja usuwająca wybrany folder z serwera
    deleteMailbox(item) {
      MailAccountDataService.mailbox(item.id, `/folders?folder=${ item.name }&deleted=true`)
      .then(res => {
        console.log(res.data)
        this.deletedMailbox = null
        this.getMailboxes()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca użytkowników nie należących do wybranego folderu
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.users = []
        this.usersAC = []

        res.data["hydra:member"].forEach(item => {
          let add = true

          if (item.id === this.me.id) {
            add = false
          }

          this.sharedMailbox.sharedUsers.forEach(element => {
            if (element.member.id === item.id) {
              add = false
            }
          })

          if (add) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca odczytanie maila
    onChangeSeen(id, item) {
      MailAccountDataService.mailbox(id, `/mail?folder=${ this.mailbox.name }&mid=${ item.id }&seen=${ !item.seen }`)
      .then(res => {
        console.log(res.data)
        this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca flagę maila
    onChangeFlagged(id, item) {
      MailAccountDataService.mailbox(id, `/mail?folder=${ this.mailbox.name }&mid=${ item.id }&flagged=${ !item.flagged }`)
      .then(res => {
        console.log(res.data)
        this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca folder maila
    onChangeFolder(mid, id, folder) {
      MailAccountDataService.mailbox(mid, `/mail?folder=${ this.mailbox.name }&mid=${ id }&moved=true&newFolder=${ folder }`)
      .then(res => {
        console.log(res.data)
        this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca maila
    deleteMail(id, item) {
      MailAccountDataService.mailbox(id, `/mail?folder=${ this.mailbox.name }&mid=${ item }&deleted=true`)
      .then(res => {
        console.log(res.data)
        if (this.mail && this.mail.id === item.id) {
          this.mail = null
        }

        this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca plik .eml
    getEmlFile(id, account, folder, type) {
      MailAccountDataService.mailbox(account, `/eml?folder=${ folder }&mailId=${ id }`)
      .then(res => {
        const blob = new Blob([res.data], { type: "message/rfc822" })
        const file = new File([blob], "email.eml", { type: "message/rfc822" })

        if (type === "customer") {
          this.$store.state.createdCustomer.operation = true
          this.$store.state.createdCustomer.attachments.push({
            file: file,
            description: "",
          })

          this.$redirect("Kontrahenci")
        }
        else if (type === "sale") {
          this.$store.state.createdSale.operation = true
          this.$store.state.createdSale.attachments.push({
            file: file,
            description: "",
          })

          this.$redirect("Sprzedaż")
        }
        else if (type === "event") {
          this.$store.state.createdEvent.operation = true
          this.$store.state.createdEvent.attachments.push(file)

          this.$redirect("Kalendarz")
        }
        else if (type === "task") {
          this.$store.state.createdTask.operation = true
          this.$store.state.createdTask.attachments.push({
            file: file,
            description: "",
          })

          this.$redirect("Zadania")
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca odpowiedź na maila
    composeMail(mid, id, reply) {
      MailAccountDataService.mailbox(mid, `?mid=${ id }&folder=${ this.mailbox.name }${ this.isDarkMode ? "&isDarkMode=true" : ""}`)
      .then(res => {
        let item = res.data["hydra:member"][0]

        let attachments = []
        item.attachments.forEach(attachment => {
          const binaryData = atob(attachment.blob)
          const arrayBuffer = new ArrayBuffer(binaryData.length)
          const uint8Array = new Uint8Array(arrayBuffer)
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i)
          }

          const file = new File([uint8Array], attachment.name, { type: attachment.type })
          attachments.push(file)
        })

        if (reply) {
          this.$store.state.mail = {
            id: null,
            from: mid,
            to: item.from,
            subject: `RE: ${ item.subject }`,
            text: "<br>___________________________________________________________________________________________________________<br>",
            attachments: attachments,
            accountId: mid,
          }

          this.$store.state.mail.text += item.textHtml ? item.textHtml : item.textPlain
        }
        else {
          this.$store.state.mail = {
            id: null,
            from: mid,
            to: "",
            subject: `FW: ${ item.subject }`,
            text: item.textHtml ? item.textHtml : item.textPlain,
            attachments: attachments,
            accountId: mid,
          }
        }

        this.$redirect("Nowa wiadomość")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca odpowiedź na maila
    composeDraft(mid, id) {
      MailAccountDataService.mailbox(mid, `?mid=${ id }&folder=${ this.mailbox.name }${ this.isDarkMode ? "&isDarkMode=true" : ""}`)
      .then(res => {
        let item = res.data["hydra:member"][0]

        let attachments = []
        item.attachments.forEach(attachment => {
          const binaryData = atob(attachment.blob)
          const arrayBuffer = new ArrayBuffer(binaryData.length)
          const uint8Array = new Uint8Array(arrayBuffer)
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i)
          }

          const file = new File([uint8Array], attachment.name, { type: attachment.type })
          attachments.push(file)
        })

        this.$store.state.mail = {
          id: id,
          from: mid ,
          to: Object.keys(res.data["hydra:member"][0].to),
          subject: item.subject,
          text: item.textHtml ? item.textHtml : item.textPlain,
          attachments: attachments,
          accountId: mid,
        }

        this.$redirect("Nowa wiadomość")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca zaznaczony mail do listy
    selectMail(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedMails.push(item)
      }
      else {
        this.checkedMails.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedMails.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie maile
    selectMails(e) {
      this.checkedMails = []
      if(e.target.checked) {
        this.mailbox.mails.forEach(item => {
          item.mark = true
          this.checkedMails.push(item)
        })
      }
      else {
        this.mailbox.mails.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie maile
    unselectMails() {
      this.checkedMails = []
      this.mailbox.mails.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja zmieniająca folder maili
    onChangeFolders(folder) {
      this.checkedMails.forEach(item => {
        this.onChangeFolder(this.mailbox.id, item.id, folder)
      })
    },

    // Funkcja usuwająca maila
    deleteMails() {
      this.checkedMails.forEach(item => {
        if (this.mailbox.name === "Trash") {
          this.deleteMail(this.mailbox.id, item.id)
        }
        else {
          this.onChangeFolder(this.mailbox.id, item.id, "Trash")
        }
      })
    },

    // Funkcja aktualizująca sposób wyświetlania wiadomości
    patchSettings() {
      SettingsDataService.patch(this.me.settings.id,
        {
          displayMail: this.me.settings.displayMail
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        if (this.mail) this.openModal(0)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybranegi nadawcę do zmiennej
    selectItem(item) {
      this.filter_from = item
      this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
    },

    // Funkcja przypisująca wybrane imię i nazwisko do zmiennej
    selectUser(user) {
      this.users.forEach((item, index) => {
        if (item.fullName === user) {
          this.sharedMailbox.createdSharedUsers.push(item)
          this.usersAC.splice(index, 1)
          this.users.splice(index, 1)
        }
      })

      this.$refs.filter_users.clearInput()
    },

    // Funkcja restujące dane w filtrze
    resetData() {
      this.$refs.filter_from.clearInput()
      this.filter_from = ""
      this.column = "date"
      this.getMailbox(this.mailbox.id, this.mailbox.name, this.mailbox.account)
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      if (x === 0) {
        if (this.me.settings.displayMail) {
          document.getElementById("modal").classList.add("d-block")
          this.modals[x] = true
        }
      }
      else {
        document.getElementById("modal").classList.add("d-block")
        this.modals[x] = true
      }
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      this.mail = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

@media (min-width: 850px) {
  .modal-content {
    width: 800px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.simple-typeahead {
  color: #000;
}

thead {
  position: sticky !important;
  top: 0;
  z-index: 10;
}
</style>