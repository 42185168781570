import http from "@/http-common"

class AsomDataService {
    getInspections(url) {
        return http.get(`przeglady${ url }`)
    }

    getWorksheets(url) {
        return http.get(`oferty${ url }`)
    }
}

export default new AsomDataService()