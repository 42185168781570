<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Potwierdzenie powiązanych produktów -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Wybierz ilość</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
            <p class="mb-2 text-sm">
              Wybierz ilość jaką chcesz przypisać do powiązanych produktów
            </p>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Produkt: <strong>{{ assortment.name }}</strong>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <input
                    v-model="assortment.quantity"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <label class="d-flex align-items-center">
              <material-checkbox class="mb-1 me-1" :checked="assortment.link" @change="assortment.link = !assortment.link" />
              Przypisać ilość do powiązanych produktów?
            </label>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="selectProductModal(assortment.name, assortment.quantity, assortment.link); closeModal()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Potwierdzenie dodania asortymentu -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Dodawanie produktu</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
            <p class="mb-2 text-sm">
              Czy chcesz dodać produkt na stałe do asortymentu?
            </p>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-1">
                  Typ
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="assortment.type"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="assortment.rbh = 1"
                >
                  <option value="service">Usługa</option>
                  <option value="product">Towar</option>
                  <option value="external">Usługa zewnętrzna</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  Nazwa
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="assortment.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  Symbol
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="assortment.symbol"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-1">
                  Kategoria
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="assortment.category"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in categories" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  Cena za jednostkę
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="assortment.sellingPriceNet"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="assortment.type === 'service'" class="row mb-2">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  RBH
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="assortment.rbh"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-1">
                  VAT
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="assortment.vat"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="8">8%</option>
                  <option value="23">23%</option>
                  <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postAssortment(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nowy kontrahent -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowy kontrahent</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Company'"
                    @change="createdCustomer.type = 'Company'"
                  />
                  <span class="text-sm mt-1">Przedsiębiorstwo</span>
                </label>
              </div>
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Person'"
                    @change="createdCustomer.type = 'Person'"
                  />
                  <span class="text-sm mt-1">Osoba fizyczna</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ createdCustomer.type === "Company" ? 'Nazwa wyświetlana' : 'Imię i nazwisko' }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="createdCustomer.displayName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center form-label ms-0">
                  Grupy <i class="material-icons text-warning text-sm ms-1">star</i>
                </label>
                <label v-for="item in createdCustomer.groups" :key="item" class="d-flex align-items-center mx-0">
                  <material-checkbox class="me-1" :checked="item.status" @change="item.status = !item.status" /> {{ item.name }}
                </label>
              </div>
            </div>

            <p v-if="createdCustomer.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ createdCustomer.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postNewCustomer()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Szablon wydruku -->
    <div class="modal-dialog d-none">
      <div class="modal-content">
        <div id="printable">
          <div v-for="(item, index) in pages" :key="item">
            <!-- eslint-disable-next-line -->
            <span v-html="item" style="color: black !important"></span>
            <div v-if="index !== pages.length - 1" class="html2pdf__page-break"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-sm-flex justify-content-between mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Oferty')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item mb-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <div v-if="offer.template">
              <li v-for="category in offer.template.categories" :key="category" class="nav-item mb-2">
                <a class="nav-link text-dark d-flex align-items-center" data-scroll :href="`#products${ category.id }`" :class="isDarkMode ? 'text-white' : 'text-dark'">
                  <i class="material-symbols-outlined text-lg me-2">category</i>
                  <span class="text-sm">Kategoria: {{ category.name }}</span>
                </a>
              </li>
            </div>
            <li class="nav-item mb-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#products" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">category</i>
                <span class="text-sm">Kategoria: Wszystkie</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#summary" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">summarize</i>
                <span class="text-sm">Podsumowanie</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa oferty
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="offer.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex align-items-center mb-1">Szablon</label>
                  <SimpleTypeahead
                    ref="template"
                    v-model="template"
                    :items="templates"
                    :min-input-length="0"
                    class="w-100 text-sm border-secondary"
                    style="padding-bottom: 10px; background: none; border: 0; border-bottom: 1px solid white; outline: none"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    @select-item="selectTemplate"
                  ></SimpleTypeahead>
                </div>

                <div class="d-flex mt-2">
                  <material-badge
                    v-if="offer.template"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center"
                  >
                    {{ offer.template.name }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="offer.template = null">close</i>
                  </material-badge>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex align-items-center mb-1">
                    Kontrahent
                    <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="getNewCustomer()">add_circle</i>
                  </label>
                  <SimpleTypeahead
                    ref="customer"
                    v-model="customer"
                    :items="customers"
                    :min-input-length="0"
                    class="w-100 text-sm border-secondary"
                    style="padding-bottom: 10px; background: none; border: 0; border-bottom: 1px solid white; outline: none"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    @select-item="selectCustomer"
                  ></SimpleTypeahead>
                </div>

                <div class="d-flex mt-2">
                  <material-badge
                    v-if="offer.customer"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center"
                  >
                    {{ offer.customer.displayName }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="offer.customer = null">close</i>
                  </material-badge>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex align-items-center mb-1">Szablon wydruku</label>
                  <SimpleTypeahead
                    ref="printTemplate"
                    v-model="printTemplate"
                    :items="printTemplates"
                    :min-input-length="0"
                    class="w-100 text-sm border-secondary"
                    style="padding-bottom: 10px; background: none; border: 0; border-bottom: 1px solid white; outline: none"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    @select-item="selectPrintTemplate"
                  ></SimpleTypeahead>
                </div>

                <div class="d-flex mt-2">
                  <material-badge
                    v-if="offer.printTemplate"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center"
                  >
                    {{ offer.printTemplate.name }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="offer.printTemplate = null">close</i>
                  </material-badge>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex align-items-center mb-1">Oferta do sprzedaży</label>
                  <SimpleTypeahead
                    ref="sale"
                    v-model="sale"
                    :items="sales"
                    :min-input-length="0"
                    class="w-100 text-sm border-secondary"
                    style="padding-bottom: 10px; background: none; border: 0; border-bottom: 1px solid white; outline: none"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    @select-item="selectSale"
                  ></SimpleTypeahead>
                </div>

                <div class="d-flex mt-2">
                  <material-badge
                    v-if="offer.sale"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center"
                  >
                    {{ offer.sale.name }}
                    <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="offer.sale = null">close</i>
                  </material-badge>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label>Notatka</label>
                  <input
                    v-model="offer.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0">
                  <material-checkbox :checked="popUp" @change="popUp = !popUp" />
                  <span class="ms-1 mt-1 text-sm">Automatycznie przypisuj powiązane produkty</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="offer.template">
          <div v-for="category in offer.template.categories" :id="`products${ category.id }`" :key="category" class="card mb-4">
            <div class="card-header pb-1">
              <h5>Kategoria: {{ category.name }}</h5>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive pt-1">
                <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                  <!-- Tabela -->
                  <div class="dataTable-container">
                    <table class="table table-flush dataTable-table mb-0">
                      <!-- Kolumny -->
                      <thead class="thead-light">
                        <tr>
                          <td class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">Typ</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 40%">Nazwa</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">Cena za jednostkę</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">RBH</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Ilość</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">VAT</td>
                          <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Wartość</td>
                          <td class="text-center" style="width: 5%"></td>
                        </tr>
                      </thead>

                      <!-- Wiersze -->
                      <tbody>
                        <tr v-for="(item, index) in offer.products" :key="index" :class="item.category && item.category === category.id ? '' : 'd-none'">
                          <!-- Typ -->
                          <td class="text-center">
                            <div v-if="item.product" class="text-xs" :class="isDarkMode ? 'text-white' : 'text-dark'">{{ item.type === "service" ? "US" : item.type === "product" ? "TW" : "UZ" }}</div>
                            <div v-else>
                              <select
                                v-model="item.type"
                                class="text-xs"
                                :class="isDarkMode ? 'text-white' : 'text-dark'"
                                @change="item.rbh = 1"
                              >
                                <option value="product">TW</option>
                                <option value="service">US</option>
                                <option value="external">UZ</option>
                              </select>
                            </div>
                          </td>

                          <!-- Nazwa -->
                          <td class="ps-0 py-0">
                            <SimpleTypeahead
                              v-model="item.name"
                              :placeholder="item.name ? item.name : ''"
                              :items="assortments"
                              :min-input-length="0"
                              class="w-100 text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                              style="background: none; border: 0; outline: none"
                              :value="item.name"
                              @select-item="selectProduct"
                              @on-input="editedItem = index; setListUp()"
                              @on-focus="getProducts(category.id); editedItem = index; setListUp()"
                            ></SimpleTypeahead>
                          </td>

                          <!-- Cena za jednostkę -->
                          <td class="ps-0">
                            <input
                              v-model="item.price"
                              type="number"
                              min="0"
                              class="form-control m-0 py-0 text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                            />
                          </td>

                          <!-- RBH -->
                          <td class="ps-0">
                            <p v-if="item.type === 'service'" class="m-0 p-0">
                              <input
                                v-model="item.rbh"
                                type="number"
                                min="0"
                                class="form-control m-0 py-0 text-xs"
                                :class="isDarkMode ? 'text-white' : 'text-dark'"
                              />
                            </p>
                            <p v-else class="m-0 p-0">-</p>
                          </td>

                          <!-- Ilość -->
                          <td class="ps-0">
                            <input
                              v-model="item.quantity"
                              type="number"
                              min="0"
                              class="form-control m-0 py-0 text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                            />
                          </td>

                          <!-- VAT -->
                          <td class="ps-0">
                            <select
                              v-model="item.vat"
                              class="text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                            >
                              <option value="0">0%</option>
                              <option value="5">5%</option>
                              <option value="8">8%</option>
                              <option value="23">23%</option>
                              <option v-for="vatRate in vatRates" :key="vatRate" :value="vatRate.value">{{ isNaN(parseInt(vatRate.value, 10)) ? vatRate.value : `${ vatRate.value }%` }}</option>
                            </select>
                          </td>

                          <!-- Wartość -->
                          <td class="ps-0">
                            <p class="m-0 text-xs">{{ (item.price * item.rbh * item.quantity).toFixed(2) }} PLN</p>
                          </td>

                          <!-- Operacje -->
                          <td class="text-end">
                            <div class="d-flex align-items-center justify-content-end">
                              <i v-if="!item.product" class="material-icons cursor-pointer me-2" :class="`text-${ color }`" @click="getNewAssortment(item, category.id); openModal(1)">add_circle</i>
                              <i class="material-icons text-danger cursor-pointer" @click="offer.products.splice(index, 1)">cancel</i>
                            </div>
                          </td>
                        </tr>

                        <!-- Nowy produkt -->
                        <tr>
                          <td class="dataTables-empty py-3 text-xs text-uppercase cursor-pointer" :class="`text-${ color }`" colspan="8" @click="getNewProduct(category)">
                            + &nbsp; Nowy produkt
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="products" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Kategoria: Wszystkie</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table mb-0">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">Typ</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 40%">Nazwa</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">Cena za jednostkę</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">RBH</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Ilość</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">VAT</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 10%">Wartość</td>
                        <td class="text-center" style="width: 5%"></td>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <tr v-for="(item, index) in offer.products" :key="item" :class="item.category ? 'd-none' : ''">
                        <!-- Typ -->
                        <td class="text-center">
                          <div v-if="item.product" class="text-xs" :class="isDarkMode ? 'text-white' : 'text-dark'">{{ item.type === "service" ? "US" : item.type === "product" ? "TW" : "UZ" }}</div>
                          <div v-else>
                            <select
                              v-model="item.type"
                              class="text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                              @change="item.rbh = 1"
                            >
                              <option value="product">TW</option>
                              <option value="service">US</option>
                              <option value="external">UZ</option>
                            </select>
                          </div>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0 py-0">
                          <SimpleTypeahead
                            v-model="item.name"
                            :placeholder="item.name ? item.name : ''"
                            :items="assortments"
                            :min-input-length="0"
                            class="w-100 text-xs"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            style="background: none; border: 0; outline: none"
                            :value="item.name"
                            @select-item="selectProduct"
                            @on-input="editedItem = index; setListUp()"
                            @on-focus="getProducts(); editedItem = index; setListUp()"
                          ></SimpleTypeahead>
                        </td>

                        <!-- Cena za jednostkę -->
                        <td class="ps-0">
                          <input
                            v-model="item.price"
                            type="number"
                            min="0"
                            class="form-control m-0 py-0 text-xs"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                          />
                        </td>

                        <!-- RBH -->
                        <td class="ps-0">
                          <p v-if="item.type === 'service'" class="m-0 p-0">
                            <input
                              v-model="item.rbh"
                              type="number"
                              min="0"
                              class="form-control m-0 py-0 text-xs"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                            />
                          </p>
                          <p v-else class="m-0 p-0">-</p>
                        </td>

                        <!-- Ilość -->
                        <td class="ps-0">
                          <input
                            v-model="item.quantity"
                            type="number"
                            min="0"
                            class="form-control m-0 py-0 text-xs"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                          />
                        </td>

                        <!-- VAT -->
                        <td class="ps-0">
                          <select
                            v-model="item.vat"
                            class="text-xs"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                          >
                            <option value="0">0%</option>
                            <option value="5">5%</option>
                            <option value="8">8%</option>
                            <option value="23">23%</option>
                            <option v-for="vatRate in vatRates" :key="vatRate" :value="vatRate.value">{{ isNaN(parseInt(vatRate.value, 10)) ? vatRate.value : `${ vatRate.value }%` }}</option>
                          </select>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ (item.price * item.rbh * item.quantity).toFixed(2) }} PLN</p>
                        </td>

                        <!-- Operacje -->
                        <td class="text-end">
                          <div class="d-flex align-items-center justify-content-end">
                            <i v-if="!item.product" class="material-icons cursor-pointer me-2" :class="`text-${ color }`" @click="getNewAssortment(item); openModal(1)">add_circle</i>
                            <i class="material-icons text-danger cursor-pointer" @click="offer.products.splice(index, 1)">cancel</i>
                          </div>
                        </td>
                      </tr>

                      <!-- Nowy produkt -->
                      <tr>
                        <td class="dataTables-empty py-3 text-xs text-uppercase cursor-pointer" :class="`text-${ color }`" colspan="8" @click="getNewProduct(null)">
                          + &nbsp; Nowy produkt
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="summary" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Podsumowanie</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="table-responsive pt-1 overflow-hidden">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 80%">Nazwa</td>
                        <td class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">Wartość</td>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość usług</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalServices.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość towarów</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalProducts.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <!-- Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">Wartość usług zewnętrznych</p>
                        </td>

                        <!-- Wartość -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ totalExternals.toFixed(2) }} PLN</p>
                        </td>
                      </tr>

                      <tr>
                        <td id="discount" class="ps-0" style="text-align: right">
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Netto:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na towary
                            <span id="discount" class="ms-1 d-flex align-items-center">
                              (

                              <input
                                v-model.number="offer.discountProducts"
                                type="number"
                                min="0"
                                max="100"
                                class="form-control m-0 mx-1 py-0 text-xs font-weight-bold"
                                :class="isDarkMode ? 'text-white' : 'text-secondary'"
                                style="text-align: center"
                                :style="`width: ${ (8 + (5 * offer.discountProducts.toString().length)) }px !important`"
                                @input="handleInput($event, 'discountProducts')"
                              />

                              %)
                            </span>:
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na usługi
                            <span class="ms-1 d-flex align-items-center">
                              (

                              <input
                                v-model.number="offer.discountServices"
                                type="number"
                                min="0"
                                max="100"
                                class="form-control m-0 mx-1 py-0 text-xs font-weight-bold"
                                :class="isDarkMode ? 'text-white' : 'text-secondary'"
                                style="text-align: center"
                                :style="`width: ${ (8 + (5 * offer.discountServices.toString().length)) }px !important`"
                                @input="handleInput($event, 'discountServices')"
                              />

                              %)
                            </span>:
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold d-flex justify-content-end">
                            Rabat na usługi zewnętrzne
                            <span id="discount" class="ms-1 d-flex align-items-center">
                              (

                              <input
                                v-model.number="offer.discountExternals"
                                type="number"
                                min="0"
                                max="100"
                                class="form-control m-0 mx-1 py-0 text-xs font-weight-bold"
                                :class="isDarkMode ? 'text-white' : 'text-secondary'"
                                style="text-align: center"
                                :style="`width: ${ (8 + (5 * offer.discountExternals.toString().length)) }px !important`"
                                @input="handleInput($event, 'discountExternals')"
                              />

                              %)
                            </span>:
                          </p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Netto:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">VAT:</p>
                          <p class="m-0 mb-2 text-xs text-uppercase font-weight-bold">Suma Brutto:</p>
                        </td>

                        <td class="ps-0">
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ total.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountProducts.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountServices.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ discountExternals.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ (total - (discountServices + discountProducts + discountExternals)).toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ vat.toFixed(2) }} PLN</p>
                          <p class="m-0 mb-2 text-xs text-uppercase">{{ (total - (discountServices + discountProducts + discountExternals) + vat).toFixed(2) }} PLN</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            v-if="offer.printTemplate"
            :color="color"
            variant="gradient"
            @click="validation(true)"
          >
            Generuj ofertę i wyślij
          </material-button>

          <material-button
            class="ms-3"
            :color="color"
            variant="gradient"
            @click="validation(false)"
          >
            Generuj ofertę
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import OfferDataService from "@/services/OfferDataService"
import ProductDataService from "@/services/ProductDataService"
import CategoryDataService from "@/services/CategoryDataService"
import TemplateDataService from "@/services/TemplateDataService"
import CustomerDataService from "@/services/CustomerDataService"
import PrintTemplateDataService from "@/services/PrintTemplateDataService"
import CustomerGroupDataService from "@/services/CustomerGroupDataService"
import VatRateDataService from "@/services/VatRateDataService"
import SaleDataService from "@/services/SaleDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"
import _ from "lodash"
import $ from "jquery"

export default {
  name: "NewOffer",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialRadio,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      offer: {
        name: "",
        template: null,
        customer: null,
        printTemplate: null,
        sale: null,
        discountServices: 0,
        discountProducts: 0,
        discountExternals: 0,
        note: "",
        createdAt: "",
        products: []
      }, // Obiekt tworzonej oferty

      assortments: [], // Tablica zawierająca wszystkie produkty

      templates: [], // Tablica zawierająca wszystkie szablony
      template: "", // Wartość szukanego szablonu

      customers: [], // Tablica zawierająca wszystkich kontrahentów
      customer: "", // Wartość szukanego kontrahenta

      printTemplates: [], // Tablica zawierająca wszystkie szablony wydruku
      printTemplate: "", // Wartość szukanego szablonu wydruku

      sales: [], // Tablica zawierająca wszystkie sprzedaże
      sale: "", // Wartość szukanej sprzedaży

      vatRates: [], // Tablica customowych stawek VAT
      defaultVAT: "", // Wartość domyślnej stawki VAT
      defaultRate: "", // Wartość domyślnej stawki godzinnej

      error: "", // Zmienna odpowiedzialna za błąd danych

      editedItem: null, // Zmienna zawierająca ID edytowanego itemu
      popUp: false, // Zmienna odpowiedzialna za okienko PopUp
      pages: [], // Tablica zawierająca strony pdfa
      modals: [], // Tablica dostępnych modali

      assortment: null, // Obiekt asortymentu
      categories: [], // Tablica wszystkich kategorii

      createdCustomer: null, // Obiekt tworzonego kontrahenta
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    totalProducts: function() {
      let result = 0

      this.offer.products.forEach(item => {
        result += item.type === "product" ? item.price * item.rbh * item.quantity : 0
      })

      return result
    },

    totalServices: function() {
      let result = 0

      this.offer.products.forEach(item => {
        result += item.type === "service" ? item.price * item.rbh * item.quantity : 0
      })

      return result
    },

    totalExternals: function() {
      let result = 0

      this.offer.products.forEach(item => {
        result += item.type === "external" ? item.price * item.rbh * item.quantity : 0
      })

      return result
    },

    discountProducts: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "product") {
          result += (item.price * item.rbh * item.quantity) * (this.offer.discountProducts / 100)
        }
      })

      return result
    },

    discountServices: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "service") {
          result += (item.price * item.rbh * item.quantity) * (this.offer.discountServices / 100)
        }
      })

      return result
    },

    discountExternals: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "external") {
          result += (item.price * item.rbh * item.quantity) * (this.offer.discountExternals / 100)
        }
      })

      return result
    },

    vat: function() {
      let result = 0

      this.offer.products.forEach(item => {
        if (item.type === "product") {
          if (this.offer.discountProducts > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (this.offer.discountProducts / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }

        if (item.type === "service") {
          if (this.offer.discountServices > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (this.offer.discountServices / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }

        if (item.type === "external") {
          if (this.offer.discountExternals > 0) {
            result += ((item.price * item.rbh * item.quantity) - ((item.price * item.rbh * item.quantity) * (this.offer.discountExternals / 100))) * this.countVAT(item.vat)
          }
          else {
            result += (item.price * item.rbh * item.quantity) * this.countVAT(item.vat)
          }
        }
      })

      return result
    },

    total: function() {
      let result = 0

      this.offer.products.forEach(item => {
        result += item.price * item.rbh * item.quantity
      })

      return result
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_OFFERS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getDefaultVat()
          this.getDefaultRate()
          this.getProducts()
          this.getTemplates()
          this.getCustomers()
          this.getPrintTemplates()
          this.getSales()
          this.getVatRates()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation(action) {
      let success = true
      this.error = ""

      if (this.offer.name === "") {
        success = false
        this.error = "Nazwa oferty nie może być pusta!"
      }

      if (this.offer.name.length > 65) {
        success = false
        this.error = "Nazwa oferty jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.postOffer(action)
      }
    },

    // Funkcja dodająca nową ofertę do API
    postOffer(action) {
      OfferDataService.post(this.offer)
      .then(res => {
        console.log(res.data)

        if (action) {
          this.replaceText(res.data.id)
        }
        else {
          this.$redirect("Oferty")
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie produkty
    getProducts(category = "") {
      let filter = category ? `?category.id=${ category }` : ""
      ProductDataService.getAll(filter)
      .then(res => {
        this.assortments = []
        res.data["hydra:member"].forEach(item => {
          this.assortments.push(item.name)
          if (item.symbol) {
            this.assortments.push(item.symbol)
          } 
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybrany produkt
    selectProduct(item) {
      ProductDataService.getAll(`?search=${ item }`)
      .then(res => {
        if (res.data["hydra:member"].length > 0) {
          if (res.data["hydra:member"][0].subproducts.length > 0) {
            if (this.popUp) {
              this.selectProductModal(item, 1, true)
            }
            else {
              let product = {
                name: item
              }

              this.getNewAssortment(product)
              this.openModal(0)
            }
          }
          else {
            this.offer.products[this.editedItem].product = res.data["hydra:member"][0].id
            this.offer.products[this.editedItem].name = res.data["hydra:member"][0].name
            this.offer.products[this.editedItem].category = null

            if (this.offer.template) {
              let exist = false

              this.offer.template.categories.forEach(item => {
                if (item.id === res.data["hydra:member"][0].category.id) {
                  exist = true
                }
              })

              this.offer.products[this.editedItem].category = exist ? res.data["hydra:member"][0].category.id : null
            }

            this.offer.products[this.editedItem].rbh = res.data["hydra:member"][0].rbh
            this.offer.products[this.editedItem].quantity = 1
            
            if (res.data["hydra:member"][0].type != "service") {
              this.offer.products[this.editedItem].price = res.data["hydra:member"][0].sellingPriceNet
            }
            else {
              if (this.offer.customer) {
                if (this.offer.customer.price > 0) {
                  this.offer.products[this.editedItem].price = this.offer.customer.discount ? (this.offer.customer.price - (this.offer.customer.price * (this.offer.customer.discount / 100))) : this.offer.customer.price
                }
                else {
                  this.offer.products[this.editedItem].price = this.defaultRate
                }
              }
              else {
                this.offer.products[this.editedItem].price = this.defaultRate
              }
            }

            this.offer.products[this.editedItem].vat = this.checkVAT(res.data["hydra:member"][0].vat)
            this.offer.products[this.editedItem].type = res.data["hydra:member"][0].type

            res.data["hydra:member"][0].subproducts.forEach(product => {
              let exist = false

              if (this.offer.template) {
                this.offer.template.categories.forEach(item => {
                  if (item.id === product.category.id) {
                    exist = true
                  }
                })
              }

              if (product.type == "service") {
                if (this.offer.customer) {
                  if (this.offer.customer.price > 0) {
                    product.sellingPriceNet = this.offer.customer.discount ? (this.offer.customer.price - (this.offer.customer.price * (this.offer.customer.discount / 100))) : this.offer.customer.price
                  }
                  else {
                    product.sellingPriceNet = this.defaultRate
                  }
                }
                else {
                  product.sellingPriceNet = this.defaultRate
                }
              }

              this.offer.products.push(
                {
                  product: product.id,
                  name: product.name,
                  category: exist ? product.category.id : null,
                  rbh: product.rbh,
                  quantity: 1,
                  price: product.sellingPriceNet,
                  vat: this.checkVAT(product.vat),
                  type: product.type
                }
              )
            })
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybrany produkt
    selectProductModal(item, quantity, type) {
      ProductDataService.getAll(`?search=${ item }`)
      .then(res => {
        if (res.data["hydra:member"].length > 0) {
          this.offer.products[this.editedItem].product = res.data["hydra:member"][0].id
          this.offer.products[this.editedItem].name = res.data["hydra:member"][0].name
          this.offer.products[this.editedItem].category = null

          if (this.offer.template) {
            let exist = false

            this.offer.template.categories.forEach(item => {
              if (item.id === res.data["hydra:member"][0].category.id) {
                exist = true
              }
            })

            this.offer.products[this.editedItem].category = exist ? res.data["hydra:member"][0].category.id : null
          }

          this.offer.products[this.editedItem].rbh = res.data["hydra:member"][0].rbh
          this.offer.products[this.editedItem].quantity = quantity ? quantity : 1

          if (res.data["hydra:member"][0].type != "service") {
            this.offer.products[this.editedItem].price = res.data["hydra:member"][0].sellingPriceNet
          }
          else {
            if (this.offer.customer) {
              if (this.offer.customer.price > 0) {
                this.offer.products[this.editedItem].price = this.offer.customer.discount ? (this.offer.customer.price - (this.offer.customer.price * (this.offer.customer.discount / 100))) : this.offer.customer.price
              }
              else {
                this.offer.products[this.editedItem].price = this.defaultRate
              }
            }
            else {
              this.offer.products[this.editedItem].price = this.defaultRate
            }
          }

          this.offer.products[this.editedItem].vat = this.checkVAT(res.data["hydra:member"][0].vat)
          this.offer.products[this.editedItem].type = res.data["hydra:member"][0].type

          res.data["hydra:member"][0].subproducts.forEach(product => {
            let exist = false

            if (this.offer.template) {
              this.offer.template.categories.forEach(item => {
                if (item.id === product.category.id) {
                  exist = true
                }
              })
            }

            if (product.type == "service") {
              if (this.offer.customer) {
                if (this.offer.customer.price > 0) {
                  product.sellingPriceNet = this.offer.customer.discount ? (this.offer.customer.price - (this.offer.customer.price * (this.offer.customer.discount / 100))) : this.offer.customer.price
                }
                else {
                  product.sellingPriceNet = this.defaultRate
                }
              }
              else {
                product.sellingPriceNet = this.defaultRate
              }
            }

            this.offer.products.push(
              {
                product: product.id,
                name: product.name,
                category: exist ? product.category.id : null,
                rbh: product.rbh,
                quantity: type ? quantity ? quantity : 1 : 1,
                price: product.sellingPriceNet,
                vat: this.checkVAT(product.vat),
                type: product.type,
              }
            )
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca pusty produkt do tablicy
    getNewProduct(category) {
      this.offer.products.push(
        {
          product: null,
          offer: null,
          name: "",
          category: category ? category.id : null,
          rbh: 1,
          quantity: 1,
          price: 0,
          vat: category ? category.vat : this.defaultVAT,
          type: "product"
        }
      )
    },

    // Funkcja tworząca nowy asortyment
    getNewAssortment(item, category = "") {
      this.assortment = null
      this.assortment = {
        type: item.type ? item.type : "service",
        name: item.name ? item.name : "",
        category: category ? category : "",
        symbol: "",
        measureUnit: "",
        rbh: item.rbh ? item.rbh : 1,
        purchasePriceNet: 0,
        sellingPriceNet: item.price ? item.price : 0,
        vat: item.vat ? item.vat : this.defaultVAT,
        producer: "",
        note: "",
        subproducts: [],
        quantity: 1,
        link: true,
      }

      this.getCategories()
    },

    // Funkcja tworząca nowy produkt w asortymencie
    postAssortment() {
      ProductDataService.post(this.assortment)
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie kategorie
    getCategories() {
      CategoryDataService.getAll("")
      .then(res => {
        this.categories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie szablony
    getTemplates() {
      TemplateDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.templates.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca do oferty wybrany szablon
    selectTemplate: _.debounce(function(name) {
      TemplateDataService.getAll(`?name=${ name }`)
      .then(res => {
        this.offer.products = []
        this.offer.template = null

        res.data["hydra:member"].forEach(item => {
          if (item.name === name) {
            this.offer.template = item
          }
        })

        this.$refs.template.clearInput()
      })
      .catch(error => {
        console.log(error)
      })
    }, 500),

    // Funkcja pobierająca wszystkich kontrahentów
    getCustomers() {
      CustomerDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.customers.push(item.displayName)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca do oferty wybranego kontrahenta
    selectCustomer: _.debounce(function(name) {
      CustomerDataService.getAll(`?displayName=${ name }`)
      .then(res => {
        this.offer.customer = null

        res.data["hydra:member"].forEach(item => {
          if (item.displayName === name) {
            this.offer.customer = item
          }
        })

        this.$refs.customer.clearInput()
      })
      .catch(error => {
        console.log(error)
      })
    }, 500),
    
    // Funkcja pobierająca wszystkie szablony wydruku
    getPrintTemplates() {
      PrintTemplateDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.printTemplates.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca do oferty wybrany szablon wydruku
    selectPrintTemplate: _.debounce(function(name) {
      PrintTemplateDataService.getAll(`?name=${ name }`)
      .then(res => {
        this.offer.printTemplate = null

        res.data["hydra:member"].forEach(item => {
          if (item.name === name) {
            this.offer.printTemplate = item
          }
        })

        this.$refs.printTemplate.clearInput()
      })
      .catch(error => {
        console.log(error)
      })
    }, 500),

    // Funkcja pobierająca wszystkie sprzedaże
    getSales() {
      SaleDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.sales.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca do oferty wybraną sprzedaż
    selectSale: _.debounce(function(name) {
      SaleDataService.getAll(`?name=${ name }`)
      .then(res => {
        this.offer.sale = null

        res.data["hydra:member"].forEach(item => {
          if (item.name === name) {
            this.offer.sale = item
          }
        })

        this.$refs.sale.clearInput()
      })
    }, 500),

    // Funkcja tworząca nowego kontrahenta
    getNewCustomer() {
      CustomerGroupDataService.getAll("")
      .then(res => {
        this.createdCustomer = {
          type: "Company",
          displayName: "",
          groups: [],
          error: "",
        }

        this.createdCustomer.groups = res.data["hydra:member"]
        this.createdCustomer.groups.forEach(item => {
          item.status = false
        })

        this.openModal(2)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca do API nowego kotnrahenta
    postNewCustomer() {
      this.createdCustomer.error = ""
      if (this.createdCustomer.displayName !== "") {
        if (this.createdCustomer.displayName.length <= 65) {
          if (this.createdCustomer.groups.some(group => group.status === true)) {
            CustomerDataService.post(this.createdCustomer)
            .then(res => {
              console.log(res.data)
              this.selectCustomer(this.createdCustomer.displayName)
              this.closeModal()
            })
            .catch(error => {
              console.log(error)

              if (JSON.stringify(error.response.status) == 400) {
                this.createdCustomer.error = error.response.data.error
              }
            })
          }
          else {
            this.createdCustomer.error = "Kontrahent musi należeć do min. 1 grupy"
          }
        }
        else {
          this.createdCustomer.error = "Nazwa kontrahenta jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.createdCustomer.error = "Podaj nazwę kontrahenta!"
      }
    },

    // Funkcja pobierająca wszystie customowe stawki VAT
    getVatRates() {
      VatRateDataService.getAll("")
      .then(res => {
        this.vatRates = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca domyślną stawkę VAT
    getDefaultVat() {
      AppSettingsDataService.getAll("?name=vat_rate")
      .then(res => {
        // this.defaultVAT = isNaN(parseInt(res.data["hydra:member"][0].value, 10)) ? "0" : res.data["hydra:member"][0].value
        this.defaultVAT = res.data["hydra:member"][0].value
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca domyślną stawkę roboczogodziny
    getDefaultRate() {
      AppSettingsDataService.getAll("?name=mh_rate")
      .then(res => {
        this.defaultRate = res.data["hydra:member"][0].value
      })
      .catch(error => {
        console.log(error)
      })
    },

     // Funkcja pilnująca wartości wpisanej w rabacie
     handleInput(event, name) {
      let value = event.target.value

      if (value < 0) {
        value = 0
      } else if (value > 100) {
        value = 100
      }

      this.offer[name] = value
    },

    // Funkcja obliczająca VAT dla produktu
    countVAT(value) {
      const parsedVAT = parseInt(value, 10)
      return isNaN(parsedVAT) ? 0 : (parsedVAT / 100)
    },

    // Funkcja sprawdzająca czy VAT istnieje
    checkVAT(value) {
      let vatExist = false

      if (value == 0 || value == 5 || value == 8 || value == 23) {
        vatExist = true
      }

      this.vatRates.forEach(item => {
        if (value == item.value) {
          vatExist = true
        }
      })

      if (vatExist) {
        return value
      }
      else {
        return this.defaultVAT
      }
    },

    // Funkcja podmieniająca zmienne w szablonie wydruku na odpowiedni tekst
    replaceText(id) {
      OfferDataService.replace(id)
      .then(res => {
        this.pages = res.data["hydra:member"]

        setTimeout(function() {
          $("img").addClass("w-100")
        }, 10)

        this.offerAsAttachment()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wysyłająca szablon wydruku oferty
    offerAsAttachment() {
      const element = document.getElementById("printable")
      const parameters = {
        margin: 30,
        filename: this.moment().format("YYYY.MM.DD") + [this.offer.customer ? "_" + this.offer.customer.displayName : ""] + " - " + this.offer.name + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).toPdf().get("pdf").then(pdf => {
        const blob = new Blob([pdf.output("blob")], { type: "application/pdf" })
        const file = new File([blob], parameters.filename, { type: "application/pdf" })
        this.$store.state.mail.attachments.push(file)
        this.$redirect("Nowa wiadomość")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja otwierająca modala
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca modala
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja ustawiająca listą na górze warstw
    setListUp() {
      setTimeout(function() {
        $(".simple-typeahead").css({ "position": "static" })
        $(".simple-typeahead-list").css({ "max-width": "400px", "overflow": "hidden" })
      }, 10)
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

#modal select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

#modal select {
  width: 100%;
}

#modal select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}

select {
  background: none;
  border: none;
  outline: none;
}

select option {
  color: black !important;
}

#discount input[type="number"]::-webkit-outer-spin-button,
#discount input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>