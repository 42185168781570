import http from "@/http-common"

class MailAccountDataService {
    getAll(url) {
        return http.get(`mail_accounts${ url }`)
    }

    get(id) {
        return http.get(`mail_accounts/${ id }`)
    }

    mailbox(id, url) {
        return http.get(`mailbox/${ id }${ url }`)
    }

    post(data) {
        return http.post('mail_accounts', data)
    }

    patch(id, data, headers) {
        return http.patch(`mail_accounts/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`mail_accounts/${ id }`)
    }
}

export default new MailAccountDataService()