import http from "@/http-common"

class SaleDataService {
    getAll(url) {
        return http.get(`sales${ url }`)
    }

    get(id) {
        return http.get(`sales/${ id }`)
    }

    post(data) {
        return http.post('sales', data)
    }

    patch(id, data, headers) {
        return http.patch(`sales/${ id }`, data, headers)
    }

    stage(id, data, headers) {
        return http.patch(`sales/stage/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`sale/delete/${ id }`)
    }
}

export default new SaleDataService()