import http from "@/http-common"

class MailDataService {
    getAll(url) {
        return http.get(`mails${ url }`)
    }

    get(id) {
        return http.get(`mails/${ id }`)
    }
    
    post(data) {
        return http.post('mails', data)
    }

    patch(id, data, headers) {
        return http.patch(`mails/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`mails/${ id }`)
    }
}

export default new MailDataService()