<template>
  <div v-if="me && template" class="container-fluid">
    <div class="d-flex justify-content-start mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Szablony ofert')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-md-8 mb-4">
        <div ref="default" class="card">
          <div class="card-header pb-1">
            <h5>Schemat blokowy</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <div class="col-12">
                <div class="card card-body card-plain border border-radius-lg py-3 text-sm font-weight-bold" :class="isDarkMode ? 'bg-dark border-dark' : 'bg-light border-light'">
                  Informacje ogólne
                </div>
              </div>
            </div>

            <div v-for="item in sortedCategories" :key="item" class="row mb-3">
              <div class="col-12">
                <div class="card card-body card-plain border border-radius-lg py-3 text-sm font-weight-bold" :class="isDarkMode ? 'border-dark' : 'border-light'">
                  Kategoria: {{ item.name }}
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="card card-body card-plain border border-radius-lg py-3 text-sm font-weight-bold" :class="isDarkMode ? 'bg-dark border-dark' : 'bg-light border-light'">
                  Kategoria: Wszystkie
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card card-body card-plain border border-radius-lg py-3 text-sm font-weight-bold" :class="isDarkMode ? 'bg-dark border-dark' : 'bg-light border-light'">
                  Podsumowanie
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-1">
            <div class="row mb-2">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa szablonu
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="template.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <h5 class="my-3">Kategorie</h5>

            <p
              v-for="(item, index) in categories"
              :key="item"
              class="d-flex align-items-center text-sm cursor-pointer"
              :class="index !== categories.length - 1 ? 'mb-2' : ' mb-0'"
              @click="item.status = !item.status; moveCategory(item)"
            >
              <i class="material-icons text-lg me-1">{{ !item.status ? 'add' : 'remove' }}</i> {{ item.name }}
            </p>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validation()"
              >
                Zapisz
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import TemplateDataService from "@/services/TemplateDataService"
import CategoryDataService from "@/services/CategoryDataService"
import { mapState } from "vuex"

export default {
  name: "EditTemplate",
  components: {
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      template: null, // Obiekt edytowanego szablonu
      categories: [], // Tablica zawierająca wszystkie dostępne kategorie

      error: "", // Zmienna odpowiedzialna za błąd danych

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    sortedCategories() {
      return [...this.template.categories].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_TEMPLATES")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getTemplate()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybrany szablon
    getTemplate() {
      TemplateDataService.get(this.$route.params.id)
      .then(res => {
        this.template = res.data
        this.getCategories()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      if (this.template.name === "") {
        success = false
        this.error = "Nazwa szablonu nie może być pusta"
      }

      if (this.template.name.length > 65) {
        success = false
        this.error = "Nazwa szablonu jest za długa (max. 65 znaków)"
      }

      if (success) {
        this.patchTemplate()
      }
    },

    // Funkcja dodająca nowy szablon do API
    patchTemplate() {
      let categories = []
      this.template.categories.forEach(item => {
        categories.push(`/categories/${ item.id }`)
      })

      TemplateDataService.patch(this.template.id,
        {
          name: this.template.name,
          categories: categories
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.snackbar = "success"
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if(JSON.stringify(error.response.status) == 422) {
          this.error = "Nazwa szablonu jest już zajęta!"
        }
      })
    },

    // Funkcja pobierająca wszystkie kategorie
    getCategories() {
      CategoryDataService.getAll("?order[name]=asc")
      .then(res => {
        this.categories = res.data["hydra:member"]
        this.categories.forEach(item => {
          item.status = false
          this.template.categories.forEach(category => {
            if (item.id === category.id) {
              item.status = true
            }
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca / usuwająca kategorię z szablonu
    moveCategory(item) {
      let add = true

      this.template.categories.forEach((category, index) => {
        if (category.id === item.id) {
          add = false
          this.template.categories.splice(index, 1)
        }
      })

      if (add) {
        this.template.categories.push(item)
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>