<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Podpis -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-2" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Podpis</h5>

              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>

            <p class="mb-0 text-sm">
              Podpis będzie dołączany do każdej wysłanej przez Ciebie wiadomości.
            </p>
          </div>
          <div class="card-body pt-2 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="d-flex align-items-center mb-3">
              <span class="me-3">Status stopki:</span>
              <material-switch
                class="my-auto"
                :checked="me.mailFooterStatus"
                @change="me.mailFooterStatus = !me.mailFooterStatus"
              />
            </div>

            <div class="input-group input-group-outline">
              <textarea
                v-model="me.mailFooter"
                class="form-control"
                :class="isDarkMode ? 'text-white' : 'text-black'"
                rows="5"
              ></textarea>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchFooter()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-sm-flex justify-content-between mb-4">
      <!-- Przycisk: Zamknij i usuń -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$store.state.mail.id ? deleteDraft() : resetMail()"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Zamknij i usuń
      </material-button>

      <!-- Przycisk: Podpis -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        @click="openModal(0)"
      >
        <i class="material-icons me-2">edit</i> Podpis
      </material-button>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Nowa wiadomość</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Od:</label>
                <select
                  v-model="$store.state.mail.from"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in me.mailAccounts" :key="item" :value="item.id">{{ item.accountMail }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm font-weight-bold">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Do:
                  </label>
                  <input
                    v-model="$store.state.mail.to"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm font-weight-bold">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Temat:
                  </label>
                  <input
                    v-model="$store.state.mail.subject"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <span class="font-weight-bold">Wiadomość:</span>
                <!-- eslint-disable -->
                <div
                  id="text"
                  class="mt-2"
                  :style="isDarkMode ? 'border-color: #797f8c' : 'border-color: #d2d6da'"
                  contenteditable="true"
                  v-html="$store.state.mail.text"
                ></div>
              </div>
            </div>

            <div v-if="$store.state.mail.attachments.length > 0" class="d-flex align-items-center mb-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in $store.state.mail.attachments"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.name }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="$store.state.mail.attachments.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <p v-if="error !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ error }}</p>

            <div class="d-flex align-items-center justify-content-between">
              <label class="d-flex my-auto cursor-pointer">
                <i class="material-icons my-auto">attach_file</i>
                <span class="my-auto ms-2 text-sm">Załącz pliki</span>
                <input type="file" class="d-none" @change="createAttachment($event)">
              </label>

              <div class="d-flex">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="draft = false; validation()"
                >
                  Wyślij
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="draft = true; validation()"
                >
                  Zapisz w roboczych
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import MailDataService from "@/services/MailDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import { mapState } from "vuex"
import $ from "jquery"

export default {
  name: "NewMail",
  components: {
    MaterialBadge,
    MaterialSwitch,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      draft: false, // Sprawdza czy wiadomość to draft
      modals: [], // Tablica odpowiedzialna za status modali

      error: "", // Zmienna odpowiedzialna za błąd danych
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane obecnie zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MAILBOX")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          if (this.me.mailFooterStatus && this.me.mailFooter) {
            this.$store.state.mail.text += `\n${ this.me.mailFooter }`
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      if (!this.draft && this.$store.state.mail.from === "") {
        success = false
        this.error = "Wybierz konto, z którego chcesz wysłać wiadomość"
      }

      if (!this.draft && this.$store.state.mail.to === "") {
        success = false
        this.error = "Podaj adres e-mail odbiorcy"
      }

      if (!this.draft && this.$store.state.mail.subject === "") {
        success = false
        this.error = "Podaj temat wiadomości"
      }

      this.$store.state.mail.text = $("#text").html()

      if (success) {
        this.postMail()
      }
    },

    // Funkcja tworząca nową wiadomość
    postMail() {
      const formData = new FormData()
      formData.append("subject", this.$store.state.mail.subject)
      formData.append("textPlain", this.$store.state.mail.text)
      formData.append("fromAddress", this.$store.state.mail.from)
      formData.append("toAddress", this.$store.state.mail.to)
      formData.append("drafted", this.draft)
      this.$store.state.mail.attachments.forEach(item => {
        formData.append("files[]", item)
      })

      MailDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.resetMail()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca załącznik do listy
    createAttachment(e) {
      this.$store.state.mail.attachments.push(e.target.files[0])
    },

    // Funkcja aktualizująca stopke użytkownika
    patchFooter() {
      UserDataService.patch(this.me.id,
        {
          id: this.me.id,
          mailFooter: this.me.mailFooter,
          mailFooterStatus: this.me.mailFooterStatus,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.closeModal()
        this.getMe()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrany draft
    deleteDraft() {
      MailAccountDataService.mailbox(this.$store.state.mail.accountId, `/mail?folder=Drafts&mid=${ this.$store.state.mail.id }&moved=true&newFolder=Trash`)
      .then(res => {
        console.log(res.data)
        this.resetMail()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca maila
    resetMail() {
      this.$store.state.mail.id = null
      this.$store.state.mail.from = ""
      this.$store.state.mail.to = ""
      this.$store.state.mail.subject = ""
      this.$store.state.mail.text = ""
      this.$store.state.mail.attachments = []
      this.$store.state.mail.accountId = null
      this.$redirect("Poczta")
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

#text {
  min-height: 400px;
  padding: 8px 10px;
  border: 2px solid;
  border-radius: 5px;
  outline: none;
}

#text[placeholder]:empty:before {
  content: attr(placeholder);
  color: white;
  opacity: 0.5;
}
</style>