<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowa grupa -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowa grupa</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <p v-if="group.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ group.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postCustomerGroup()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja grupy -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja grupy</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <p v-if="group.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ group.error }}</p>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <span class="text-danger my-auto text-sm cursor-pointer" @click="deleteCustomerGroup()">Usuń grupę</span>
              
              <div class="d-flex align-items-center">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="patchCustomerGroup()"
                >
                  Zapisz
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedCustomers.length === 1">Czy na pewno chcesz usunąć {{ checkedCustomers.length }} kontrahenta?</div>
            <div v-if="checkedCustomers.length > 1">Czy na pewno chcesz usunąć {{ checkedCustomers.length }} kontrahentów?</div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteCustomers(); unselectCustomers(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Nie można usunąć -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nie można usunąć obiektu!</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Nie można usunąć obiektu, ponieważ posiada powiązane relacje!

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="closeModal()"
              >
                OK
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Import zakończył się sukcesem -->
    <div v-if="modals[4]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Import zakończył się sukcesem</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Pomyślnie zaimportowano kontrahentów!

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="closeModal()"
              >
                OK
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-between mb-4">
      <!-- Export / Import -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        + &nbsp; Excel
      </material-button>
      <ul class="dropdown-menu px-2 shadow">
        <!-- Export kontrahentów -->
        <li>
          <a class="dropdown-item border-radius-md d-flex align-items-center ps-2 cursor-pointer" @click="exportCustomers()">
            <i class="material-symbols-outlined me-2">file_download</i> Export
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <!-- Import kontrahentów -->
        <li>
          <label class="w-100 m-0 p-0 cursor-pointer">
            <a class="dropdown-item border-radius-md d-flex align-items-center ps-2">
              <i class="material-symbols-outlined me-2">file_upload</i> Import
              <input type="file" class="d-none" @change="importCustomers($event)">
            </a>
          </label>
        </li>
      </ul>

      <div class="d-flex align-items-center">
        <!-- Filtr: Stworzony przez -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
        Stworzony przez
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li v-for="item in users" :key="item">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeCreator(item.fullName)">
              {{ item.fullName }}
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_creators = []; getCustomers(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Grupa -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Grupa
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="getNewCustomerGroup()">
              Nowa grupa
            </a>
          </li>
          <li v-if="groups.length !== 0">
            <hr class="horizontal bg-light my-2">
          </li>
          <li v-for="item in groups" :key="item" class="d-flex align-items-center">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeGroup(item.name)">
              {{ item.name }}
            </a>
            <a class="dropdown-item border-radius-md cursor-pointer" style="width: 25%" @click="getCustomerGroup(item.id)">
              <i class="material-symbols-outlined text-lg d-flex align-items-center justify-content-center">edit</i>
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_groups = []; getCustomers(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Miejscowość -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Miejscowość
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li v-for="item in cities" :key="item">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeCity(item)"
            >{{ item }}</a>
          </li>
          <li v-if="cities.length !== 0">
            <hr class="horizontal bg-light my-2" />
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_cities = []; getCustomers(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Typ -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Typ
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeType('Person')"
            >Osoba fizyczna</a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeType('Company')"
            >Przedsiębiorstwo</a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2" />
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_types = []; getCustomers(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Przycisk: Nowy kontrahent -->
        <material-button
          :color="color"
          variant="gradient"
          @click="customer && customer.operation === 'new' ? customer = null : getNewCustomer('', 'Company')"
        >
          + &nbsp; Nowy kontrahent
        </material-button>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_creators"
        :key="item"
        color="danger"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCreator(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_groups"
        :key="item"
        color="warning"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeGroup(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_cities"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCity(item)">close</i>
      </material-badge>

      <material-badge
        v-for="item in filter_types"
        :key="item"
        color="info"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item === 'Person' ? 'Osoba fizyczna' : 'Przedsiębiorstwo' }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeType(item)">close</i>
      </material-badge>
    </div>

    <div class="row">
      <div class="mb-4" :class="customer ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Kontrahenci</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_displayName"
                      v-model="filter_displayName"
                      :items="customersAC"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      @select-item="selectItem"
                    >
                    </SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table id="order-list" class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%;">
                          <material-checkbox class="mx-auto" @change="selectCustomers($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('displayName')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%;">
                          <a href="#" class="dataTable-sorter" @click="onOrder('type')">Typ</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="checkedCustomers.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(2)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in customers"
                        :key="item"
                        :class="customer && customer.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null"
                      >
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="selectCustomer($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0 text-xs cursor-pointer" @click="customer && customer.id === item.id ? customer = null : getCustomer(item.id)">
                          <span
                            class="me-2"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                          >
                            {{ item.displayName }}
                          </span>
                          {{ item.company ? item.company.displayName : "" }}
                        </td>

                        <!-- Tabela: Typ -->
                        <td class="ps-0 text-xs cursor-pointer" @click="customer && customer.id === item.id ? customer = null : getCustomer(item.id)">
                          {{ item.type === "Company" ? "Przedsiębiorstwo" : "Osoba fizyczna" }}
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getCustomer(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getCustomer(item.id, 'edit')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="$store.state.createdEvent.operation = true; $store.state.createdEvent.attachments = []; $redirect('Kalendarz')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                     <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowe wydarzenie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$store.state.createdTask.operation = true; $store.state.createdTask.attachments = []; $redirect('Zadania')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowe zadanie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unselectCustomers(); item.mark = true; checkedCustomers.push(item); openModal(2)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak kontrahentów -->
                      <tr :class="customers.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="4">Nie znaleziono kontrahentów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="customers.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>
                  <nav class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowy kontrahent -->
      <div v-if="customer && customer.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="customer.type === 'Company'"
                    @click="customer.type === 'Person' ? onChangeCustomerType() : null"
                  />
                  <span class="text-sm mt-1">Przedsiębiorstwo</span>
                </label>
              </div>
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="customer.type === 'Person'"
                    @click="customer.type === 'Company' ? onChangeCustomerType() : null"
                  />
                  <span class="text-sm mt-1">Osoba fizyczna</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ customer.type === "Company" ? 'Nazwa wyświetlana' : 'Imię i nazwisko' }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="customer.displayName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div v-if="customer.type === 'Company'" class="input-group input-group-static">
                  <label>NIP</label>
                  <input
                    v-model="customer.nip"
                    v-mask="'##########'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>

                <div v-if="customer.type === 'Person'" class="input-group input-group-static">
                  <label>Stanowisko</label>
                  <input
                    v-model="customer.position"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="customer.type === 'Person'" class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Firma</label>
                <select
                  v-model="customer.company"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in companies" :key="item" :value="item.id">{{ item.displayName }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Stawka godzinowa</label>
                  <input
                    v-model="customer.price"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :disabled="!customer.blockedPrice"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center">
                  <material-checkbox
                    class="me-2"
                    :checked="customer.blockedPrice"
                    @change="customer.blockedPrice = !customer.blockedPrice, !customer.blockedPrice ? customer.price = defaultRate : customer.price = 0"
                  />
                  Zablokuj stawkę
                </label>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div style="width: 90%">
                <div class="input-group input-group-static">
                  <label>Rabat</label>
                  <input
                    v-model.number="customer.discount"
                    type="number"
                    max="100"
                    min="0"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    @input="handleInput"
                  />
                </div>
              </div>
              <div class="text-center mt-auto" style="width: 10%">%</div>
            </div>

            <h5 class="my-3">Dane adresowe</h5>

            <div v-for="(item, index) in customer.addresses" :key="item">
              <div class="text-end">
                <i class="material-icons cursor-pointer text-danger" @click="customer.addresses.splice(index, 1)">cancel</i>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Nazwa adresu</label>
                    <input
                      v-model="item.addressName"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Ulica</label>
                    <input
                      v-model="item.street"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Kod pocztowy</label>
                    <input
                      v-model="item.postCode"
                      v-mask="'##-###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Miasto</label>
                    <input
                      v-model="item.city"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <hr v-if="index !== customer.addresses.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.addresses.push({ addressName: '', street: '', postCode: '', city: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj dane adresowe</span>
                </label>
              </div>
            </div>

            <h5 class="my-3">Dane kontaktowe</h5>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                Numery telefonu
              </div>
            </div>

            <div v-for="(item, index) in customer.contacts" :key="item">
              <div v-if="item.type === 'Phone'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      v-mask="'+48 ### ### ###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
                
                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.contacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.contacts.push({ type: 'Phone', kind: '', value: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj numer telefonu</span>
                </label>
              </div>
            </div>

            <hr class="horizontal mt-2" :class="isDarkMode ? 'bg-dark' : 'bg-light'">

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                Adresy e-mail
              </div>
            </div>
            
            <div v-for="(item, index) in customer.contacts" :key="item">
              <div v-if="item.type === 'Email'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.contacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.contacts.push({ type: 'Email', kind: '', value: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj adres e-mail</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center form-label ms-0">
                  Grupy <i class="material-icons text-warning text-sm ms-1">star</i>
                </label>
                <label v-for="item in customer.groups" :key="item" class="d-flex align-items-center mx-0">
                  <material-checkbox class="me-1" :checked="item.status" @change="item.status = !item.status" /> {{ item.name }}
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="customer.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>

            <div v-for="(item, index) in customer.inputs" :key="item" class="mb-3">
              <div class="d-flex align-items-center mb-1">
                <div style="width: 25%">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-model="item.name"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>

                <div :style="item.type === 'date' ? 'width: 40%' : 'width: 60%'">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-if="item.type === 'text'"
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />

                    <input
                      v-if="item.type === 'date'"
                      v-model="item.value"
                      type="date"
                      class="w-100 py-2 text-sm"
                      :class="isDarkMode ? '' : 'text-dark'"
                      :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                    />
                  </div>
                </div>

                <div v-if="item.type === 'date'" style="width: 20%">
                  <select
                    v-model="item.calendar"
                    class="w-100 px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option v-for="calendar in calendars" :key="calendar" :value="calendar.id">{{ calendar.name }}</option>
                  </select>
                </div>

                <div class="d-flex align-items-center justify-content-end my-auto" style="width: 15%">
                  <i v-if="item.isRequired" class="material-icons text-warning">star</i>
                  <i class="material-icons text-danger ms-1 cursor-pointer" @click="customer.inputs.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div v-if="item.type == 'date'" class="d-flex align-items-center" style="flex-wrap: wrap">
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().format('yyyy-MM-DD')">D</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(1, 'days').format('yyyy-MM-DD')">J</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = findNextFriday()">KT</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(7, 'days').format('yyyy-MM-DD')">1T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(14, 'days').format('yyyy-MM-DD')">2T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center cursor-pointer" @click="item.value = moment().add(1, 'months').format('yyyy-MM-DD')">1M</material-badge>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.inputs.push({ name: '', value: '', calendar: '', type: 'text', isRequired: false })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole tekstowe</span>
                </label>
              </div>

              <div class="col-md-6">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.inputs.push({ name: '', value: '', calendar: '', type: 'date', isRequired: false })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole daty</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in customer.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="text-sm me-2">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="customer.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(customer.attachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostCustomer()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="customer = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja kontrahenta -->
      <div v-if="customer && customer.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="customer.type === 'Company'"
                    @click="customer.type === 'Person' ? onChangeCustomerType() : null"
                  />
                  <span class="text-sm mt-1">Przedsiębiorstwo</span>
                </label>
              </div>
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="customer.type === 'Person'"
                    @click="customer.type === 'Company' ? onChangeCustomerType() : null"
                  />
                  <span class="text-sm mt-1">Osoba fizyczna</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ customer.type === "Company" ? 'Nazwa wyświetlana' : 'Imię i nazwisko' }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="customer.displayName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div v-if="customer.type === 'Company'" class="input-group input-group-static">
                  <label>NIP</label>
                  <input
                    v-model="customer.nip"
                    v-mask="'##########'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>

                <div v-if="customer.type === 'Person'" class="input-group input-group-static">
                  <label>Stanowisko</label>
                  <input
                    v-model="customer.position"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div v-if="customer.type === 'Person'" class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Firma</label>
                <select
                  v-model="customer.company"
                  class="w-100 px-0 pt-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in companies" :key="item" :value="item.id">{{ item.displayName }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>Stawka godzinowa</label>
                  <input
                    v-model="customer.price"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :disabled="!customer.blockedPrice"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center">
                  <material-checkbox
                    class="me-2"
                    :checked="customer.blockedPrice"
                    @change="customer.blockedPrice = !customer.blockedPrice, !customer.blockedPrice ? customer.price = defaultRate : customer.price = 0"
                  />
                  Zablokuj stawkę
                </label>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div style="width: 90%">
                <div class="input-group input-group-static">
                  <label>Rabat</label>
                  <input
                    v-model.number="customer.discount"
                    type="number"
                    max="100"
                    min="0"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    @input="handleInput"
                  />
                </div>
              </div>
              <div class="text-center mt-auto" style="width: 10%">%</div>
            </div>

            <h5 class="my-3">Dane adresowe</h5>

            <div v-for="(item, index) in customer.addresses" :key="item">
              <div class="text-end">
                <i class="material-icons cursor-pointer text-danger" @click="customer.removedAddresses.push(item.id); customer.addresses.splice(index, 1)">cancel</i>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Nazwa adresu</label>
                    <input
                      v-model="item.addressName"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Ulica</label>
                    <input
                      v-model="item.street"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Kod pocztowy</label>
                    <input
                      v-model="item.postCode"
                      v-mask="'##-###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Miasto</label>
                    <input
                      v-model="item.city"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <hr v-if="customer.newAddresses.length !== 0" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
            </div>

            <div v-for="(item, index) in customer.newAddresses" :key="item">
              <div class="text-end">
                <i class="material-icons cursor-pointer text-danger" @click="customer.newAddresses.splice(index, 1)">cancel</i>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Nazwa adresu</label>
                    <input
                      v-model="item.addressName"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Ulica</label>
                    <input
                      v-model="item.street"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Kod pocztowy</label>
                    <input
                      v-model="item.postCode"
                      v-mask="'##-###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group input-group-static">
                    <label>Miasto</label>
                    <input
                      v-model="item.city"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />
                  </div>
                </div>
              </div>

              <hr v-if="index !== customer.newAddresses.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.newAddresses.push({ addressName: '', street: '', postCode: '', city: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj dane adresowe</span>
                </label>
              </div>
            </div>

            <h5 class="my-3">Dane kontaktowe</h5>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                Numery telefonu
              </div>
            </div>

            <div v-for="(item, index) in customer.contacts" :key="item">
              <div v-if="item.type === 'Phone'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      v-mask="'+48 ### ### ###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.removedContacts.push(item.id); customer.contacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in customer.newContacts" :key="item">
              <div v-if="item.type === 'Phone'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      v-mask="'+48 ### ### ###'"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.newContacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.newContacts.push({ type: 'Phone', kind: '', value: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj numer telefonu</span>
                </label>
              </div>
            </div>

            <hr class="horizontal mt-3" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
            
            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                Adresy e-mail
              </div>
            </div>

            <div v-for="(item, index) in customer.contacts" :key="item">
              <div v-if="item.type === 'Email'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
                
                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.removedContacts.push(item.id); customer.contacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in customer.newContacts" :key="item">
              <div v-if="item.type === 'Email'" class="d-flex align-items-center mb-3">
                <div style="width: 20%">
                  <select
                    v-model="item.kind"
                    class="px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option value="Służbowy">Służbowy</option>
                    <option value="Prywatny">Prywatny</option>
                    <option value="Inne">Inne</option>
                  </select>
                </div>

                <div style="width: 70%">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.newContacts.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.newContacts.push({ type: 'Email', kind: '', value: '' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj adres e-mail</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center form-label ms-0">
                  Grupy <i class="material-icons text-warning text-sm ms-1">star</i>
                </label>
                <label v-for="item in customer.groups" :key="item" class="d-flex align-items-center mx-0">
                  <material-checkbox class="me-1" :checked="item.status" @change="item.status = !item.status" /> {{ item.name }}
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="customer.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>

            <div v-for="(item, index) in customer.inputs" :key="item" class="mb-3">
              <div class="d-flex align-items-center mb-1">
                <div style="width: 30%">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-model="item.name"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div :style="item.type === 'date' ? 'width: 40%' : 'width: 60%'">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-if="item.type === 'text'"
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />

                    <input
                      v-if="item.type === 'date'"
                      v-model="item.value"
                      type="date"
                      class="w-100 py-2 text-sm"
                      :class="isDarkMode ? '' : 'text-dark'"
                      :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                    />
                  </div>
                </div>

                <div v-if="item.type === 'date'" style="width: 20%">
                  <select
                    v-model="item.calendar"
                    class="w-100 px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option v-for="calendar in calendars" :key="calendar" :value="calendar.id">{{ calendar.name }}</option>
                  </select>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.removedInputs.push(item.id); customer.inputs.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div v-if="item.type == 'date'" class="d-flex align-items-center" style="flex-wrap: wrap">
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().format('yyyy-MM-DD')">D</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(1, 'days').format('yyyy-MM-DD')">J</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = findNextFriday()">KT</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(7, 'days').format('yyyy-MM-DD')">1T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(14, 'days').format('yyyy-MM-DD')">2T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center cursor-pointer" @click="item.value = moment().add(1, 'months').format('yyyy-MM-DD')">1M</material-badge>
              </div>
            </div>

            <div v-for="(item, index) in customer.newInputs" :key="item" class="mb-3">
              <div class="d-flex align-items-center mb-1">
                <div style="width: 30%">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-model="item.name"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>

                <div :style="item.type === 'date' ? 'width: 40%' : 'width: 60%'">
                  <div class="input-group input-group-static pe-2">
                    <input
                      v-if="item.type === 'text'"
                      v-model="item.value"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="border: 1px"
                    />

                    <input
                      v-if="item.type === 'date'"
                      v-model="item.value"
                      type="date"
                      class="w-100 py-2 text-sm"
                      :class="isDarkMode ? '' : 'text-dark'"
                      :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                    />
                  </div>
                </div>

                <div v-if="item.type === 'date'" style="width: 20%">
                  <select
                    v-model="item.calendar"
                    class="w-100 px-0 py-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="">Wybierz...</option>
                    <option v-for="calendar in calendars" :key="calendar" :value="calendar.id">{{ calendar.name }}</option>
                  </select>
                </div>

                <div class="text-end my-auto" style="width: 10%">
                  <i class="material-icons text-danger cursor-pointer" @click="customer.newInputs.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div v-if="item.type == 'date'" class="d-flex align-items-center" style="flex-wrap: wrap">
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().format('yyyy-MM-DD')">D</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(1, 'days').format('yyyy-MM-DD')">J</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = findNextFriday()">KT</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(7, 'days').format('yyyy-MM-DD')">1T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center me-1 cursor-pointer" @click="item.value = moment().add(14, 'days').format('yyyy-MM-DD')">2T</material-badge>
                <material-badge color="success" size="sm" class="d-flex align-items-center cursor-pointer" @click="item.value = moment().add(1, 'months').format('yyyy-MM-DD')">1M</material-badge>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.newInputs.push({ name: '', value: '', calendar: '', type: 'text' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole tekstowe</span>
                </label>
              </div>

              <div class="col-md-6">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="customer.newInputs.push({ name: '', value: '', calendar: '', type: 'date' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole daty</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in customer.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">{{ item.fileName }}</a> ({{ item.fileSize }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="customer.removedAttachments.push(item.id); customer.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in customer.newAttachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="me-2 text-sm">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="customer.newAttachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(customer.newAttachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchCustomer()"
              >
                Zapisz
              </material-button>
              
              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="customer = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Podgląd kontrahenta -->
      <div v-if="customer && customer.operation === 'overview'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>{{ customer.displayName }}</h5>
          </div>
          <div class="card-body py-0">
            <div v-if="customer.type === 'Company'" class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  NIP: <span class="font-weight-normal ms-2">{{ customer.nip ? customer.nip : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-if="customer.type === 'Person'" class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Przedsiębiorstwo: <span class="font-weight-normal ms-2">{{ customer.company ? customer.company.displayName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-if="customer.type === 'Person'" class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stanowisko: <span class="font-weight-normal ms-2">{{ customer.position ? customer.position : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stawka godzinowa: <span class="font-weight-normal ms-2">{{ customer.price ? customer.price.toFixed(2) : 0 }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Zablokuj stawkę: <span class="font-weight-normal ms-2">{{ customer.blockedPrice ? "Tak" : "Nie" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Rabat: <span class="font-weight-normal ms-2">{{ customer.discount ? customer.discount.toFixed(2) : 0 }}%</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stawka po rabacie: <span class="font-weight-normal ms-2">{{ customer.price ? customer.discount ? (customer.price - (customer.price * (customer.discount / 100))).toFixed(2) : customer.price.toFixed(2) : 0 }} PLN</span>
                </label>
              </div>
            </div>

            <div class="d-flex my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="getCustomer(customer.id, 'edit')"
              >
                Edycja
              </material-button>
            </div>

            <h5>Dane adresowe</h5>

            <div v-if="customer.addresses.length === 0" class="d-flex align-items-center text-sm">Nie podano danych adresowych</div>
            
            <div v-for="(item, index) in customer.addresses" :key="item" class="row">
              <div class="col-12" :class="index !== customer.addresses.length - 1 ? 'mb-2' : ''">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  {{ item.addressName }}: <a :href="`http://maps.google.com/?q=ul. ${ item.street }, ${ item.postCode } ${item.city}`" target="_blank" class="font-weight-normal ms-2">ul. {{ item.street }}, {{ item.postCode }} {{ item.city }}</a>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Dane kontaktowe</h5>

            <div v-if="customer.contacts == 0" class="d-flex align-items-center text-sm">Nie podano danych kontaktowych</div>
            
            <div v-for="item in customer.contacts" :key="item" class="row">
              <div v-if="item.type === 'Phone'" class="col-12 mb-2">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Numer telefonu{{ item.kind ? `(${ item.kind })` : null }}: <span class="font-weight-normal ms-2">{{ item.value ? item.value : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-for="(item, index) in customer.contacts" :key="item" class="row">
              <div v-if="item.type === 'Email'" class="col-12" :class="index !== customer.contacts.length - 1 ? 'mb-2' : ''">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Adres e-mail{{ item.kind ? `(${ item.kind })` : null }}: <span class="font-weight-normal ms-2">{{ item.value ? item.value : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-if="customer.type === 'Company'">
              <h5 class="mt-3">Pracownicy</h5>

              <div v-for="item in customer.jobers" :key="item" class="row mb-3 cursor-pointer" @click="getCustomer(item.id)">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <i class="material-symbols-outlined me-2">person</i>
                    <div class="h-100">
                      <p class="text-sm mb-1">{{ item.displayName }}</p>
                      <p class="mb-0 text-xs">{{ item.position }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="d-flex align-items-center cursor-pointer" @click="getNewCustomer(customer.id, 'Person')">
                    <i class="material-symbols-outlined ms-1 me-2">person_add</i>
                    <span class="text-sm font-weight-light">Utwórz nowy kontakt pracownika</span>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="mt-3">Pozostałe</h5>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold d-flex">
                  Grupy:
                  <material-badge
                    v-for="item in customer.groups"
                    :key="item"
                    color="success"
                    size="sm"
                    class="d-flex align-items-center ms-1"
                    :class="!item.status ? 'd-none' : ''"
                  >
                    {{ item.name }}
                  </material-badge>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Notatka: <span class="font-weight-normal ms-2">{{ customer.note ? customer.note : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row" :class="customer.inputs.length !== 0 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Stworzony przez: <span class="font-weight-normal ms-2">{{ customer.createdBy ? customer.createdBy.fullName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div v-for="(item, index) in customer.inputs" :key="item" class="row" :class="index !== customer.inputs.length - 1 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  {{ item.name }}: <span class="font-weight-normal ms-2">{{ item.type === "date" ? moment(item.value).format("DD/MM/YYYY") : item.value }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Załączniki</h5>

            <div v-if="customer.attachments && customer.attachments.length === 0" class="text-sm">Brak załączników</div>
            
            <div v-for="(item, index) in customer.attachments" :key="item" :class="index !== customer.attachments.length - 1 ? 'mb-3' : ''">
              <div class="row mb-1">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i>
                  <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">
                    {{ item.fileName }}
                  </a>
                  ({{ item.fileSize }} KB)
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-sm">
                  {{ item.description }}
                </div>
              </div>
            </div>

            <h5 class="mt-3">Aktywność</h5>

            <div class="d-flex mb-3">
              <label class="form-label ms-0 me-3 my-auto">Pokaż</label>
              <select
                v-model="customer.activity"
                class="p-1 text-sm"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                style="border: none; border-radius: 10px"
                :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
                @change="order = false"
              >
                <option value="history">Historia</option>
                <option value="comments">Komentarze</option>
                <option value="sale">Sprzedaż</option>
                <option value="tasks">Zadania</option>
                <option value="events">Wydarzenia</option>
              </select>

              <label class="d-flex ms-auto my-auto text-xs cursor-pointer" @click="order = !order; getCustomerHistory(customer.id); getCustomerComments(customer.id); getCustomerSales(customer.id); getCustomerTasks(customer.id); getCustomerEvents(customer.id)">
                <span class="my-auto">{{ order ? "Od najstarszych" : "Od najnowszych" }}</span>
                <i class="material-icons my-auto">{{ order ? "expand_more" : "expand_less" }}</i>
              </label>
            </div>

            <!-- Historia -->
            <div v-if="customer.activity === 'history'">
              <div class="row">
                <timeline-list class="bg-transparent shadow-none">
                  <TimelineItem
                    v-for="item in customer.histories"
                    :key="item"
                    :full-name="item.owner.fullName"
                    :avatar="item.owner.avatar"
                    :activity="item.subject"
                    :date-time="item.createdAt"
                    :description="item.text"
                  />
                </timeline-list>
              </div>
            </div>

            <!-- Komentarze -->
            <div v-if="customer.activity === 'comments'">
              <div class="row" :class="customer.comments.length === 0 ? 'mb-4' : ''">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <material-avatar
                      :img="$getAvatar(me.fullName, me.avatar)"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />

                    <div class="input-group input-group-outline">
                      <input
                        v-model="comment"
                        class="form-control mx-3"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                      />
                    </div>

                    <material-button
                      class="px-4"
                      :color="color"
                      variant="gradient"
                      @click="postCustomerComment(customer.id)"
                    >
                      +
                    </material-button>
                  </div>
                </div>
              </div>

              <hr v-if="customer.comments.length !== 0" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
              
              <div v-for="item in customer.comments" :key="item" class="row mb-2">
                <div class="col-12">
                  <div class="d-flex">
                    <material-avatar
                      :img="$getAvatar(item.owner.fullName, item.owner.avatar)"
                      class="me-3"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />
                    <p style="width: 100% !important">
                      <span class="text-sm d-flex">
                        <b>{{ item.owner.fullName }}</b> &nbsp;&bull; {{ item.createdAt }}
                        <i v-if="item.owner.id === me.id" class="material-icons cursor-pointer ms-auto" @click="deleteCustomerComment(item.id)">delete</i>
                      </span>
                      <span class="d-block text-sm">
                        {{ item.text }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Sprzedaż -->
            <div v-if="customer.activity === 'sale'">
              <div v-if="customer.sales.length === 0" class="d-flex align-items-center text-sm mb-3">Brak sprzedaży</div>
              
              <div v-for="item in customer.sales" :key="item" class="row mb-3 cursor-pointer" @click="$store.state.openedSale = item.id; $redirect('Sprzedaż')">
                <div class="col-8 text-sm font-weight-bold">
                  {{ item.name }}
                  <p class="text-xs opacity-7 m-0 font-weight-normal">{{ moment(item.closedAt).format("DD/MM/YYYY") }}</p>
                </div>
                <div class="col-4 text-xs opacity-7 pt-1" style="text-align: right">
                  {{ item.value }} {{ item.currency }}
                </div>
              </div>
            </div>

            <!-- Zadania -->
            <div v-if="customer.activity === 'tasks'">
              <div v-if="customer.tasks.length === 0" class="d-flex align-items-center text-sm mb-3">Brak zadań</div>
              
              <div v-for="item in customer.tasks" :key="item" class="d-flex align-items-center mb-3 text-sm cursor-pointer" @click="$store.state.openedTask = item.id; $redirect('Zadania')">
                {{ item.name }}
                <span class="text-xs opacity-7 mt-1 ms-auto">{{ moment(item.closedAt).format("DD/MM/YYYY") }}</span>
              </div>
            </div>

            <!-- Wydarzenia -->
            <div v-if="customer.activity === 'events'">
              <div v-if="customer.events.length === 0" class="d-flex align-items-center text-sm mb-3">Brak wydarzeń</div>
              
              <div v-for="item in customer.events" :key="item" class="row mb-3 cursor-pointer" @click="$store.state.openedEvent = item.id; $redirect('Kalendarz')">
                <div class="col-12">
                  <div class="text-sm">{{ item.title }}</div>
                  <div class="text-xs opacity-7">{{ moment(item.startDate).format(item.allDay ? "dddd, DD MMMM" : "dddd, DD MMMM HH:mm") }} do {{ moment(item.endDate).format(item.allDay ? "dddd, DD MMMM" : "dddd, DD MMMM HH:mm") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import TimelineList from "@/examples/Cards/TimelineList.vue"
import TimelineItem from "@/examples/Cards/TimelineItem.vue"
import UserDataService from "@/services/UserDataService"
import CustomerDataService from "@/services/CustomerDataService"
import CustomerGroupDataService from "@/services/CustomerGroupDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import CalendarDataService from "@/services/CalendarDataService"
import DefaultInputDataService from "@/services/DefaultInputDataService"
import CommentDataService from "@/services/CommentDataService"
import AttachmentDataService from "@/services/AttachmentDataService"
import HistoryDataService from "@/services/HistoryDataService"
import SaleDataService from "@/services/SaleDataService"
import TaskDataService from "@/services/TaskDataService"
import EventDataService from "@/services/EventDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Customers",
  components: {
    MaterialAvatar,
    MaterialBadge,
    MaterialCheckbox,
    MaterialRadio,
    MaterialSnackbar,
    MaterialButton,
    TimelineList,
    TimelineItem,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      customer: null, // Obiekt wybranego kontrahenta

      companies: [], // Tablica dostępnych firm
      calendars: [], // Tablica kalendarzy zalogowanego użytkownika
      defaultRate: 0, // Domyślna stawka roboczogodziny

      comment: "", // Zmienna zawierająca treść komentarza
      order: false, // Filtr aktywności
      
      group: null, // Obiekt zawierający dane dotyczące grupy

      customers: [], // Tablica zawierająca dane kontrahentów z API
      customersAC: [], // Tablica zawierająca wszystkie nazwy kontrahentów z API (AutoComplete)

      groups: [], // Tablica wszystkich grup
      cities: [], // Tablica wszystkich miast
      users: [], // Tablica dostępnych użytkowników 

      checkedCustomers: [], // Tablica zawierająca wszystkich zaznaczonych kontrahentów
      modals: [], // Tablica odpowiedzialna za status modali

      error: "", // Zmienna odpowiedzialna za błąd danych

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "name", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_displayName: "", // Filtr: Nazwa
      filter_creators: [], // Filtr: Stworzony przez
      filter_groups: [], // Filtr: Grupy
      filter_cities: [], // Filtr: Miejscowość
      filter_types: [], // Filtr: Typ
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
      
      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_displayName(newSearch) {
      this.filter_displayName = newSearch
      this.getCustomersDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_CUSTOMERS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getCustomers(this.page)
          this.getCustomersAC()
          this.getCities()
          this.getUsers()
          this.getDefaultRate()
          this.getGroups()

          if (this.$store.state.openedCustomer) {
            this.getCustomer(this.$store.state.openedCustomer)
            this.$store.state.openedCustomer = null
          }

          if (this.$store.state.createdCustomer.operation) {
            this.getNewCustomer("", "Company", this.$store.state.createdCustomer.attachments)
            this.$store.state.createdCustomer.operation = false
            this.$store.state.createdCustomer.attachments = []
          }
        }
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje obiekt wybranego kontrahenta
    getCustomer(id, operation = "overview") {
      CustomerDataService.get(id)
      .then(res => {
        this.customer = res.data
        this.customer.operation = operation
        this.customer.activity = "history"

        this.customer.inputs.forEach(item => {
          item.calendar = item.calendar ? item.calendar.id : null 
        })

        if (operation === "edit") {
          this.customer.company = this.customer.company ? this.customer.company.id : ""
        }

        this.getGroups()
        this.getJobers()
        this.getCompanies()
        this.getCalendars()

        this.getAttachments(res.data.id)
        this.getCustomerHistory(res.data.id)
        this.getCustomerComments(res.data.id)
        this.getCustomerSales(res.data.id)
        this.getCustomerTasks(res.data.id)
        this.getCustomerEvents(res.data.id)

        this.customer.newAddresses = []
        this.customer.removedAddresses = []
        this.customer.newContacts = []
        this.customer.removedContacts = []
        this.customer.newInputs = []
        this.customer.removedInputs = []
        this.customer.newAttachments = []
        this.customer.removedAttachments = []

        this.error = ""

        console.log(this.customer)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca obiekt nowego kontrahenta
    getNewCustomer(company, type, attachments = []) {
      this.customer = null
      this.customer = {
        displayName: "",
        nip: "",
        position: "",
        company: company,
        price: this.defaultRate,
        blockedPrice: false,
        discount: 0,
        groups: [],
        note: "",
        type: type,
        addresses: [],
        contacts: [],
        inputs: [],
        attachments: attachments,
        operation: "new",
      }

      this.getGroups()
      this.getCompanies()
      this.getDefaultInputs()
      this.getCalendars()

      this.error = ""
    },

    // Funkcja zmieniająca typ kontrahenta
    onChangeCustomerType() {
      this.customer.type = this.customer.type === "Company" ? "Person" : "Company"
      this.customer.displayName = ""
      this.customer.nip = ""
      this.customer.position = ""
      this.customer.company = ""
    },

    // Funkcja pobierająca dostępne firmy
    getCompanies() {
      CustomerDataService.getAll("?type=Company")
      .then(res => {
        this.companies = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca kontrahentów należących do tej firmy
    getJobers() {
      CustomerDataService.getAll("")
      .then(res => {
        this.customer.jobers = []
        res.data["hydra:member"].forEach(item => {
          if(item.company && (item.company.id === this.customer.id)) {
            this.customer.jobers.push(item)
          }
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca kalendarze zalogowanego użytkownika
    getCalendars() {
      CalendarDataService.getAll(`?members.id=${ this.me.id }`)
      .then(res => {
        this.calendars = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPostCustomer() {
      let success = true
      this.error = ""

      if (this.customer.displayName === "") {
        success = false
        this.error = "Podaj nazwę kontrahenta!"
      }

      if (this.customer.displayName.length > 65) {
        success = false
        this.error = "Nazwa kontrahenta jest za długa (max. 65 znaków)"
      }

      let i = 0
      this.customer.groups.forEach(item => {
        if (item.status) {
          i++
        }
      })

      if (i === 0) {
        success = false
        this.error = "Kontrahent musi należeć do min. 1 grupy"
      }

      this.customer.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }

        if (item.isRequired && item.value === "") {
          success = false
          this.error = "Wymagane pole nie może być puste"
        }
      })

      if (success) {
        this.postCustomer()
      }
    },

    // Funkcja tworząca nowego kontrahenta
    postCustomer() {
      CustomerDataService.post(this.customer)
      .then(res => {
        console.log(res.data)

        this.customer.attachments.forEach(item => {
          this.postAttachment(res.data.id, item)
        })

        this.getCustomers(this.page)
        this.getCustomersAC()
        this.customer = null
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.status) == 400) {
          this.error = error.response.data.error
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchCustomer() {
      let success = true
      this.error = ""

      if (this.customer.displayName === "") {
        success = false
        this.error = "Podaj nazwę kontrahenta!"
      }

      if (this.customer.displayName.length > 65) {
        success = false
        this.error = "Nazwa kontrahenta jest za długa (max. 65 znaków)"
      }

      let i = 0
      this.customer.groups.forEach(item => {
        if (item.status) {
          i++
        }
      })

      if (i === 0) {
        success = false
        this.error = "Kontrahent musi należeć do min. 1 grupy"
      }

      this.customer.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      this.customer.newInputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      if (success) { 
        this.patchCustomer()
      }
    },

    // Funkcja edytująca kontrahenta
    patchCustomer() {
      CustomerDataService.patch(this.customer.id, this.customer,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.customer.newAttachments.forEach(item => {
          this.postAttachment(this.customer.id, item)
        })

        this.snackbar = "success"

        this.getCustomers(this.page)
        this.getCustomersAC()
        this.customer = null
      })
      .catch(error => {
        console.log(error)
        
        if (JSON.stringify(error.response.status) == 400) {
          this.error = error.response.data.error
        }
      })
    },

    // Funkcja pobierająca wybraną grupę
    getCustomerGroup(id) {
      CustomerGroupDataService.get(id)
      .then(res => {
        this.group = res.data
        this.group.error = ""
        this.openModal(1)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca obiekt nowej grupy
    getNewCustomerGroup() {
      this.group = {
        id: null,
        name: "",
        members: [],
        error: "",
      }

      this.openModal(0)
    },

    // Funkcja tworząca nową grupę kontrahentów
    postCustomerGroup() {
      if (this.group.name !== "") {
        if (this.group.name.length <= 65) {
          CustomerGroupDataService.post(
            {
              name: this.group.name
            }
          )
          .then(res => {
            console.log(res.data)
            this.getGroups()
            this.closeModal()
          })
          .catch(error => {
            console.log(error)

            if(JSON.stringify(error.response.status) == 422) {
              this.group.error = "Nazwa grupy jest już zajęta!"
            }
          })
        }
        else {
          this.group.error = "Nazwa grupy jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.group.error = "Nazwa grupy nie może być pusta!"
      }
    },

    // Funkcja edytująca grupę kontrahentów
    patchCustomerGroup() {
      if (this.group.name !== "") {
        if (this.group.name.length <= 65) {
          CustomerGroupDataService.patch(this.group.id,
            {
              name: this.group.name
            },
            {
              headers: { "Content-Type": "application/merge-patch+json" }
            }
          )
          .then(res => {
            console.log(res.data)
            this.getGroups()
            this.closeModal()
          })
          .catch(error => {
            console.log(error)

            if(JSON.stringify(error.response.status) == 422) {
              this.group.error = "Nazwa grupy jest już zajęta!"
            }
          })
        }
        else {
          this.group.error = "Nazwa grupy jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.group.error = "Nazwa grupy nie może być pusta!"
      }
    },

    // Funkcja pilnująca wartości wpisanej w rabacie
    handleInput(event) {
      let value = event.target.value

      if (value < 0) {
        value = 0
      } else if (value > 100) {
        value = 100
      }

      this.customer.discount = value
    },

    // Funkcja usuwająca grupę kontrahenta
    deleteCustomerGroup() {
      if (this.group.customers.length === 0) {
        CustomerGroupDataService.delete(this.group.id)
        .then(res => {
          console.log(res.data)
          this.getGroups()
          this.closeModal()
        })
        .catch(error => {
          console.log(error)
        })
      }
      else {
        this.openModal(3)
      }
    },

    // Funkcja pobierająca wybrane domyślne pola z API
    getDefaultInputs() {
      DefaultInputDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.customer.inputs.push({
            name: item.name,
            value: "",
            calendar: "",
            type: "text",
            isRequired: item.isRequired,
          })
        })
      })
    },

    // Funkcja pobierająca domyślną stawkę roboczogodziny
    getDefaultRate() {
      AppSettingsDataService.getAll("?name=mh_rate")
      .then(res => {
        this.defaultRate = parseFloat(res.data["hydra:member"][0].value)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca załącznik do listy
    createAttachment(array, event) {
      array.push(
        {
          file: event.target.files[0],
          description: ""
        }
      )
    },

    // Funkcja pobierająca wybrane załączniki
    getAttachments(id) {
      AttachmentDataService.getAll(`?customer.id=${ id }`)
      .then(res => {
        this.customer.attachments = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowy załącznik
    postAttachment(id, item) {
      const formData = new FormData()
      formData.append("file", item.file)
      formData.append("description", item.description)
      formData.append("entity", "Customer")
      formData.append("entityId", id)

      AttachmentDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja znajdująca najbliższy piątek
    findNextFriday() {
      let today = this.moment()
      while (today.day() != 5) {
        today.add(1, "days")
      }

      return today.format("yyyy-MM-DD")
    },

    // Funkcja pobierająca historie wybranego kontrahenta
    getCustomerHistory(id) {
      let value = this.order ? "asc" : "desc"
      HistoryDataService.getAll(`?page=1&itemsPerPage=10&order[createdAt]=${ value }&customer.id=${ id }`)
      .then(res => {
        this.customer.histories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca historie
    postCustomerHistory(id, subject, text) {
      HistoryDataService.post(
        {
          owner: `/users/${ this.me.id }`,
          subject: subject,
          text: text,
          createdAt: this.moment().format(),
          customer: `/customers/${ id }`
        }
      )
      .then(res => {
        console.log(res.data)
        this.getCustomerHistory(id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca komentarze wybranego kontrahenta
    getCustomerComments(id) {
      let value = this.order ? "asc" : "desc"
      CommentDataService.getAll(`?customer.id=${ id }&order[createdAt]=${ value }`)
      .then(res => {
        this.customer.comments = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca komentarz
    postCustomerComment(id) {
      if (this.comment !== "") {
        CommentDataService.post(
          {
            owner: `/users/${ this.me.id }`,
            text: this.comment,
            createdAt: this.moment().format(),
            customer: `/customers/${ id }`
          }
        )
        .then(res => {
          console.log(res.data)
          this.postCustomerHistory(id, "dodał/a komentarz", res.data.text)
          this.getCustomerComments(id)
          this.comment = ""
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja usuwająca komentarz
    deleteCustomerComment(id) {
      CommentDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getCustomerComments(this.customer.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca oferty wybranego kontrahenta
    getCustomerSales(id) {
      let value = this.order ? "asc" : "desc"
      SaleDataService.getAll(`?customer.id=${ id }&order[closedAt]=${ value }`)
      .then(res => {
        this.customer.sales = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca zadania wybranego kontrahenta
    getCustomerTasks(id) {
      let value = this.order ? "asc" : "desc"
      TaskDataService.getAll(`?customer.id=${ id }&order[closedAt]=${ value }&group.members.id=${ this.me.id }`)
      .then(res => {
        this.customer.tasks = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wydarzenia wybranego kontrahenta
    getCustomerEvents(id) {
      let value = this.order ? "asc" : "desc"
      EventDataService.getAll(`?customers.id=${ id }&order[startDate]=${ value }&calendar.members.id=${ this.me.id }`)
      .then(res => {
        this.customer.events = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funckja usuwająca kontrahenta
    deleteCustomer(id) {
      CustomerDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getCustomers(this.page)
        this.getCustomersAC()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if(JSON.stringify(error.response.status) == 500) {
          this.openModal(3)
        }
      })
    },

    // Funckja usuwająca wiele kontrahentów
    deleteCustomers() {
      this.checkedCustomers.forEach(item => {
        this.deleteCustomer(item.id)
      })
    },

    // Funkcja dodająca nowego użytkownika do grupy
    selectNewUser(fullName) {
      this.users.forEach((item, index) => {
        if (item.fullName === fullName) {
          this.group.members.push(item)
          this.usersAC.splice(index, 1)
          this.users.splice(index, 1)
        }
      })

      this.$refs.usersAC.clearInput()
    },

    // Funkcja exportująca kontrahentów do pliku
    exportCustomers() {
      CustomerDataService.export()
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "kontrahenci.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja importująca kontrahentów z wybranego pliku
    importCustomers(event) {
      const formData = new FormData()
      formData.append("file", event.target.files[0])

      CustomerDataService.import(this.me.id, formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getCustomers(1)
        this.getCustomersAC()
        this.openModal(4)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wczytuje przefiltrowane dane kontrahenta z API
    getCustomers(page) {
      let creators = ""
      this.filter_creators.forEach(item => {
        creators += `&createdBy.fullName[]=${ item }`
      })

      let groups = ""
      this.filter_groups.forEach(item => {
        groups += `&groups.name[]=${ item }`
      })

      let cities = ""
      this.filter_cities.forEach(item => {
        cities += `&addresses.city[]=${ item }`
      })

      let types = ""
      this.filter_types.forEach(item => {
        types += `&type[]=${ item }`
      })

      CustomerDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&displayName=${ this.filter_displayName }${ creators }${ groups }${ cities }${ types }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.customers = res.data["hydra:member"]
        this.unselectCustomers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane kontrahenta z API z opóźnieniem
    getCustomersDelay: _.debounce(function(page) {
      let creators = ""
      this.filter_creators.forEach(item => {
        creators += `&createdBy.fullName[]=${ item }`
      })

      let groups = ""
      this.filter_groups.forEach(item => {
        groups += `&groups.name[]=${ item }`
      })

      let cities = ""
      this.filter_cities.forEach(item => {
        cities += `&addresses.city[]=${ item }`
      })

      let types = ""
      this.filter_types.forEach(item => {
        types += `&type[]=${ item }`
      })

      CustomerDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&displayName=${ this.filter_displayName }${ creators }${ groups }${ cities }${ types }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.customers = res.data["hydra:member"]
        this.unselectCustomers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja wczytuje wszystkich użytkowników z API
    getCustomersAC() {
      CustomerDataService.getAll("")
      .then(res => {
        this.customersAC = []
        res.data["hydra:member"].forEach(customer => {
          this.customersAC.push(customer.displayName)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkie grupy
    getGroups() {
      CustomerGroupDataService.getAll("")
      .then(res => {
        this.groups = res.data["hydra:member"]

        if (this.customer) {
          let groups = []

          res.data["hydra:member"].forEach(item => {
            item.status = false
            this.customer.groups.forEach(group => {
              if (item.id === group.id) {
                item.status = true
              }
            })

            groups.push(item)   
          })

          this.customer.groups = groups
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkie miasta kontrahentów
    getCities() {
      CustomerDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(customer => {
          customer.addresses.forEach(address => {
            let add = true

            this.cities.forEach(city => {
              if (address.city === city) {
                add = false
              }
            })

            if (add) {
              this.cities.push(address.city)
            }
          })
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getUsers() {
      UserDataService.getAll("?order[fullName]=asc")
      .then(res => {
        this.users = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca zaznaczonego kontrahenta do listy
    selectCustomer(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedCustomers.push(item)
      }
      else {
        this.checkedCustomers.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedCustomers.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkich kontrahentów
    selectCustomers(e) {
      this.checkedCustomers = []
      if(e.target.checked) {
        this.customers.forEach(item => {
          item.mark = true
          this.checkedCustomers.push(item)
        })
      }
      else {
        this.customers.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkich kontrahentów
    unselectCustomers() {
      this.checkedCustomers = []
      this.customers.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_displayName = item
      this.getCustomers(1)
    },

    // Funkcja przypisująca do zmiennej wybranego twórce
    onChangeCreator(event) {
      let exist = false

      this.filter_creators.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_creators.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_creators.push(event)
      }

      this.getCustomers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną grupę
    onChangeGroup(event) {
      let exist = false

      this.filter_groups.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_groups.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_groups.push(event)
      }

      this.getCustomers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną miejscowość
    onChangeCity(event) {
      let exist = false

      this.filter_cities.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_cities.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_cities.push(event)
      }

      this.getCustomers(1)
    },

    // Funkcja przypisująca do zmiennej wybrany typ
    onChangeType(event) {
      let exist = false

      this.filter_types.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_types.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_types.push(event)
      }

      this.getCustomers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getCustomers(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getCustomers(page)
      }
      else {
        this.getCustomers(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getCustomers(page)
      }
      else {
        this.getCustomers(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getCustomers(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getCustomers(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

input[type="date"] {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>