<template>
  <div v-if="me" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Użytkownicy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#user-data" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Dane użytkownika</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#password" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">lock</i>
                <span class="text-sm">Hasło</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#permissions" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">security</i>
                <span class="text-sm">Uprawnienia</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#groups" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">groups</i>
                <span class="text-sm">Grupy</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#mail-accounts" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">mail</i>
                <span class="text-sm">Konta pocztowe</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="col-lg-9">
        <!-- Dane użytkownika -->
        <div id="user-data" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dane użytkownika</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Imię
                  </label>
                  <input
                    v-model="user.firstName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwisko
                  </label>
                  <input
                    v-model="user.lastName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres e-mail
                  </label>
                  <input
                    v-model="user.email"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Numer telefonu
                  </label>
                  <input
                    v-model="user.phoneNumber"
                    v-mask="'+48 ### ### ###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorData !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorData }}</p>
        </div>

        <!-- Hasło -->
        <div id="password" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Hasło</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Hasło
                  </label>
                  <input
                    v-model="user.password"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Powtórz hasło
                  </label>
                  <input
                    v-model="user.confirmPassword"
                    type="password"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorPassword !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorPassword }}</p>
        </div>

        <!-- Uprawnienia -->
        <div id="permissions" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Uprawnienia</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox
                  :checked="user.roles.includes('ROLE_USERS') && user.roles.includes('ROLE_GROUPS') && user.roles.includes('ROLE_MAIL_ACCOUNTS') && user.roles.includes('ROLE_PERMISSIONS') && user.roles.includes('ROLE_PAYMENTS') && user.roles.includes('ROLE_PACKAGES') && user.roles.includes('ROLE_APP_SETTINGS') && user.roles.includes('ROLE_MAILBOX') && user.roles.includes('ROLE_CUSTOMERS') && user.roles.includes('ROLE_SALE') && user.roles.includes('ROLE_OFFERS') && user.roles.includes('ROLE_TEMPLATES') && user.roles.includes('ROLE_PRINT_TEMPLATES') && user.roles.includes('ROLE_ASSORTMENTS') && user.roles.includes('ROLE_CATEGORIES') && user.roles.includes('ROLE_CALENDAR') && user.roles.includes('ROLE_TASKS') && user.roles.includes('ROLE_REPORTS') && user.roles.includes('ROLE_PERIODIC_REPORTS') && user.roles.includes('ROLE_MESSAGES') && user.roles.includes('ROLE_ASOM_INSPECTIONS') && user.roles.includes('ROLE_ASOM_WORKSHEETS')"
                    @change="$event.target.checked ? user.roles = ['ROLE_USER', 'ROLE_USERS', 'ROLE_GROUPS', 'ROLE_MAIL_ACCOUNTS', 'ROLE_PERMISSIONS', 'ROLE_PAYMENTS', 'ROLE_PACKAGES', 'ROLE_APP_SETTINGS', 'ROLE_MAILBOX', 'ROLE_CUSTOMERS', 'ROLE_SALE', 'ROLE_OFFERS', 'ROLE_TEMPLATES', 'ROLE_PRINT_TEMPLATES', 'ROLE_ASSORTMENTS', 'ROLE_CATEGORIES', 'ROLE_CALENDAR', 'ROLE_TASKS', 'ROLE_REPORTS', 'ROLE_PERIODIC_REPORTS', 'ROLE_MESSAGES', 'ROLE_ASOM_INSPECTIONS', 'ROLE_ASOM_WORKSHEETS'] : user.roles = ['ROLE_USER']"
                  />
                  <span class="mx-2 text-sm">(Wszystkie)</span>
                </label>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox checked disabled />
                  <span class="mx-2 text-sm">Kokpit</span>
                </label>

                <div class="d-flex align-items-center mb-2">
                  <material-checkbox
                    class="text-sm"
                    :checked="user.roles.includes('ROLE_USERS') && user.roles.includes('ROLE_GROUPS') && user.roles.includes('ROLE_MAIL_ACCOUNTS') && user.roles.includes('ROLE_PERMISSIONS') && user.roles.includes('ROLE_PAYMENTS') && user.roles.includes('ROLE_PACKAGES') && user.roles.includes('ROLE_APP_SETTINGS')"
                    @change="onChangePermissions(['ROLE_USERS', 'ROLE_GROUPS', 'ROLE_MAIL_ACCOUNTS', 'ROLE_PERMISSIONS', 'ROLE_PAYMENTS', 'ROLE_PACKAGES', 'ROLE_APP_SETTINGS'], $event.target.checked)"
                  />
                  <span class="mx-2 text-sm">Panel administratora</span>
                  <i class="material-symbols-outlined text-md cursor-pointer" @click="permissionsExpansion[0] = !permissionsExpansion[0]">{{ permissionsExpansion[0] ? "expand_less" : "expand_more" }}</i>
                </div>

                <div v-if="permissionsExpansion[0]" class="ms-3">
                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_USERS')" @change="onChangePermission('ROLE_USERS')" />
                    <span class="mx-2 text-sm">Użytkownicy</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_GROUPS')" @change="onChangePermission('ROLE_GROUPS')" />
                    <span class="mx-2 text-sm">Grupy</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_MAIL_ACCOUNTS')" @change="onChangePermission('ROLE_MAIL_ACCOUNTS')" />
                    <span class="mx-2 text-sm">Konta pocztowe</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_PERMISSIONS')" @change="onChangePermission('ROLE_PERMISSIONS')" />
                    <span class="mx-2 text-sm">Uprawnienia</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_PAYMENTS')" @change="onChangePermission('ROLE_PAYMENTS')" />
                    <span class="mx-2 text-sm">Płatności</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_PACKAGES')" @change="onChangePermission('ROLE_PACKAGES')" />
                    <span class="mx-2 text-sm">Pakiety</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_APP_SETTINGS')" @change="onChangePermission('ROLE_APP_SETTINGS')" />
                    <span class="mx-2 text-sm">Ustawienia aplikacji</span>
                  </label>
                </div>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox :checked="user.roles.includes('ROLE_MAILBOX')" @change="onChangePermission('ROLE_MAILBOX')" />
                  <span class="mx-2 text-sm">Poczta</span>
                </label>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox :checked="user.roles.includes('ROLE_CUSTOMERS')" @change="onChangePermission('ROLE_CUSTOMERS')" />
                  <span class="mx-2 text-sm">Kontrahenci</span>
                </label>

                <div class="d-flex align-items-center mb-2">
                  <material-checkbox
                    class="text-sm"
                    :checked="user.roles.includes('ROLE_SALE') && user.roles.includes('ROLE_OFFERS') && user.roles.includes('ROLE_TEMPLATES') && user.roles.includes('ROLE_PRINT_TEMPLATES') && user.roles.includes('ROLE_ASSORTMENTS') && user.roles.includes('ROLE_CATEGORIES')"
                    @change="onChangePermissions(['ROLE_SALE', 'ROLE_OFFERS', 'ROLE_TEMPLATES', 'ROLE_PRINT_TEMPLATES', 'ROLE_ASSORTMENTS', 'ROLE_CATEGORIES'], $event.target.checked)"
                  />
                  <span class="mx-2 text-sm">Oferty i zamówienia</span>
                  <i class="material-symbols-outlined text-md cursor-pointer" @click="permissionsExpansion[1] = !permissionsExpansion[1]">{{ permissionsExpansion[1] ? "expand_less" : "expand_more" }}</i>
                </div>

                <div v-if="permissionsExpansion[1]" class="ms-3">
                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_SALE')" @change="onChangePermission('ROLE_SALE')" />
                    <span class="mx-2 text-sm">Sprzedaż</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_OFFERS')" @change="onChangePermission('ROLE_OFFERS')" />
                    <span class="mx-2 text-sm">Oferty</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_TEMPLATES')" @change="onChangePermission('ROLE_TEMPLATES')" />
                    <span class="mx-2 text-sm">Szablony ofert</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_PRINT_TEMPLATES')" @change="onChangePermission('ROLE_PRINT_TEMPLATES')" />
                    <span class="mx-2 text-sm">Szablony wydruku</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_ASSORTMENTS')" @change="onChangePermission('ROLE_ASSORTMENTS')" />
                    <span class="mx-2 text-sm">Asortyment</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_CATEGORIES')" @change="onChangePermission('ROLE_CATEGORIES')" />
                    <span class="mx-2 text-sm">Ustawienia</span>
                  </label>
                </div>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox :checked="user.roles.includes('ROLE_CALENDAR')" @change="onChangePermission('ROLE_CALENDAR')" />
                  <span class="mx-2 text-sm">Kalendarz</span>
                </label>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox :checked="user.roles.includes('ROLE_TASKS')" @change="onChangePermission('ROLE_TASKS')" />
                  <span class="mx-2 text-sm">Zadania</span>
                </label>

                <div class="d-flex align-items-center mb-2">
                  <material-checkbox
                    class="text-sm"
                    :checked="user.roles.includes('ROLE_REPORTS') && user.roles.includes('ROLE_PERIODIC_REPORTS')"
                    @change="onChangePermissions(['ROLE_REPORTS', 'ROLE_PERIODIC_REPORTS'], $event.target.checked)"
                  />
                  <span class="mx-2 text-sm">Raporty</span>
                  <i class="material-symbols-outlined text-md cursor-pointer" @click="permissionsExpansion[2] = !permissionsExpansion[2]">{{ permissionsExpansion[2] ? "expand_less" : "expand_more" }}</i>
                </div>

                <div v-if="permissionsExpansion[2]" class="ms-3">
                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_REPORTS')" @change="onChangePermission('ROLE_REPORTS')" />
                    <span class="mx-2 text-sm">Raporty</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_PERIODIC_REPORTS')" @change="onChangePermission('ROLE_PERIODIC_REPORTS')" />
                    <span class="mx-2 text-sm">Raporty cykliczne</span>
                  </label>
                </div>

                <label class="d-flex align-items-center mb-2 ms-0">
                  <material-checkbox :checked="user.roles.includes('ROLE_MESSAGES')" @change="onChangePermission('ROLE_MESSAGES')" />
                  <span class="mx-2 text-sm">Wiadomości</span>
                </label>

                <div class="d-flex align-items-center mb-2">
                  <material-checkbox
                    class="text-sm"
                    :checked="user.roles.includes('ROLE_ASOM_INSPECTIONS') && user.roles.includes('ROLE_ASOM_WORKSHEETS')"
                    @change="onChangePermissions(['ROLE_ASOM_INSPECTIONS', 'ROLE_ASOM_WORKSHEETS'], $event.target.checked)"
                  />
                  <span class="mx-2 text-sm">ASOM</span>
                  <i class="material-symbols-outlined text-md cursor-pointer" @click="permissionsExpansion[3] = !permissionsExpansion[3]">{{ permissionsExpansion[3] ? "expand_less" : "expand_more" }}</i>
                </div>

                <div v-if="permissionsExpansion[3]" class="ms-3">
                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_ASOM_INSPECTIONS')" @change="onChangePermission('ROLE_ASOM_INSPECTIONS')" />
                    <span class="mx-2 text-sm">Przeglądy</span>
                  </label>

                  <label class="d-flex align-items-center mb-2">
                    <material-checkbox :checked="user.roles.includes('ROLE_ASOM_WORKSHEETS')" @change="onChangePermission('ROLE_ASOM_WORKSHEETS')" />
                    <span class="mx-2 text-sm">Karty pracy</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Grupy -->
        <div id="groups" class="card mb-4 z-index-3">
          <div class="card-header pb-0">
            <h5>Grupy</h5>
            <p class="mb-0 text-sm">
              Wybierz grupę, do której ma należeć użytkownik.
            </p>
          </div>
          <div class="card-body pt-0">
            <div v-if="user.groups.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak podłączonych grup
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in user.groups"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.name }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="groups.push(item); groupsAC.push(item.name); user.groups.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0">Podłącz grupę</label>
                <SimpleTypeahead
                  ref="groupsAC"
                  :items="groupsAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-2"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectGroup"
                >
                </SimpleTypeahead>
              </div>
            </div>
          </div>
        </div>

        <!-- Konta pocztowe -->
        <div id="mail-accounts" class="card mb-4">
          <div class="card-header pb-0">
            <h5>Konta pocztowe</h5>
            <p class="mb-0 text-sm">
              Dodaj konta pocztowe z których może korzystać użytkownik.
            </p>
          </div>
          <div class="card-body pt-0">
            <div v-if="user.mailAccounts.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak podłączonych kont pocztowych
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="(item, index) in user.mailAccounts"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ item.accountMail }}
                <i
                  class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer"
                  @click="mailAccounts.push(item); mailAccountsAC.push(item.accountMail); user.mailAccounts.splice(index, 1)"
                >
                  close
                </i>
              </material-badge>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0">Podłącz konto pocztowe</label>
                <SimpleTypeahead
                  ref="mailAccountsAC"
                  :items="mailAccountsAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-2"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectMailAccount"
                >
                </SimpleTypeahead>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Dodaj
          </material-button>

          <material-button
            class="ms-3"
            :color="color"
            variant="gradient"
            @click="addAnother = true; validation()"
          >
            Dodaj i utwórz kolejnego
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Użytkownicy')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import { mapState } from "vuex"

export default {
  name: "NewUser",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      user: {
        email: "",
        fullName: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        groups: [],
        mailAccounts: [],
        roles: ["ROLE_USER", "ROLE_MAILBOX", "ROLE_CUSTOMERS", "ROLE_SALE", "ROLE_OFFERS", "ROLE_TEMPLATES", "ROLE_PRINT_TEMPLATES", "ROLE_ASSORTMENTS", "ROLE_CATEGORIES", "ROLE_CALENDAR", "ROLE_TASKS", "ROLE_REPORTS", "ROLE_PERIODIC_REPORTS", "ROLE_MESSAGES", "ROLE_ASOM_INSPECTIONS", "ROLE_ASOM_WORKSHEETS"],
      }, // Obiekt tworzonego użytkownika
      
      groups: [], // Lista wszystkich dostępnych grup
      groupsAC: [], // Lista nazw wszystkich dostępnych grup
      
      mailAccounts: [], // Lista wszystkich wolnych kont pocztowych
      mailAccountsAC: [], // Lista nazw wszystkich wolnych kont pocztowych
      
      permissionsExpansion: [], // Tablica rozwinięcia uprawnień

      errorData: "", // Zmienna odpowiedzialna za błąd danych
      errorPassword: "", // Zmienna odpowiedzialna za błąd hasła

      addAnother: false, // Zmienna sprawdzająca czy dodać kolejne konto
    }
  },
  computed: {
    ...mapState([
      "isAbsolute",
      "isDarkMode",
      "color",
    ])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_USERS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getGroups()
          this.getMailAccounts()
        }
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true

      this.errorData = ""
      this.errorPassword = ""

      if (this.user.firstName == "" || this.user.lastName == "" || this.user.email == "" || this.user.phoneNumber == "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (this.user.firstName.includes(" ") || this.user.lastName.includes(" ")) {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (!this.user.email.includes("@") || !this.user.email.includes(".")) {
        success = false
        this.errorData = "Niepoprawny adres e-mail!"
      }

      const regex = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/
      if (regex.test(this.user.email)) {
        success = false
        this.errorData = "Niepoprawny adres e-mail!"
      }

      if (this.user.phoneNumber == "+48 000 000 000") {
        success = false
        this.errorData = "Niepoprawny numer telefonu!"
      }

      if (this.user.password == "" || this.user.confirmPassword == "") {
        success = false
        this.errorPassword = "Niepoprawne hasło!"
      }
      
      if (this.user.password != this.user.confirmPassword) {
        success = false
        this.errorPassword = "Niepoprawne hasło!"
      }

      if (success) {
        this.postUser()
      }
    },

    // Funkcja dodająca nowego użytkownika do bazy danych
    postUser() {
      this.user.fullName = this.user.firstName + " " + this.user.lastName
      UserDataService.post(this.user)
      .then(res => {
        console.log(res.data)

        if (this.addAnother) {
          this.user.email = ""
          this.user.firstName = ""
          this.user.lastName = ""
          this.user.password = ""
          this.user.confirmPassword = ""
          this.user.phoneNumber = ""
          this.user.groups = []
          this.user.mailAccounts = []
          this.user.roles = ["ROLE_USER", "ROLE_MAILBOX", "ROLE_CUSTOMERS", "ROLE_SALE", "ROLE_OFFERS", "ROLE_TEMPLATES", "ROLE_PRINT_TEMPLATES", "ROLE_ASSORTMENTS", "ROLE_CATEGORIES", "ROLE_CALENDAR", "ROLE_TASKS", "ROLE_REPORTS", "ROLE_PERIODIC_REPORTS", "ROLE_MESSAGES", "ROLE_ASOM_INSPECTIONS", "ROLE_ASOM_WORKSHEETS"]
          this.getGroups()
          this.getMailAccounts()
        }
        else {
          this.$redirect("Użytkownicy")
        }
      })
      .catch(error => {
        console.log(error)

        if (JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if (JSON.stringify(error.response.status) == 400) {
          this.errorData = error.response.data.error
        }
      })
    },
    
    // Funkcja pobierająca wszystkie grupy z API
    getGroups() {
      UserGroupDataService.getAll("")
      .then(res => {
        this.groups = []
        this.groupsAC = []

        res.data["hydra:member"].forEach(item => {
          this.groups.push(item)
          this.groupsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkie konta pocztowe z API
    getMailAccounts() {
      MailAccountDataService.getAll("")
      .then(res => {
        this.mailAccounts = []
        this.mailAccountsAC = []

        res.data["hydra:member"].forEach(item => {
          this.mailAccounts.push(item)
          this.mailAccountsAC.push(item.accountMail)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o grupie z listy rozwijanej
    selectGroup(group) {
      this.groups.forEach((item, index) => {
        if(item.name === group) {
          this.user.groups.push(item)
          this.groups.splice(index, 1)
          this.groupsAC.splice(index, 1)
        }
      })

      this.$refs.groupsAC.clearInput()
    },

    // Funkcja pobierająca dane o koncie pocztowym z listy rozwijanej
    selectMailAccount(mailAccount) {
      this.mailAccounts.forEach((item, index) => {
        if (item.accountMail === mailAccount) {
          this.user.mailAccounts.push(item)
          this.mailAccounts.splice(index, 1)
          this.mailAccountsAC.splice(index, 1)
        }
      })

      this.$refs.mailAccountsAC.clearInput()
    },

    // Funkcja zarządzająca rolą
    onChangePermission(role) {
      if (this.user.roles.includes(role)) {
        this.user.roles.splice(this.user.roles.indexOf(role), 1)
      }
      else {
        this.user.roles.push(role)
      }
    },

    // Funkcja zarządzająca rolami
    onChangePermissions(roles, checked) {
      roles.forEach(role => {
        if (checked) {
          if (!this.user.roles.includes(role)) {
            this.user.roles.push(role)
          }
        }
        else {
          if (this.user.roles.includes(role)) {
            this.user.roles.splice(this.user.roles.indexOf(role), 1)
          }
        }
      })
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>