<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
              >
                <div
                  class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  style="background-size: cover; background-repeat: no-repeat; background-position: center;"
                  :style="{ backgroundImage: 'url(' + require('@/assets/img/illustrations/illustration.jpg') + ')' }"
                ></div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div class="card card-plain">
                  <div class="mb-4 pb-0 card-header text-center bg-transparent">
                    <img src="@/assets/img/logo.png" width="225">
                  </div>
                  <div class="card-body pt-2">
                    <div class="w-100 mb-4 text-center text-sm">
                      <h5>Resetowanie hasła</h5>
                      W ciągu kilku minut na swoją skrzynkę pocztową powinieneś otrzymać link do zresetowania hasła
                    </div>

                    <p
                      v-if="status"
                      class="w-100 text-center text-sm font-weight-bold"
                      :class="`text-${ status.color }`"
                    >
                      {{ status.text }}
                    </p>

                    <div class="input-group input-group-outline mb-3">
                      <label class="d-flex mb-1">
                        Adres e-mail
                      </label>
                      <input
                        v-model="email"
                        class="w-100 form-control"
                      />
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <material-button
                          variant="gradient"
                          color="primary"
                          full-width
                          size="lg"
                          @click="checkUser()"
                        >
                          Potwierdź
                        </material-button>
                      </div>
                      <div class="col-md-6">
                        <material-button
                          variant="gradient"
                          color="light"
                          full-width
                          size="lg"
                          @click="$redirect('Login')"
                        >
                          Anuluj
                        </material-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import { mapMutations } from "vuex"

const body = document.getElementsByTagName("body")[0]

export default {
  name: "ResetPassword",
  components: {
    MaterialButton,
  },
  data() {
    return {
      email: "", // Zmienna przechowująca wpisany email
      status: null, // Objekt odpowiedzialny za treść statusu
    }
  },
  beforeMount() {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
    body.classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
    body.classList.add("bg-gray-100")
  },
  created() {
    // Jeśli token jest ustawiony to wyrzuca z tej strony to Kokpitu
    if (localStorage.getItem("token")) {
      this.$router.push({ name: "Kokpit" })
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    checkUser() {
      UserDataService.forgot_password(
        {
          email: this.email
        },
        {
          headers: {
            Authorization: null
          }
        }
      )
      .then(res => {
        this.status = {
          color: res.data.color,
          text: res.data.text
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>
