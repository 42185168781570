<template>
  <div v-if="me && mailAccount" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Konta pocztowe')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#incoming" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">south</i>
                <span class="text-sm">Konto przychodzące</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#outgoing" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">north</i>
                <span class="text-sm">Konto wychodzące</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#members" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Przypisani użytkownicy</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje ogólne -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Nazwa konta: <span class="font-weight-normal ms-2">{{ mailAccount.accountName }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Adres e-mail: <span class="font-weight-normal ms-2">{{ mailAccount.accountMail }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Konto przychodzące -->
        <div id="incoming" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Konto przychodzące</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  SSL: <span class="font-weight-normal ms-2">{{ mailAccount.iaSsl ? "TAK" : "NIE" }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Adres serwera: <span class="font-weight-normal ms-2">{{ mailAccount.iaServerAddress }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Port: <span class="font-weight-normal ms-2">{{ mailAccount.iaPort }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Nazwa użytkownika: <span class="font-weight-normal ms-2">{{ mailAccount.iaUsername }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Konto wychodzące -->
        <div id="outgoing" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Konto wychodzące</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  SSL: <span class="font-weight-normal ms-2">{{ mailAccount.oaSsl ? "TAK" : "NIE" }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Adres serwera: <span class="font-weight-normal ms-2">{{ mailAccount.oaServerAddress }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Port: <span class="font-weight-normal ms-2">{{ mailAccount.oaPort }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Nazwa użytkownika: <span class="font-weight-normal ms-2">{{ mailAccount.oaUsername }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Przypisani użytkownicy -->
        <div id="members" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Przypisani użytkownicy</h5>
          </div>
          <div class="card-body pt-1">
            <div v-if="mailAccount.members.length === 0" class="d-flex align-items-center text-sm">
              Brak podłączonych użytkowników
            </div>

            <div v-else class="d-flex align-items-center" style="flex-wrap: wrap">
              <material-badge
                v-for="item in mailAccount.members"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                <i class="material-symbols-outlined me-1 text-xs font-weight-bold cursor-pointer">person</i>
                {{ item.fullName }}
              </material-badge>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="$redirectID('Edycja konta pocztowego', mailAccount.id)"
          >
            Edytuj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import MailAccountDataService from "@/services/MailAccountDataService"
import { mapState } from "vuex"

export default {
  name: "OverviewMailAccount",
  components: {
    MaterialBadge,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      mailAccount: null, // Obiekt edytowanego konta pocztowego
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_MAIL_ACCOUNTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getMailAccount()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane edytowanego konta pocztowego
    getMailAccount() {
      MailAccountDataService.get(this.$route.params.id)
      .then(res => {
        this.mailAccount = res.data
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  color: #7b809a;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #eeeeee;
}

select option {
  color: black !important;
}
</style>