import http from "@/http-common"

class HistoryDataService {
    getAll(url) {
        return http.get(`histories${ url }`)
    }

    get(id) {
        return http.get(`histories/${ id }`)
    }

    post(data) {
        return http.post('histories', data)
    }

    patch(id, data, headers) {
        return http.patch(`histories/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`histories/${ id }`)
    }
}

export default new HistoryDataService()