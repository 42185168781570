import http from "@/http-common"

class SharedMailboxDataService {
    getAll(url) {
        return http.get(`shared_mailboxes${ url }`)
    }

    get(id) {
        return http.get(`shared_mailboxes/${ id }`)
    }

    post(data) {
        return http.post('shared_mailboxes', data)
    }

    patch(id, data, headers) {
        return http.patch(`shared_mailboxes/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`shared_mailboxes/${ id }`)
    }
}

export default new SharedMailboxDataService()