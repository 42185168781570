import { createApp } from "vue"
import App from "@/App.vue"
import store from "@/store"
import router from "@/router"
import moment from "moment"
import VueTheMask from "vue-the-mask"
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css"
import "flatpickr/dist/flatpickr.min.css";
import "@/assets/css/nucleo-icons.css"
import "@/assets/css/nucleo-svg.css"
import VueSweetalert2 from "vue-sweetalert2"
import MaterialDashboard from "@/material-dashboard"

require("moment/locale/pl")

const appInstance = createApp(App)
appInstance.use(store)
appInstance.use(router)
appInstance.use(VueTheMask)
appInstance.use(VueSweetalert2)
appInstance.use(MaterialDashboard)

// Zmienne / Funkcje globalne
appInstance.config.globalProperties.moment = moment
appInstance.config.globalProperties.$redirect = function (name) { // Globalna funkcja zmiany stron
    this.$router.push({ name: name })
}
appInstance.config.globalProperties.$redirectID = function (name, id) { // Globalna funkcja zmiany stron z ID
    this.$router.push({ name: name, params: { id: id } })
}
appInstance.config.globalProperties.$getAvatar = function (fullName, color) { // Globalna funkcja pobierająca avatar użytkownika
    return `https://ui-avatars.com/api/?name=${ fullName.substring(0, fullName.indexOf(" ")) }+${ fullName.substring(fullName.indexOf(" ") + 1) }&background=${ color }&color=ffffff&bold=true`
}
appInstance.config.globalProperties.$logout = function () { // Globalna funkcja wylogowania
    localStorage.removeItem("token")
    if (window.location.search.includes('?asom=1')) {
        this.$router.push({ name: "Login", query: { asom: 1 } })
    }
    else {
        this.$router.push({ name: "Login" })
    }
}

appInstance.mount("#app")