<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowa stawka VAT -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowa stawka VAT</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  Stawka VAT
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="vat"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postVatRate()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja stawek VAT -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1 d-flex justify-content-between" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div>
              <h5 class="mb-1">Edycja stawek VAT</h5>
              <p class="mb-0 text-sm">
                Zmiany zapisują się automatycznie
              </p>
            </div>

            <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="d-flex align-items-center" style="flex-wrap: wrap">
              <material-badge
                color="success"
                size="sm"
                class="d-flex align-items-center text-xs me-1 mb-1"
              >
                0%
              </material-badge>

              <material-badge
                color="success"
                size="sm"
                class="d-flex align-items-center text-xs me-1 mb-1"
              >
                5%
              </material-badge>

              <material-badge
                color="success"
                size="sm"
                class="d-flex align-items-center text-xs me-1 mb-1"
              >
                8%
              </material-badge>

              <material-badge
                color="success"
                size="sm"
                class="d-flex align-items-center text-xs me-1 mb-1"
              >
                23%
              </material-badge>

              <material-badge
                v-for="item in vatRates"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                {{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}
                <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="deleteVatRate(item.id)">close</i>
              </material-badge>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Wróć
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Nowe domyślne pola -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowa domyślne pole</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0 mb-0">
                  Nazwa pola
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <div class="input-group input-group-static">
                  <input
                    v-model="defaultInput.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center text-sm">
              <material-checkbox class="me-1" :checked="defaultInput.isRequired" @change="defaultInput.isRequired = !defaultInput.isRequired" />
              Czy pole ma być wymagane?
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postDefaultInput()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me && app && app.length !== 0" class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#logotype" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">image</i>
                <span class="text-sm">Logotyp</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#rate" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">payments</i>
                <span class="text-sm">Stawka</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#inputs" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">edit</i>
                <span class="text-sm">Domyślne pola kontrahenta</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[0].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[0].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[1].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[1].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[2].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[2].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[3].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[3].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[4].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[4].value"
                    v-mask="'##-###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ error }}</p>
          </div>
        </div>

        <!-- Logotyp -->
        <div id="logotype" class="card mb-4">
          <div class="card-header d-flex align-items-center pb-1">
            <h5>Logotyp</h5>
            <label class="d-flex align-items-center w-100 font-weight-bold cursor-pointer" :class="`text-${ color }`">
              <i class="material-symbols-outlined text-lg me-1">upload</i>
              Załącz plik
              <input type="file" class="d-none" accept="image/jpeg, image/png" @change="createAttachment($event)">
            </label>
          </div>
          <div class="card-body py-1" :class="app[7].value || attachment ? 'mb-4' : ''">
            <div class="row">
              <div class="col-12">
                <img v-if="attachmentBlob" :src="attachmentBlob" width="256">
                <img v-else :src="'data:image/jpeg;base64,' + app[7].value" width="256">
              </div>
            </div>
          </div>
        </div>

        <!-- Stawka -->
        <div id="rate" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Stawka</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[5].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[5].value"
                    type="number"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[6].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                    <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="openModal(0); vat = ''">add_circle</i>
                    <i class="material-symbols-outlined text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="openModal(1)">edit</i>
                  </label>
                  <select
                    v-model="app[6].value"
                    class="w-100 px-0 py-1 pb-2 text-sm"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="padding-bottom: 12px !important"
                    :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  >
                    <option value="0">0%</option>
                    <option value="5">5%</option>
                    <option value="8">8%</option>
                    <option value="23">23%</option>
                    <option v-for="item in vatRates" :key="item" :value="item.value">{{ isNaN(parseInt(item.value, 10)) ? item.value : `${ item.value }%` }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Domyślne pola kontrahenta -->
        <div id="inputs" class="card mb-4">
          <div class="card-header pb-0">
            <h5>Domyślne pola kontrahenta</h5>
            <p class="mb-0 text-sm">
              Zdefiniuj dodatkowe pola, które będą pokazywać się przy dodawaniu lub edycji kontrahentów.
            </p>
          </div>
          <div class="card-body pt-0">
            <div v-if="defaultInputs.length === 0" class="d-flex align-items-center my-3 text-sm">
              Brak domyślnych pól kontrahenta
            </div>

            <div v-else class="d-flex align-items-center my-3" style="flex-wrap: wrap">
              <material-badge
                v-for="item in defaultInputs"
                :key="item"
                color="success"
                size="sm"
                class="d-flex align-items-center me-1 mb-1"
              >
                <i v-if="item.isRequired" class="material-symbols-outlined me-1 text-xs font-weight-bold">star</i>
                {{ item.name }}
                <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="deleteDefaultInput(item.id)">close</i>
              </material-badge>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center text-sm cursor-pointer" :class="`text-${ color }`" @click="getNewDefaultInput()">
                  <i class="material-icons text-md me-1">add</i>
                  Dodaj nowe pole
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Zapisz
          </material-button>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import VatRateDataService from "@/services/VatRateDataService"
import DefaultInputDataService from "@/services/DefaultInputDataService"
import { mapState } from "vuex"

export default {
  name: "AppSettings",
  components: {
    MaterialBadge,
    MaterialCheckbox,
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      app: null, // Obiekt aplikacji

      attachment: null, // Obiekt załącznika
      attachmentBlob: null, // Obiekt załącznika

      vatRates: [], // Tablica customowych stawek VAT
      vat: "", // Zmienna odpowiadająca za stawkę VAT

      defaultInputs: [], // Tablica customowych pól
      defaultInput: null, // Obiekt domyślnego pola

      modals: [], // Tablica odpowiedzialna za status modali

      error: "", // Zmienna przechowująca treść błędu

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_APP_SETTINGS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getApp()
          this.getVatRates()
          this.getDefaultInputs()
        }
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca objekt aplikacji z API
    getApp() {
      AppSettingsDataService.getAll("")
      .then(res => {
        this.app = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      this.app.forEach(item => {
        if (item.name !== "company_logotype" && item.value === "") {
          success = false
          this.error = "Pola nie mogą być puste!"
        }
      })

      if (success) {
        this.patchApp()
      }
    },

    // Funkcja aktualizująca aplikację w API
    patchApp() {
      this.app.forEach(item => {
        item.value = `${ item.value }`
        AppSettingsDataService.patch(item.id, item,
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)

          this.snackbar = "success"

          if (item.name === "company_logotype" && this.attachment) {
            this.patchAttachment(item.id)
          }
        })
        .catch(error => {
          console.log(error)
        })
      })
    },

    patchAttachment(id) {
      const formData = new FormData()
      formData.append("file", this.attachment)

      AppSettingsDataService.patch_file_upload(id, formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)

        if (error.response.status == 500) {
          // this.$router.go(0)
        }
      })
    },

    // Funkcja dodająca plik do zmiennej
    createAttachment(event) {
      this.attachment = event.target.files[0]
      if (this.attachment) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.attachmentBlob = e.target.result
        }
        reader.readAsDataURL(this.attachment)
      }
    },

    // Funkcja pobierająca wszystie customowe stawki VAT
    getVatRates() {
      VatRateDataService.getAll("")
      .then(res => {
        this.vatRates = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nową stawkę VAT
    postVatRate() {
      VatRateDataService.post(
        {
          value: this.vat
        }
      )
      .then(res => {
        console.log(res.data)
        this.getVatRates()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybraną stawkę
    deleteVatRate(id) {
      VatRateDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getVatRates()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystie customowe pola
    getDefaultInputs() {
      DefaultInputDataService.getAll("")
      .then(res => {
        this.defaultInputs = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowy obiekt pola
    getNewDefaultInput() {
      this.defaultInput = null
      this.defaultInput = {
        name: "",
        isRequired: false,
      }

      this.openModal(2)
    },

    // Funkcja tworząca nowe domyślmne pole w API
    postDefaultInput() {
      DefaultInputDataService.post(this.defaultInput)
      .then(res => {
        console.log(res.data)
        this.getDefaultInputs()
        this.closeModal()
        this.defaultInput = null
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane pole
    deleteDefaultInput(id) {
      DefaultInputDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getDefaultInputs()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}
</style>