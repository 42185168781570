<template>
  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex justify-content-end mb-4">
      <!-- Filtr: Data -->
      <material-button
        v-if="!$route.query.date"
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        Data
      </material-button>
      <ul v-if="!$route.query.date" class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li>
          <a class="dropdown-item border-radius-md cursor-pointer"
          ><input type="month" style="border: none; background: none; outline: none" @change="filter_fromDate = $event.target.value; onChangeFilterDate(moment(filter_fromDate).daysInMonth())"></a>
        </li>
        <li>
          <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_fromDate = moment(); onChangeFilterDate(7)"
          >Ostatnie 7 dni</a>
        </li>
        <li>
          <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_fromDate = moment(); onChangeFilterDate(30)"
          >Ostatnie 30 dni</a>
        </li>
        <li>
          <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_fromDate = moment(); onChangeFilterDate(365)"
          >Ostatni rok</a>
        </li>
      </ul>

      <!-- Filtr: Osoba odpowiedzialna -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        Osoba odpowiedzialna
      </material-button>
      <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
        <li v-for="item in users" :key="item">
          <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeFilterUser(item); getUser(me.id)"
          >{{ item }}</a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_members = []; getUser(me.id)"
          >Wyczyść filtr</a>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_members"
        :key="item"
        color="danger"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeFilterUser(item)">close</i>
      </material-badge>

      <material-badge
        color="info"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ moment(filter_fromDate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY") ? filter_date === 7 ? "Ostatnie 7 dni" : filter_date === 30 ? "Ostatnie 30 dni" : filter_date === 365 ? "Ostatni rok" : "" : moment(filter_fromDate).format("MMMM YYYY") }}
      </material-badge>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <!-- Oferty (ilość) -->
        <div v-if="offersCount.data.length !== 0" class="card mb-4">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">Oferty (ilość)</h6>
              <div class="d-flex align-items-center">
                <span class="text-xs mb-1 me-2">{{ moment(filter_fromDate).subtract(filter_date - 1, "days").format("DD/MM/YYYY") }} - {{ moment(filter_fromDate).format("DD/MM/YYYY") }}</span>
                <a
                  data-bs-toggle="dropdown"
                >
                  <i class="material-icons cursor-pointer">more_vert</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li class="cursor-pointer" @click="exportToExcel('offersCount', 'oferty-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">download</i> Excel
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToPdf('offersCount', 'oferty-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">picture_as_pdf</i> PDF
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToSvg('offersCount', 'oferty-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">image</i> SVG
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <default-line-chart
              id="offersCount"
              :key="offersCount.data"
              class="w-100"
              :chart="{
                labels: offersCount.labels,
                datasets: [
                  {
                    label: 'Ilość',
                    data: offersCount.data
                  }
                ]
              }"
            />
          </div>

          <div class="p-3 pt-0 text-xs">Łączna ilość ofert: {{ offersCount.sum }}</div>
        </div>

        <!-- Sprzedaż (ilość) -->
        <div v-if="salesCount.data.length !== 0" class="card mb-4">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Sprzedaż (ilość)</h6>
              <div class="d-flex align-items-center">
                <span v-for="item in filter_members" :key="item" class="text-xs mb-1 me-2">{{ item }}</span>
                <a
                  data-bs-toggle="dropdown"
                >
                  <i class="material-icons cursor-pointer">more_vert</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li class="cursor-pointer" @click="exportToExcel('salesCount', 'sprzedaz-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">download</i> Excel
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToPdf('salesCount', 'sprzedaz-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">picture_as_pdf</i> PDF
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToSvg('salesCount', 'sprzedaz-ilosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">image</i> SVG
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p class="m-0 mt-1 text-xs">
              <span>&nbsp;</span> 
            </p>
          </div>
          <div class="p-3 card-body">
            <horizontal-bar-chart
              id="salesCount"
              :key="salesCount.data"
              class="w-100"
              :chart="{
                labels: salesCount.labels,
                datasets: [
                  {
                    label: 'Ilość',
                    data: salesCount.data
                  }
                ]
              }"
            />
          </div>

          <div class="p-3 pt-0 text-xs">Łączna ilość sprzedaży: {{ salesCount.sum }}</div>
        </div>

        <!-- Wiadomości -->
        <div class="card mb-4">
          <div class="p-3 pb-1 card-header">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">Liczba wiadomości wysłanych</h6>
              <div class="d-flex align-items-center">
                <span v-for="item in filter_members" :key="item" class="text-xs ms-2">{{ item }}</span>
                <span v-if="filter_members.length > 0" class="mx-1">|</span>
                <span class="text-xs">{{ moment(filter_fromDate).subtract(filter_date - 1, "days").format("DD/MM/YYYY") }} - {{ moment(filter_fromDate).format("DD/MM/YYYY") }}</span>
              </div>
            </div>
          </div>
          <div class="p-3 pt-1 card-body">
            <div class="row">
              <div class="col-12 text-start d-flex">
                <h5 class="mb-0 font-weight-bolder">{{ sentMessages }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <!-- Oferty (wartość) -->
        <div v-if="offersPrice.data.length !== 0" class="card mb-4">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">Oferty (wartość)</h6>
              <div class="d-flex align-items-center">
                <span class="text-xs mb-1 me-2">{{ moment(filter_fromDate).subtract(filter_date - 1, "days").format("DD/MM/YYYY") }} - {{ moment(filter_fromDate).format("DD/MM/YYYY") }}</span>
                <a
                  data-bs-toggle="dropdown"
                >
                  <i class="material-icons cursor-pointer">more_vert</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li class="cursor-pointer" @click="exportToExcel('offersPrice', 'oferty-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">download</i> Excel
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToPdf('offersPrice', 'oferty-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">picture_as_pdf</i> PDF
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToSvg('offersPrice', 'oferty-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">image</i> SVG
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="p-3 card-body">
            <default-line-chart
              id="offersPrice"
              :key="offersPrice.data"
              class="w-100"
              :chart="{
                labels: offersPrice.labels,
                datasets: [
                  {
                    label: 'Wartość',
                    data: offersPrice.data
                  }
                ]
              }"
            />
          </div>

          <div class="p-3 pt-0 text-xs">Łączna wartość ofert: {{ offersPrice.sum.toFixed(2) }} PLN</div>
        </div>

        <!-- Sprzedaż (wartość) -->
        <div v-if="salesPrice.data.length !== 0" class="card mb-4">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Sprzedaż (wartość)</h6>
              <div class="d-flex align-items-center">
                <span v-for="item in filter_members" :key="item" class="text-xs mb-1 me-2">{{ item }}</span>
                <a
                  data-bs-toggle="dropdown"
                >
                  <i class="material-icons cursor-pointer">more_vert</i>
                </a>
                <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                  <li class="cursor-pointer" @click="exportToExcel('salesPrice', 'sprzedaz-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">download</i> Excel
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToPdf('salesPrice', 'sprzedaz-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">picture_as_pdf</i> PDF
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="cursor-pointer" @click="exportToSvg('salesPrice', 'sprzedaz-wartosc')">
                    <a class="px-2 dropdown-item border-radius-md">
                      <div class="mx-0 px-0 py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="my-auto text-sm font-weight-normal d-flex align-items-center" style="color: #344767 !important">
                            <i class="material-symbols-outlined text-lg me-2">image</i> SVG
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p class="m-0 mt-1 text-xs">
              <span class="cursor-pointer me-3" @click="salesPrice.currency = 'PLN'; getSalesPrice()">PLN</span> 
              <span class="cursor-pointer" @click="salesPrice.currency = '€'; getSalesPrice()">EUR</span> 
            </p>
          </div>
          <div class="p-3 card-body">
            <horizontal-bar-chart
              id="salesPrice"
              :key="salesPrice.data"
              class="w-100"
              :chart="{
                labels: salesPrice.labels,
                datasets: [
                  {
                    label: 'Wartość',
                    data: salesPrice.data
                  }
                ]
              }"
            />
          </div>

          <div class="p-3 pt-0 text-xs">Łączna wartość sprzedaży: {{ `${ salesPrice.sum.toFixed(2) } ${ salesPrice.currency }` }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import MessageDataService from "@/services/MessageDataService"
import OfferDataService from "@/services/OfferDataService"
import SaleStageDataService from "@/services/SaleStageDataService"
import SaleDataService from "@/services/SaleDataService"
import ReportDataService from "@/services/ReportDataService"
import DefaultLineChart from "./components/DefaultLineChart.vue"
import HorizontalBarChart from "./components/HorizontalBarChart.vue"
import { toSvg } from "html-to-image"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"

export default {
  name: "Reports",
  components: {
    MaterialBadge,
    MaterialButton,
    DefaultLineChart,
    HorizontalBarChart,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      sentMessages: 0, // Ilość wysłanych wiadomości w tym miesiącu

      offersCount: {
        labels: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
        data: [],
        sum: 0
      }, // Ilość ofert w każdym miesiącu

      offersPrice: {
        labels: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
        data: [],
        sum: 0
      }, // Suma wartości ofert w każdym miesiącu

      salesCount: {
        labels: [],
        data: [],
        sum: 0
      }, // Ilość sprzedaży o danym etapie w miesiącu

      salesPrice: {
        labels: [],
        data: [],
        sum: 0,
        currency: "PLN"
      }, // Suma wartości sprzedaży o danym etapie w miesiącu

      users: [], // Tablica zawierająca wszystkich użytkowników

      filter_date: 30, // Filtr: Data
      filter_fromDate: null, // Filtr: Od daty
      filter_members: [], // Filtr: Osoba odpowiedzialna
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    if (this.$route.query.date) {
      this.filter_fromDate = this.$route.query.date
      this.filter_date = this.moment(this.filter_fromDate).daysInMonth()
    }
    else {
      this.filter_fromDate = this.moment()
    }
    
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_REPORTS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getUsers()
          this.getSentMessages()
          this.getSaleStages()
          this.onChangeFilterDate(this.filter_date)
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników
    getUsers() {
      UserDataService.getAll("?deleted=false&order[fullName]=ASC")
      .then(res => {
        this.users = []
        res.data["hydra:member"].forEach(item => {
          this.users.push(item.fullName)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca ilość wysłanych wiadomości
    getSentMessages() {
      let filter = ""
      this.filter_members.forEach(item => {
        filter += `&sender.fullName[]=${ item }`
      })

      MessageDataService.getAll(`?createdAt[before]=${ this.moment(this.filter_fromDate).add(1, "days").format("YYYY-MM-DD") }&createdAt[after]=${ this.moment(this.filter_fromDate).subtract(this.filter_date, "days").format("YYYY-MM-DD") }${ filter }`)
      .then(res => {
        this.sentMessages = res.data["hydra:member"].length
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierajca ilość ofert w każdym miesiącu
    getOffersCount() {
      OfferDataService.getAll(`?createdAt[before]=${ this.moment(this.filter_fromDate).add(1, "days").format("YYYY-MM-DD") }&createdAt[after]=${ this.moment(this.filter_fromDate).subtract(this.filter_date, "days").format("YYYY-MM-DD") }`)
      .then(res => {
        this.offersCount.data = []
        this.offersCount.sum = 0

        for (let i = this.offersCount.labels.length - 1; i >= 0; i--) {
          let x = 0

          res.data["hydra:member"].forEach(item => {
            if (this.filter_date === 7) {
              if (this.moment(this.filter_fromDate).subtract(i, "days").format("YYYY-MM-DD") === this.moment(item.createdAt).format("YYYY-MM-DD")) {
                x += 1
              }
            }
            else if (this.filter_date === 31 || this.filter_date === 30 || this.filter_date === 29 || this.filter_date === 28) {
              if (this.moment(this.filter_fromDate).subtract(i, "days").format("YYYY-MM-DD") === this.moment(item.createdAt).format("YYYY-MM-DD")) {
                x += 1
              }
            }
            else {
              if (this.moment(this.filter_fromDate).subtract(i, "months").format("YYYY-MM") === this.moment(item.createdAt).format("YYYY-MM")) {
                x += 1
              }
            }
          })

          this.offersCount.sum += x
          this.offersCount.data.push(x)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierajca sumę wartości ofert w każdym miesiącu
    getOffersPrice() {
      OfferDataService.getAll(`?createdAt[before]=${ this.moment(this.filter_fromDate).add(1, "days").format("YYYY-MM-DD") }&createdAt[after]=${ this.moment(this.filter_fromDate).subtract(this.filter_date, "days").format("YYYY-MM-DD") }`)
      .then(res => {
        this.offersPrice.data = []
        this.offersPrice.sum = 0

        for (let i = this.offersPrice.labels.length - 1; i >= 0; i--) {
          let x = 0

          res.data["hydra:member"].forEach(item => {
            let discountServices = item.discountServices > 100 ? 100 : item.discountServices
            let discountProducts = item.discountProducts > 100 ? 100 : item.discountProducts
            let discountExternals = item.discountExternals > 100 ? 100 : item.discountExternals

            if (this.filter_date === 7) {
              if (this.moment(this.filter_fromDate).subtract(i, "days").format("YYYY-MM-DD") === this.moment(item.createdAt).format("YYYY-MM-DD")) {
                item.products.forEach(product => {
                  if (product.type === "service") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                  }

                  if (product.type === "product") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                  }

                  if (product.type === "external") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                  }
                })
              }
            }
            else if (this.filter_date === 31 || this.filter_date === 30 || this.filter_date === 29 || this.filter_date === 28) {
              if (this.moment(this.filter_fromDate).subtract(i, "days").format("YYYY-MM-DD") === this.moment(item.createdAt).format("YYYY-MM-DD")) {
                item.products.forEach(product => {
                  if (product.type === "service") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                  }

                  if (product.type === "product") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                  }

                  if (product.type === "external") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                  }
                })
              }
            }
            else {
              if (this.moment(this.filter_fromDate).subtract(i, "months").format("YYYY-MM") === this.moment(item.createdAt).format("YYYY-MM")) {
                item.products.forEach(product => {
                  if (product.type === "service") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
                  }

                  if (product.type === "product") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
                  }

                  if (product.type === "external") {
                    x += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                    this.offersPrice.sum += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
                  }
                })
              }
            }
          })

          this.offersPrice.data.push(x)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie etapy w sprzedaży
    getSaleStages() {
      SaleStageDataService.getAll("")
      .then(res => {
        this.salesCount.labels = []
        this.salesPrice.labels = []

        res.data["hydra:member"].forEach(item => {
          this.salesCount.labels.push(item.name)
          this.salesPrice.labels.push(item.name)
        })

        this.getSalesCount()
        this.getSalesPrice(this.salesPrice.currency)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca ilość sprzedaży o danym etapie
    getSalesCount() {
      let filter = ""
      this.filter_members.forEach((item, index) => {
        filter += index === 0 ? `?member.fullName[]=${ item }` : `&member.fullName[]=${ item }`
      })

      SaleDataService.getAll(filter)
      .then(res => {
        this.salesCount.data = []
        this.salesCount.sum = 0

        this.salesCount.labels.forEach(item => {
          let x = 0

          res.data["hydra:member"].forEach(sale => {
            if (item === sale.stage.name) {
              x += 1
            }
          })

          this.salesCount.sum += x
          this.salesCount.data.push(x)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca sumę wartości sprzedaży o danym etapie
    getSalesPrice() {
      let filter = ""
      this.filter_members.forEach(item => {
        filter += `&member.fullName[]=${ item }`
      })

      SaleDataService.getAll(`?currency=${ this.salesPrice.currency }${ filter }`)
      .then(res => {
        this.salesPrice.data = []
        this.salesPrice.sum = 0

        this.salesPrice.labels.forEach(item => {
          let x = 0

          res.data["hydra:member"].forEach(sale => {
            if (item === sale.stage.name) {
              x += sale.value
              this.salesPrice.sum += sale.value
            }
          })

          this.salesPrice.data.push(x)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja ustawiająca filtr daty
    onChangeFilterDate(x) {
      if (this.filter_fromDate.length === 7) {
        this.filter_fromDate = this.filter_fromDate + "-" + this.moment(this.filter_fromDate).daysInMonth()
      }

      this.filter_date = x

      this.offersCount.labels = []
      this.offersPrice.labels = []

      if (x === 7) {
        for (let i = 6; i >= 0; i--) {
          this.offersCount.labels.push(this.moment(this.filter_fromDate).subtract(i, "days").format("dd"))
          this.offersPrice.labels.push(this.moment(this.filter_fromDate).subtract(i, "days").format("dd"))
        }
      }
      else if (x === 31 || x === 30 || x === 29 || x === 28) {
        for (let i = x - 1; i >= 0; i--) {
          this.offersCount.labels.push(this.moment(this.filter_fromDate).subtract(i, "days").format("DD"))
          this.offersPrice.labels.push(this.moment(this.filter_fromDate).subtract(i, "days").format("DD"))
        }
      }
      else {
        for (let i = 11; i >= 0; i--) {
          this.offersCount.labels.push(this.moment(this.filter_fromDate).subtract(i, "months").format("MMM"))
          this.offersPrice.labels.push(this.moment(this.filter_fromDate).subtract(i, "months").format("MMM"))
        }
      }
      
      this.getOffersCount()
      this.getOffersPrice()
      this.getSentMessages()
    },

    // Funkcja ustawiająca filtr osoby odpowiedzialnej
    onChangeFilterUser(event) {
      let exist = false

      this.filter_members.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_members.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_members.push(event)
      }

      this.getUser(this.me.id)
    },

    // Funkcja exportująca wykres do Excel
    exportToExcel(id, name) {
      ReportDataService.report_xlsx(
        {
          name: id,
          fromDate: this.filter_fromDate,
          days: this.filter_date,
          members: this.filter_members,
          currency: this.salesPrice.currency
        }
      )
      .then(res => {
        console.log(res.data)
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${ name }.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja exportująca wykres do PDF
    exportToPdf(id, name) {
      const element = document.getElementById(id)
      const parameters = {
        margin: 5,
        filename: `${ name }.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).save()
    },

    // Funkcja exportująca wykres do SVG
    exportToSvg(id, name) {
      toSvg(document.getElementById(id), { quality: 1 })
      .then(res => {
        const link = document.createElement("a")
        link.download = `${ name }.svg`
        link.href = res
        link.click()
      })
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>