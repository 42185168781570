<template>
  <div class="d-flex text-sm ms-auto px-4 pb-3">
    <span class="me-3 cursor-pointer" :class="initialView === 'dayGridMonth' ? `text-${ color }` : isDarkMode ? 'text-white' : 'text-dark'" @click="onChangeView('dayGridMonth')">Miesiąc</span>|
    <span class="mx-3 cursor-pointer" :class="initialView === 'timeGridWeek' ? `text-${ color }` : isDarkMode ? 'text-white' : 'text-dark'" @click="onChangeView('timeGridWeek')">Tydzień</span>|
    <span class="ms-3 cursor-pointer" :class="initialView === 'timeGridDay' ? `text-${ color }` : isDarkMode ? 'text-white' : 'text-dark'" @click="onChangeView('timeGridDay')">Dzień</span>
  </div>

  <div class="text-center">
    <a class="d-inline-block mb-0" style="cursor: pointer" @click="prev()">
      <i class="material-icons text-sm fixed-plugin-button-nav cursor-pointer">
        arrow_back_ios
      </i>
    </a>

    <div v-if="initialView === 'dayGridMonth'" class="d-inline-block mb-0 p text-md widget-calendar-day text-center text-capitalize" style="width: 175px;">
      {{ moment(date).format("MMMM") }}
    </div>

    <div v-if="initialView === 'timeGridWeek'" class="d-inline-block mb-0 p text-md widget-calendar-day text-center" style="width: 175px;">
      {{ moment(date).format("DD") }} - {{ moment(date).add(7, "days").format("DD") }} <span class="text-capitalize">{{ moment(date).format("MMMM") }}</span>
    </div>

    <div v-if="initialView === 'timeGridDay'" class="d-inline-block mb-0 p text-md widget-calendar-day text-center" style="width: 175px;">
      <span class="text-capitalize">{{ moment(date).format("DD MMMM") }}</span>
    </div>

    <a class="d-inline-block mb-0 p" style="cursor: pointer" @click="next()">
      <i class="material-icons text-sm fixed-plugin-button-nav cursor-pointer">
        arrow_forward_ios
      </i>
    </a>
  </div>

  <div class="mb-0 p text-sm widget-calendar-year text-center">
    {{ moment(date).format("YYYY") }}
  </div>

  <div class="card-body">
    <div :id="id" data-toggle="widget-calendar"></div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import EventDataService from "@/services/EventDataService"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"
import $ from "jquery"

let calendar

export default {
  name: "Calendar",
  props: {
    id: {
      type: String,
      default: "widget-calendar"
    },
    events: {
      type: Array,
      required: false,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: this.moment().format("YYYY-MM-DD"), // Aktualnie pokazywana data
      initialView: "dayGridMonth", // Obecnie pokazywany widok
      initialDate: this.moment().format("YYYY-MM-DD"), // Obecnie pokazywany miesiąc
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    if (calendar) {
      this.date = this.getDate()
    }
  },
  beforeUnmount() {
    if (calendar) {
      calendar.destroy()
    }
  },
  methods: {
    // Funkcja aktualizująca wybrane wydarzenie
    editEvent(id, start, end) {
      EventDataService.patch(id,
        {
          id: id,
          startDate: this.moment(start).add(2, 'hours'),
          endDate: this.moment(end).add(2, 'hours')
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca widok kalendarza
    onChangeView(view) {
      this.initialView = view
      calendar.changeView(view)
    },

    renderEvents() {
      calendar.refetchEvents()
    },

    getView() {
      if (calendar) {
        return calendar.view.type
      }
      else {
        return "dayGridMonth"
      }
    },

    getDate() {
      if (calendar) {
        return calendar.getDate()
      }
      else {
        return this.moment().format("YYYY-MM-DD")
      }
    },

    next () {
      calendar.next()
      this.date = calendar.getDate()
    },

    prev () {
      calendar.prev()
      this.date = calendar.getDate()
    },

    unselect() {
      calendar.unselect()
    },

    print() {
      const element = document.getElementById("widget-calendar")

      element.style.width = "210mm";

      let headerDiv = document.createElement("span")

      if (this.getView() === "dayGridMonth") {
        headerDiv.innerText = this.moment(this.date).format("MMMM")
      }
      else if (this.getView() === "timeGridWeek") {
        headerDiv.innerText = this.moment(this.date).format("DD") + " - " + this.moment(this.date).add(7, "days").format("DD") + " " + this.moment(this.date).format("MMMM")
      }
      else if (this.getView() === "timeGridDay") {
        headerDiv.innerText = this.moment(this.date).format("DD MMMM")
      }

      headerDiv.style.fontSize = "24px"
      headerDiv.style.textAlign = "center"
      element.prepend(headerDiv)

      this.createCalendar("auto", true, this.getView(), this.getDate())

      const parameters = {
        margin: 0,
        filename: "kalendarz.pdf",
        html2canvas: { scale: 5 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).save()
      .then(() => {
        element.style.width = "auto";
        element.removeChild(headerDiv)
        this.createCalendar("650px", true, this.getView(), this.getDate())
      })
    },

    createCalendar(height, selectable, initialView, initialDate) {
      let calendarElement = document.getElementById(this.id)
      if (calendarElement) {
        calendar = new Calendar(calendarElement, {
          contentHeight: height,
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          firstDay: 1,
          initialView: initialView,
          selectable: selectable,
          editable: this.editable,
          events: this.events,
          initialDate: initialDate,
          eventDisplay: "block",
          headerToolbar: false,
          locale: "pl",
          select: function(info) {
            this.getNewEvent(this.moment(info.start).format("YYYY-MM-DD"), this.moment(info.end).format("YYYY-MM-DD"), true)
          }.bind(this),
          eventClick: function(info) {
            if (info.event.extendedProps.type) {
              if (info.event.extendedProps.type === "customer") {
                this.$store.state.openedCustomer = info.event.id
                this.$redirect("Kontrahenci")
                calendar = null
              }
              else if (info.event.extendedProps.type === "sale") {
                this.$store.state.openedSale = info.event.id
                this.$redirect("Sprzedaż")
                calendar = null
              }
              else if (info.event.extendedProps.type === "task") {
                this.$store.state.openedTask = info.event.id
                this.$redirect("Zadania")
                calendar = null
              }
              else {
                this.$store.state.openedEvent = info.event.id
                this.$redirect("Kalendarz")
                this.getEvent(info.event.id)
              }
            }
          }.bind(this),
          eventDidMount: function(info) {
            if (info.event.extendedProps.icon) {
              $(info.el).find(".fc-event-title").addClass("d-flex align-items-center py-0 my-0")
              if (info.event.extendedProps.icon === "done") {
                $(info.el).find(".fc-event-title").prepend("<i class='material-icons me-1 text-sm'>done</i>")
              }
              else {
                $(info.el).find(".fc-event-title").prepend("<b class='me-2 text-md'>&bull;</b>")
              }
            }
          }.bind(this),
          eventDrop: function(info) {
            if (info.event.end == null) {
              this.editEvent(info.event.id, info.event.start, info.event.start)
            }
            else {
              this.editEvent(info.event.id, info.event.start, info.event.end)
            }
          }.bind(this),
          eventResize: function(info) {
            if (info.event.end == null) {
              this.editEvent(info.event.id, info.event.start, info.event.start)
            }
            else {
              this.editEvent(info.event.id, info.event.start, info.event.end)
            }
          }.bind(this),
          eventTimeFormat: {
            hour: "numeric",
            minute: "2-digit",
            meridiem: false
          },
          views: {
            month: {
              titleFormat: {
                month: "long",
                year: "numeric",
              },
            },
            agendaWeek: {
              titleFormat: {
                month: "long",
                year: "numeric",
                day: "numeric",
              },
            },
            agendaDay: {
              titleFormat: {
                month: "short",
                year: "numeric",
                day: "numeric",
              },
            },
          },
        })
        
        calendar.render()
      }
    }
  }
}
</script>

<style scoped>
.fc .fc-button-primary {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}

.fc .fc-button-primary:hover {
  background: #4caf50 !important;
  border-color: #4caf50 !important;
}
</style>