import http from "@/http-common"

class TemplateDataService {
    getAll(url) {
        return http.get(`templates${ url }`)
    }

    get(id) {
        return http.get(`templates/${ id }`)
    }

    post(data) {
        return http.post('templates', data)
    }

    patch(id, data, headers) {
        return http.patch(`templates/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`templates/${ id }`)
    }
}

export default new TemplateDataService()