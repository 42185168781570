import http from "@/http-common"

class SaleStageDataService {
    getAll(url) {
        return http.get(`sale_stages${ url }`)
    }

    get(id) {
        return http.get(`sale_stages/${ id }`)
    }

    post(data) {
        return http.post('sale_stages', data)
    }

    patch(id, data, headers) {
        return http.patch(`sale_stages/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`sale_stages/${ id }`)
    }
}

export default new SaleStageDataService()