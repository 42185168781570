<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
              >
                <div
                  class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  style="background-size: cover; background-repeat: no-repeat; background-position: center;"
                  :style="{ backgroundImage: 'url(' + require('@/assets/img/illustrations/illustration.jpg') + ')' }"
                ></div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-center bg-transparent mb-4">
                    <img src="@/assets/img/logo.png" width="225">
                  </div>
                  <div class="card-body">
                    <p v-if="error !== ''" class="w-100 text-center text-sm font-weight-bold text-danger">{{ error }}</p>

                    <div class="input-group input-group-outline mb-3">
                      <label class="d-flex mb-1">
                        Adres e-mail
                      </label>
                      <input
                        v-model="email"
                        class="w-100 form-control"
                      />
                    </div>

                    <div class="input-group input-group-outline mb-4">
                      <label class="d-flex mb-1">
                        Hasło
                      </label>
                      <input
                        v-model="password"
                        type="password"
                        class="w-100 form-control"
                      />
                    </div>

                    <material-switch class="mb-3">
                      Zapamiętaj mnie
                    </material-switch>

                    <material-button
                      class="mb-4"
                      variant="gradient"
                      color="primary"
                      full-width
                      size="lg"
                      @click="validation()"
                    >
                      Zaloguj się
                    </material-button>

                    <p class="w-100 text-center text-sm font-weight-bold text-dark cursor-pointer" @click="$redirect('Resetowanie hasła')">Nie pamiętasz hasła?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import LoginDataService from "@/services/LoginDataService"
import { mapMutations } from "vuex"

const body = document.getElementsByTagName("body")[0]

export default {
  name: "SignIn",
  components: {
    MaterialSwitch,
    MaterialButton,
  },
  data() {
    return {
      user: null, // Objekt obecnie zalogowanego użytkownika

      email: "", // Zmienna przechowująca wpisany email
      password: "", // Zmienna przechowująca wpisane hasło

      error: "", // Zmienna odpowiedzialna za treść błędu
    }
  },
  beforeMount() {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
    body.classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
    body.classList.add("bg-gray-100")
  },
  created() {
    // Jeśli token jest ustawiony to wyrzuca z tej strony to Kokpitu
    if(localStorage.getItem("token")) {
      this.$router.push({ name: "Kokpit" })
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    // Funkcja sprawdzająca poprawność danych
    validation() {
      let success = true
      this.error = ""

      if (this.email === "" || this.password === "") {
        success = false
        this.error = "Niepoprawny adres e-mail lub hasło!"
      }

      if (success) {
        this.postLogin()
      }
    },

    // Funkcja tworząca token na podstawie wczytanych danych
    postLogin() {
      LoginDataService.login(
        {
          username: this.email,
          password: this.password
        }
      )
      .then(res => {
        localStorage.setItem("token", res.data.token)
        this.getMe()
      })
      .catch(error => {
        console.log(error)
        this.error = "Niepoprawny adres e-mail lub hasło!"
      })
    },

    // Funkcja wczytująca użytkownika na podstawie tokenu
    getMe() {
      LoginDataService.me(
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        this.user = res.data
        
        if (res.data.deleted) {
          localStorage.removeItem("token")
          this.error = "Konto jest usunięte!"
        }
        else {
          if (res.data.status === "Dezaktywowany") {
            localStorage.removeItem("token")
            this.error = "Konto jest dezaktywowane!"
          }
          else {
            if (this.$route.query.asom == "1") {
              let route = this.$router.resolve({ path: "/home/messages", query: { asom: 1 } })
              window.location.href = route.href
            } else {
              this.$router.go("/home/dashboard")
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>