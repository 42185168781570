<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedGroups.length === 1">Czy na pewno chcesz usunąć {{ checkedGroups.length }} grupę?</div>
            <div v-if="checkedGroups.length > 1 && checkedGroups.length < 5">Czy na pewno chcesz usunąć {{ checkedGroups.length }} grupy?</div>
            <div v-if="checkedGroups.length > 4">Czy na pewno chcesz usunąć {{ checkedGroups.length }} grup?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteGroups(); unselectGroups(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <div class="ms-auto">
        <!-- Przycisk: Nowa grupa -->
        <material-button
          :color="color"
          variant="gradient"
          @click="$redirect('Nowa grupa')"
        >
          + &nbsp; Nowa grupa
        </material-button>
      </div>
    </div>

    <!-- Tabela: Grupy -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Grupy</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_name"
                      v-model="filter_name"
                      :items="groupsAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    >
                    </SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
              
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="selectGroups($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 70%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa grupy</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('members')">Ilość członków</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <i
                            v-if="checkedGroups.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(0)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <!-- Grupy -->
                      <tr v-for="item in groups" :key="item">
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="selectGroup($event, item)" />
                        </td>

                        <!-- Tabela: Nazwa grupy -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd grupy', item.id)">
                          {{ item.name }}
                        </td>

                        <!-- Tabela: Ilość członków -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd grupy', item.id)">
                          {{ item.members ? item.members.length : 0 }}
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="$redirectID('Podgląd grupy', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$redirectID('Edycja grupy', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unselectGroups(); item.mark = true; checkedGroups.push(item); openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak grup -->
                      <tr :class="groups.length > 0 ? 'd-none' : null">
                        <td class="dataTables-empty text-sm" colspan="3">Nie znaleziono grup</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="groups.length !== 0" class="dataTable-bottom">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Groups",
  components: {
    MaterialCheckbox,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      groups: [], // Tablica zawierająca dane grup z API
      groupsAC: [], // Tablica zawierająca wszystkie nazwy grup z API (AutoComplete)

      checkedGroups: [], // Tablica zawierająca wszystkie zaznaczone grupy
      modals: [], // Tablica odpowiedzialna za status modali

      column: "name", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_name: "", // Filtr: Nazwa grupy
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getGroupsDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_GROUPS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getGroups(this.page)
          this.getGroupsAC()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane grup z API
    getGroups(page) {
      UserGroupDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.groups = res.data["hydra:member"]
        this.unselectGroups()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane grup z API z opóźnieniem
    getGroupsDelay: _.debounce(function(page) {
      UserGroupDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.groups = res.data["hydra:member"]
        this.unselectGroups()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja wczytuje wszystkie grupy z API
    getGroupsAC() {
      UserGroupDataService.getAll("")
      .then(res => {
        this.groupsAC = []
        res.data["hydra:member"].forEach(item => {
          this.groupsAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca zaznaczaną grupę do listy
    selectGroup(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedGroups.push(item)
      }
      else {
        this.checkedGroups.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedGroups.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie grupy
    selectGroups(e) {
      this.checkedGroups = []
      if(e.target.checked) {
        this.groups.forEach(item => {
          item.mark = true
          this.checkedGroups.push(item)
        })
      }
      else {
        this.groups.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie grupy
    unselectGroups() {
      this.checkedGroups = []
      this.groups.forEach(item => {
        item.mark = false
      })
    },

    // Funckja usuwająca wiele grup
    deleteGroups() {
      this.checkedGroups.forEach(item => {
        UserGroupDataService.delete(item.id)
        .then(res => {
          console.log(res.data)
          this.getGroups(this.page)
          this.getGroupsAC()
        })
        .catch(error => {
          console.log(error)
        })
      })
    },

    // Funkcja przypisująca wybraną nazwę grupy do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getGroups(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getGroups(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getGroups(page)
      }
      else {
        this.getGroups(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getGroups(page)
      }
      else {
        this.getGroups(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder == "asc") {
        this.columnOrder = "desc"
        this.getGroups(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getGroups(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>