<!--
=========================================================
* Vue Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <!-- Sidenav -->
  <sidenav
    v-if="showSidenav"
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
    style="min-height: 100vh;"
  >
    <!-- Navbar -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />

    <!-- Main -->
    <router-view />

    <!-- Footer -->
    <app-footer v-show="showFooter" />
  </main>
</template>

<script>
import Sidenav from "@/examples/Sidenav"
import Navbar from "@/examples/Navbar.vue"
import AppFooter from "@/examples/Footer.vue"
import UserDataService from "@/services/UserDataService"
import { mapMutations, mapState, mapActions } from "vuex"
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode"

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "sidebarType",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  created() {
    // Jeśli token nie jest ustawiony to wyrzuca z tej strony do panelu logowania
    if(!localStorage.getItem("token")) {
      if (window.location.search.includes('?asom=1')) {
        this.$router.push({ name: "Login", query: { asom: 1 } })
      }
      else {
        this.$router.push({ name: "Login" })
      }
    }
    else {
      this.getMe()
    }
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0]
    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned")
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    ...mapActions(["setColor"]),

    getMe() {
      UserDataService.me()
      .then(res => {
        this.getSettings(res.data.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    getSettings(uid) {
      UserDataService.get(uid)
      .then(res => {
        // Konfiguracja: Sidebar color
        document.querySelector("#sidenav-main").setAttribute("data-color", res.data.settings.sidebarColor)
        this.setColor(res.data.settings.sidebarColor)

        // Konfiguracja: Sidebar type
        this.$store.state.sidebarType = res.data.settings.sidebarType
        
        // Konfiguracja: Dark Mode
        if (!res.data.settings.darkMode) {
          this.$store.state.isDarkMode = res.data.settings.darkMode
          deactivateDarkMode()
          return
        } else {
          this.$store.state.isDarkMode = res.data.settings.darkMode
          activateDarkMode()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #66bb6a 0%,
    #43a047 100%
  ) !important;
}
</style>