<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Zmiana etapu -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Zmiana etapu</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <p class="d-flex align-items-center m-0 mb-3 text-sm">
              Etap: <span class="ms-2 font-weight-bold">{{ stageHistory.name }}</span>
            </p>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data rozpoczęcia etapu
                </label>
                <input
                  v-model="stageHistory.createdAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="stageHistory.closedAtStatus" class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data zakończenia etapu
                </label>
                <input
                  v-model="stageHistory.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="stageHistory.calendarStatus" class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Kalendarz etapu</label>
                <select
                  v-model="stageHistory.calendar"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in calendars" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="onChangeSaleStages(); unselectSales(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Nowy system -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowa grupa</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="system.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <p v-if="system.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ system.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postSaleSystem()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedSales.length === 1">Czy na pewno chcesz usunąć {{ checkedSales.length }} sprzedaż?</div>
            <div v-if="checkedSales.length > 1 && checkedSales.length < 5">Czy na pewno chcesz usunąć {{ checkedSales.length }} sprzedaże?</div>
            <div v-if="checkedSales.length > 4">Czy na pewno chcesz usunąć {{ checkedSales.length }} sprzedaży?</div>
           
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteSales(); unselectSales(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Nie można usunąć -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nie można usunąć sprzedaży</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Nie można usunąć sprzedaży, ponieważ posiada powiązane relacje!

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="closeModal()"
              >
                OK
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nowy kontrahent -->
    <div v-if="modals[4]" class="modal-dialog">
      <div class="modal-content">
        <div class="card p-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowy kontrahent</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 pb-3 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-2">
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Company'"
                    @change="createdCustomer.type = 'Company'"
                  />
                  <span class="text-sm mt-1">Przedsiębiorstwo</span>
                </label>
              </div>
              <div class="col-md-6">
                <label class="d-flex text-md">
                  <material-radio
                    name="type"
                    class="ps-0 me-1"
                    :checked="createdCustomer.type === 'Person'"
                    @change="createdCustomer.type = 'Person'"
                  />
                  <span class="text-sm mt-1">Osoba fizyczna</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ createdCustomer.type === "Company" ? 'Nazwa wyświetlana' : 'Imię i nazwisko' }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="createdCustomer.displayName"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center form-label ms-0">
                  Grupy <i class="material-icons text-warning text-sm ms-1">star</i>
                </label>
                <label v-for="item in createdCustomer.groups" :key="item" class="d-flex align-items-center mx-0">
                  <material-checkbox class="me-1" :checked="item.status" @change="item.status = !item.status" /> {{ item.name }}
                </label>
              </div>
            </div>

            <p v-if="createdCustomer.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ createdCustomer.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postNewCustomer()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex justify-content-between mb-4">
      <!-- Export -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        + &nbsp; PDF
      </material-button>
      <ul class="dropdown-menu px-2 shadow">
        <!-- Export -->
        <li>
          <a class="dropdown-item border-radius-md d-flex align-items-center ps-2 cursor-pointer" @click="exportSales()">
            <i class="material-symbols-outlined me-2">file_download</i> Export
          </a>
        </li>
      </ul>

      <div class="d-flex align-items-center">
        <!-- Filtr: Osoba odpowiedzialna -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Osoba odpowiedzialna
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li v-for="item in members" :key="item" class="d-flex">
            <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeMember(item.fullName)">
              {{ item.fullName }}
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_members = []; getSales(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Kontrahent -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Kontrahent
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li v-for="item in customersFilter" :key="item" class="d-flex">
            <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeCustomer(item.displayName)">
              {{ item.displayName }}
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_customers = []; getSales(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: System -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Grupa
        </material-button>
        <ul class="dropdown-menu px-2 shadow" style="max-height: 400px; overflow-y: auto;">
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="getNewSaleSystem()"
            >Nowa grupa</a>
          </li>
          <li v-if="systems.length !== 0">
            <hr class="horizontal bg-light my-2">
          </li>
          <li v-for="item in systems" :key="item" class="d-flex align-items-center">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="onChangeSystem(item.name)">
              {{ item.name }}
            </a>
            <a class="dropdown-item border-radius-md cursor-pointer" style="width: 25%" @click="deleteSaleSystem(item.id)">
              <i class="material-symbols-outlined text-lg d-flex align-items-center justify-content-center">delete</i>
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_systems = []; getSales(page)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Przycisk: Nowa sprzedaż -->
        <material-button
          :color="color"
          variant="gradient"
          @click="sale && sale.operation === 'new' ? sale = null : getNewSale()"
        >
          + &nbsp; Nowa sprzedaż
        </material-button>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-4">
      <material-badge
        v-for="item in filter_members"
        :key="item"
        color="danger"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeMember(item)">close</i>
      </material-badge>

      <material-badge
        v-for="item in filter_customers"
        :key="item"
        color="warning"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeCustomer(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in filter_systems"
        :key="item"
        color="success"
        size="sm"
        class="d-flex align-items-center ms-2"
      >
        {{ item }}
        <i class="material-symbols-outlined ms-1 text-xs font-weight-bold cursor-pointer" @click="onChangeSystem(item)">close</i>
      </material-badge>
      
      <material-badge
        v-for="item in stages"
        :key="item"
        :color="filter_stages.includes(item.name) ? 'info' : 'light'"
        size="sm"
        class="d-flex align-items-center ms-2 cursor-pointer"
        @click="onChangeStage(item.name)"
      >
        {{ item.name }}
      </material-badge>
    </div>

    <div class="row">
      <div class="mb-4" :class="sale ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Sprzedaż</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_name"
                      v-model="filter_name"
                      :items="salesAC"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table id="sales-pdf" class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="selectSales($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" @click="onOrder('customer.displayName')">Kontrahent</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" @click="onOrder('value')">Wartość</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('stage.name')">Etap</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" @click="onOrder('closedAt')">Termin</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 25%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('member.fullName')">Osoba odpowiedzialna</a>
                        </th>
                        
                        <th class="my-0 py-0 text-end" style="width: 5%">
                          <div v-if="checkedSales.length > 0" class="d-flex justify-content-end">
                            <a
                              :class="[color]"
                              data-bs-toggle="dropdown"
                            >
                              <i class="material-symbols-outlined h5 mt-2 cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">sync</i>
                            </a>
                            <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                              <li v-for="itemStage in stages" :key="itemStage" class="cursor-pointer" @click="getSaleStageHistory(itemStage.id)">
                                <a class="px-2 dropdown-item border-radius-md">
                                  <div class="mx-0 px-0 py-1 d-flex">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="my-auto text-sm font-weight-normal text-capitalize" style="color: #344767 !important">
                                        {{ itemStage.name }}
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>

                            <i
                              class="material-symbols-outlined h5 mt-2 cursor-pointer ms-3"
                              :class="isDarkMode ? 'text-white' : 'text-dark'"
                              @click="openModal(2)"
                            >
                              delete
                            </i>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in sales"
                        :key="item"
                        :class="sale && sale.id === item.id ? isDarkMode ? 'bg-dark' : 'bg-light' : null"
                      >
                        <!-- Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="selectSale($event, item)" />
                        </td>

                        <!-- Kontrahent / Nazwa -->
                        <td class="ps-0 cursor-pointer" colspan="2" @click="sale && sale.id === item.id ? sale = null : getSale(item.id)">
                          <p class="m-0 mb-1 text-xs">{{ item.customer ? item.customer.displayName : "Brak" }}</p>
                          <p class="m-0 text-xs font-weight-bold">{{ item.name }}</p>
                        </td>

                        <!-- Wartość / Etap -->
                        <td class="ps-0 cursor-pointer" colspan="2" @click="sale && sale.id === item.id ? sale = null : getSale(item.id)">
                          <p class="m-0 mb-1 text-xs">{{ item.value.toFixed(2) + " " + item.currency }}</p>
                          <p class="m-0 text-xs">{{ item.stage ? item.stage.name : "Brak" }}</p>
                        </td>

                        <!-- Termin / Osoba odpowiedzialna -->
                        <td class="ps-0 cursor-pointer" colspan="2" @click="sale && sale.id === item.id ? sale = null : getSale(item.id)">
                          <p class="m-0 mb-1 text-xs">{{ moment(item.closedAt).format("DD/MM/YYYY") }}</p>
                          <p class="m-0 text-xs">{{ item.member ? item.member.fullName : "Brak" }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getSale(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getSale(item.id, 'edit')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="$event.stopPropagation()">
                              <a
                                class="px-2 dropdown-item border-radius-md"
                                :class="[color]"
                                data-bs-toggle="dropdown"
                              >
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Zmień etap...
                                    </h6>
                                  </div>
                                </div>
                              </a>
                              <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                                <li v-for="itemStage in stages" :key="itemStage" class="cursor-pointer" @click="unselectSales(); item.mark = true; checkedSales.push(item); getSaleStageHistory(itemStage.id)">
                                  <a class="px-2 dropdown-item border-radius-md">
                                    <div class="mx-0 px-0 py-1 d-flex">
                                      <div class="d-flex flex-column justify-content-center">
                                        <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                          {{ itemStage.name }}
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="$store.state.createdEvent.operation = true; $store.state.createdEvent.sale = { id: item.id, name: item.name }; $store.state.createdEvent.attachments = []; $redirect('Kalendarz')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dodaj nowe wydarzenie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$store.state.createdTask.operation = true; $store.state.createdTask.sale = { id: item.id, name: item.name }; $store.state.createdTask.attachments = []; $redirect('Zadania')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important"> 
                                      Dodaj nowe zadanie
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="unselectSales(); item.mark = true; checkedSales.push(item); openModal(2)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak sprzedaży -->
                      <tr :class="sales.length > 0 ? 'd-none': ''">
                        <td class="dataTables-empty text-sm" colspan="8">Nie znaleziono sprzedaży</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Podsumowanie -->
                <div v-if="sales.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown text-xs">
                    <span class="me-3">Ilość sprzedaży: {{ sales.length }}</span>
                    Wartość sprzedaży: {{ totalPLN.toFixed(2) + ` PLN` + ` / ` + totalEUR.toFixed(2) + ` €` }}
                  </div>
                  <div class="dataTable-pagination"></div>
                </div>

                <!-- Paginacja -->
                <div v-if="sales.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>
                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Nowa oferta -->
      <div v-if="sale && sale.operation === 'new'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div v-if="sale.task" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane zadanie: <span class="font-weight-normal ms-2">{{ sale.task.name }}</span>
                </label>
              </div>
            </div>

            <div v-if="sale.event" class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane wydarzenie: <span class="font-weight-normal ms-2">{{ sale.event.title }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa sprzedaży
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="sale.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-10">
                <div class="input-group input-group-static">
                  <label>Wartość</label>
                  <input
                    v-model="sale.value"
                    v-mask="'#######'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-4">
                <select
                  v-model="sale.currency"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="padding-bottom: 12px !important"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="PLN">PLN</option>
                  <option value="€">€</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Etap
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="sale.stage"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="getNewSaleStageHistory($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in stages" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div v-if="sale.stageHistory" class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data rozpoczęcia etapu
                </label>
                <input
                  v-model="sale.stageHistory.createdAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="sale.stageHistory && sale.stageHistory.closedAtStatus" class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data zakończenia etapu
                </label>
                <input
                  v-model="sale.stageHistory.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="sale.stageHistory && sale.stageHistory.calendarStatus" class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Kalendarz etapu</label>
                <select
                  v-model="sale.stageHistory.calendar"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in calendars" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Grupa</label>
                <select
                  v-model="sale.system"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in systems" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data zakończenia
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <input
                  v-model="sale.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Powiadom</label>
                <select
                  v-model="sale.reminder"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Brak</option>
                  <option value="1 day">1 dzień przed</option>
                  <option value="2 days">2 dni przed</option>
                  <option value="5 days">5 dni przed</option>
                  <option value="14 days">14 dni przed</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label mb-0 ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>

                <SimpleTypeahead
                  ref="usersRef"
                  :items="usersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectUser"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="sale.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ sale.member.fullName }}
                </material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label mb-0 ms-0">
                  Kontrahent
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                  <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="getNewCustomer()">add_circle</i>
                </label>

                <SimpleTypeahead
                  ref="customersRef"
                  :items="customersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectCustomer"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="sale.customer"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ sale.customer.displayName }}
                </material-badge>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="sale.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>

            <div v-for="(item, index) in sale.inputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="sale.inputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="sale.inputs.push({ name: '', value: '', type: 'text' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in sale.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="text-sm me-2">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="sale.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(sale.attachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostSale()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="sale = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja oferty -->
      <div v-if="sale && sale.operation === 'edit'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa sprzedaży
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="sale.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-10">
                <div class="input-group input-group-static">
                  <label>Wartość</label>
                  <input
                    v-model="sale.value"
                    v-mask="'#######'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-4">
                <select
                  v-model="sale.currency"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="padding-bottom: 12px !important"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="PLN">PLN</option>
                  <option value="€">€</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Etap
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <select
                  v-model="sale.stage"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="getNewSaleStageHistory($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in stages" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div v-if="sale.stageHistory" class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data rozpoczęcia etapu
                </label>
                <input
                  v-model="sale.stageHistory.createdAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="sale.stageHistory && sale.stageHistory.closedAtStatus" class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data zakończenia etapu
                </label>
                <input
                  v-model="sale.stageHistory.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div v-if="sale.stageHistory && sale.stageHistory.calendarStatus" class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Kalendarz etapu</label>
                <select
                  v-model="sale.stageHistory.calendar"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in calendars" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Grupa</label>
                <select
                  v-model="sale.system"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in systems" :key="item" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label ms-0">
                  Data zakończenia
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
                <input
                  v-model="sale.closedAt"
                  type="date"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0">Powiadom</label>
                <select
                  v-model="sale.reminder"
                  class="w-100 px-0 py-1 pb-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Brak</option>
                  <option value="1 day">1 dzień przed</option>
                  <option value="2 days">2 dni przed</option>
                  <option value="5 days">5 dni przed</option>
                  <option value="14 days">14 dni przed</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label mb-0 ms-0">
                  Osoba odpowiedzialna
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>

                <SimpleTypeahead
                  ref="usersRef"
                  :items="usersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectUser"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="sale.member"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ sale.member.fullName }}
                </material-badge>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex form-label mb-0 ms-0">
                  Kontrahent
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                  <i class="material-icons text-sm ms-1 cursor-pointer" :class="`text-${ color }`" @click="getNewCustomer()">add_circle</i>
                </label>

                <SimpleTypeahead
                  ref="customersRef"
                  :items="customersAC"
                  :min-input-length="1"
                  class="w-100 text-sm pb-2 border-secondary"
                  style="background: none; border: 0; border-bottom: 1px solid white; outline: none"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectCustomer"
                ></SimpleTypeahead>
              </div>

              <div class="d-flex mt-2">
                <material-badge
                  v-if="sale.customer"
                  color="success"
                  size="sm"
                  class="d-flex align-items-center"
                >
                  {{ sale.customer.displayName }}
                </material-badge>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Pozostałe</h5>

            <div class="row mb-3">
              <div class="col-12 text-sm">
                <label class="mb-2">
                  Notatka <span class="text-xxs">(limit znaków: 5000)</span>
                </label>
                <div class="input-group input-group-outline" >
                  <textarea
                    v-model="sale.note"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-black'"
                    style="height: 250px; resize: none"
                    rows="5"
                    maxlength="5000"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-2 text-sm">
                <label class="m-0">Pola <span class="text-xxs">(limit znaków: 255)</span></label>
              </div>
            </div>

            <div v-for="(item, index) in sale.inputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="sale.removedInputs.push(item.id); sale.inputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div v-for="(item, index) in sale.newInputs" :key="item" class="row mb-3">
              <div style="width: 30%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div style="width: 60%">
                <div class="input-group input-group-static">
                  <input
                    v-model="item.value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                  />
                </div>
              </div>

              <div class="text-end my-auto" style="width: 10%">
                <i class="material-icons text-danger cursor-pointer" @click="sale.newInputs.splice(index, 1)">cancel</i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer" @click="sale.newInputs.push({ name: '', value: '', type: 'text' })">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj nowe pole</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-2">Załączniki</h5>

            <div v-for="(item, index) in sale.attachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">{{ item.fileName }}</a> ({{ item.fileSize }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="sale.removedAttachments.push(item.id); sale.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in sale.newAttachments" :key="item" class="mb-3">
              <div class="row">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i> <span class="me-2 text-sm">{{ item.file.name }}</span> ({{ item.file.size }} KB)
                  <i class="material-icons ms-auto my-auto text-danger cursor-pointer" @click="sale.newAttachments.splice(index, 1)">cancel</i>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-static">
                    <input
                      v-model="item.description"
                      class="form-control"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="d-flex align-items-center m-0 cursor-pointer">
                  <i class="material-icons me-2 mb-1" :class="`text-${ color }`">add</i>
                  <span :class="`text-${ color }`">Dodaj załącznik</span>
                  <input type="file" class="d-none" @change="createAttachment(sale.newAttachments, $event)">
                </label>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-3 text-sm font-weight-bold text-end text-danger">{{ error }}</p>

            <div class="d-flex justify-content-end my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPatchSale()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="sale = null"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Podgląd oferty -->
      <div v-if="sale && sale.operation === 'overview'" class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h5>{{ sale.name }}</h5>
          </div>
          <div class="card-body py-0">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Data zamknięcia: <span class="font-weight-normal ms-2">{{ moment(sale.closedAt).format("DD/MM/YYYY") }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Etap: <span class="font-weight-normal ms-2">{{ sale.stage ? sale.stage.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Grupa: <span class="font-weight-normal ms-2">{{ sale.system ? sale.system.name : "Brak" }}</span>
                </label>
              </div>
            </div>
          
            <div class="d-flex my-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="getSale(sale.id, 'edit')"
              >
                Edycja
              </material-button>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Wartość: <span class="font-weight-normal ms-2">{{ sale.value.toFixed(2) + " " + sale.currency }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiadomienie: <span class="font-weight-normal ms-2">{{ sale.reminder === "" ? "Brak" : sale.reminder === "1 day" ? "1 dzień przed" : sale.reminder === "2 days" ? "2 dni przed" : sale.reminder === "5 days" ? "5 dni przed" : sale.reminder === "14 days" ? "14 dni przed" : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kontrahent: <span class="font-weight-normal ms-2 cursor-pointer" @click="$store.state.openedCustomer = sale.customer.id; $redirect('Kontrahenci')">{{ sale.customer ? sale.customer.displayName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Osoby odpowiedzialne: <span class="font-weight-normal ms-2">{{ sale.member ? sale.member.fullName : "Brak" }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Pozostałe</h5>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane zadania: 
                  <span v-if="sale.tasks.length === 0" class="font-weight-normal ms-2 cursor-pointer">Brak</span>
                  <span
                    v-for="(item, index) in sale.tasks"
                    v-else
                    :key="item"
                    class="font-weight-normal ms-2 cursor-pointer"
                    @click="$store.state.openedTask = item.id; $redirect('Zadania')"
                  >
                    {{ item.name }}
                    <span v-if="index < sale.tasks.length - 1">, </span>
                  </span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Powiązane wydarzenia: 
                  <span v-if="sale.events.length === 0" class="font-weight-normal ms-2 cursor-pointer">Brak</span>
                  <span
                    v-for="(item, index) in sale.events"
                    v-else
                    :key="item"
                    class="font-weight-normal ms-2 cursor-pointer"
                    @click="$store.state.openedEvent = item.id; $redirect('Kalendarz')"
                  >
                    {{ item.title }}
                    <span v-if="index < sale.events.length - 1">, </span>
                  </span>
                </label>
              </div>
            </div>

            <div class="row" :class="sale.inputs.length !== 0 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Notatka: <span class="font-weight-normal ms-2">{{ sale.note }}</span>
                </label>
              </div>
            </div>

            <div v-for="(item, index) in sale.inputs" :key="item" class="row" :class="index !== sale.inputs.length - 1 ? 'mb-2' : ''">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  {{ item.name }}: <span class="font-weight-normal ms-2">{{ item.value }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3">Załączniki</h5>

            <div v-if="sale.attachments && sale.attachments.length === 0" class="text-sm">Brak załączników</div>

            <div v-for="(item, index) in sale.attachments" v-else :key="item" :class="index !== sale.attachments.length - 1 ? `mb-3` : ``">
              <div class="row mb-1">
                <div class="col-12 d-flex align-items-center text-xxs">
                  <i class="material-symbols-outlined text-lg me-2">attachment</i>
                  <a :href="'data:' + item.fileType + ';base64,' + item.fileBlob" class="me-2 text-sm" :download="item.fileName">
                    {{ item.fileName }}
                  </a>
                  ({{ item.fileSize }} KB)
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-sm">
                  {{ item.description }}
                </div>
              </div>
            </div>

            <h5 class="mt-3">Aktywność</h5>

            <div class="d-flex mb-3">
              <label class="form-label ms-0 me-3 my-auto">Pokaż</label>
              <select
                v-model="sale.activity"
                class="p-1 text-sm"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                style="border: none; border-radius: 10px"
                :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
                @change="order = false"
              >
                <option value="history">Historia</option>
                <option value="comments">Komentarze</option>
                <option value="offers">Oferty</option>
                <option value="stages">Etapy</option>
              </select>

              <label class="d-flex ms-auto my-auto text-xs cursor-pointer" @click="order = !order; getSaleHistory(sale.id); getSaleComments(sale.id); getSaleOffers(sale.id); getSaleStageHistories(sale.id)">
                <span class="my-auto">{{ order ? 'Od najstarszych' : 'Od najnowszych' }}</span>
                <i class="material-icons my-auto">{{ order ? 'expand_more' : 'expand_less' }}</i>
              </label>
            </div>

            <!-- Historia -->
            <div v-if="sale.activity === 'history'">
              <div class="row">
                <timeline-list class="bg-transparent shadow-none">
                  <TimelineItem
                    v-for="item in sale.histories"
                    :key="item"
                    :full-name="item.owner.fullName"
                    :avatar="item.owner.avatar"
                    :activity="item.subject"
                    :date-time="item.createdAt"
                    :description="item.text"
                  />
                </timeline-list>
              </div>
            </div>

            <!-- Komentarze -->
            <div v-if="sale.activity === 'comments'">
              <div class="row" :class="sale.comments.length === 0 ? 'mb-4' : ''">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <material-avatar
                      :img="$getAvatar(me.fullName, me.avatar)"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />
                    
                    <div class="input-group input-group-outline">
                      <input
                        v-model="comment"
                        class="form-control mx-3"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                      />
                    </div>

                    <material-button
                      class="px-4"
                      :color="color"
                      variant="gradient"
                      @click="postSaleComment(sale.id)"
                    >
                      +
                    </material-button>
                  </div>
                </div>
              </div>

              <hr v-if="sale.comments.length !== 0" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'">
              
              <div v-for="item in sale.comments" :key="item" class="row mb-2">
                <div class="col-12">
                  <div class="d-flex">
                    <material-avatar
                      :img="$getAvatar(item.owner.fullName, item.owner.avatar)"
                      class="me-3"
                      alt="bruce"
                      size="sm"
                      shadow="sm"
                      circular
                    />
                    <p style="width: 100% !important">
                      <span class="text-sm d-flex">
                        <b>{{ item.owner.fullName }}</b> &nbsp;&bull; {{ item.createdAt }}
                        <i v-if="item.owner.id === me.id" class="material-icons cursor-pointer ms-auto" @click="deleteSaleComment(item.id)">delete</i>
                      </span>
                      <span class="d-block text-sm">
                        {{ item.text }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Oferty -->
            <div v-if="sale.activity === 'offers'">
              <div v-if="sale.offers.length === 0" class="d-flex align-items-center text-sm mb-3">Brak ofert</div>
              <div v-for="item in sale.offers" :key="item" class="row mb-3 cursor-pointer" @click="$redirectID('Podgląd oferty', item.id)">
                <div class="col-8 text-sm font-weight-bold">
                  {{ item.name }}
                  <p class="text-xs opacity-7 m-0 font-weight-normal">{{ moment(item.closedAt).format("DD/MM/YYYY") }}</p>
                </div>
                <div class="col-4 text-xs opacity-7 pt-1" style="text-align: right">
                  {{ item.value }} PLN
                </div>
              </div>
            </div>

            <!-- Etapy -->
            <div v-if="sale.activity === 'stages'">
              <div v-if="sale.stageHistories.length !== 0" class="row text-sm font-weight-bold mb-3">
                <div class="col-md-3">Etap</div>
                <div class="col-md-3">Od</div>
                <div class="col-md-3">Do</div>
                <div class="col-md-3">Użytkownik</div>
              </div>
              <div v-for="(item, index) in sale.stageHistories" :key="item" class="row text-sm" :class="index != sale.stageHistories.length - 1 ? 'mb-3' : 'mb-4'">
                <div class="col-md-3">{{ item.name }}</div>
                <div class="col-md-3">{{ moment(item.createdAt).format("DD/MM/YYYY") }}</div>
                <div class="col-md-3">{{ item.closedAt ? moment(item.closedAt).format("DD/MM/YYYY") : "Brak" }}</div>
                <div class="col-md-3 d-flex align-items-center">
                  <material-avatar
                    :img="$getAvatar(item.createdBy.fullName, item.createdBy.avatar)"
                    class="me-2"
                    alt="bruce"
                    size="xs"
                    shadow="xs"
                    circular
                  />
                  {{ item.createdBy.fullName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import TimelineList from "@/examples/Cards/TimelineList.vue"
import TimelineItem from "@/examples/Cards/TimelineItem.vue"
import UserDataService from "@/services/UserDataService"
import SaleDataService from "@/services/SaleDataService"
import OfferDataService from "@/services/OfferDataService"
import CustomerDataService from "@/services/CustomerDataService"
import AttachmentDataService from "@/services/AttachmentDataService"
import CustomerGroupDataService from "@/services/CustomerGroupDataService"
import HistoryDataService from "@/services/HistoryDataService"
import CommentDataService from "@/services/CommentDataService"
import StageHistoryDataService from "@/services/StageHistoryDataService"
import CalendarDataService from "@/services/CalendarDataService"
import SaleStageDataService from "@/services/SaleStageDataService"
import SaleSystemDataService from "@/services/SaleSystemDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import html2pdf from "html2pdf.js"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Sale",
  components: {
    MaterialAvatar,
    MaterialBadge,
    MaterialCheckbox,
    MaterialRadio,
    MaterialSnackbar,
    MaterialButton,
    TimelineList,
    TimelineItem,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      sale: null, // Obiekt wybranej sprzedaży

      users: [], // Tablica wszystkich użytkowników pobierana z API
      usersAC: [], // Tablica imion i nazwisk wszystkich użytkowników pobierana z API

      customers: [], // Tablica wszystkich kontrahentów pobierana z API
      customersAC: [], // Tablica nazw wszystkich kontrahentów pobierana z API

      calendars: [], // Tablica wszystkich kalendarzy pobierana z API

      comment: "", // Zmienna zawierająca treść komentarza
      order: false, // Filtr aktywności

      stageHistory: null, // Obiekt zawierający dane dotyczące etapu
      system: null, // Obiekt zawierający dane dotyczące grupy

      sales: [], // Tablica zawierająca dane sprzedaży z API
      salesAC: [], // Tablica zawierająca nazwy sprzedaży z API

      stages: [], // Tablica etapów pobierana z API
      systems: [], // Tablica systemów pobierana z API
      members: [], // Tablica osób odpowiedzialnych pobierana z API
      customersFilter: [], // Tablica kontrahentów pobierana z API

      checkedSales: [], // Tablica zawierająca wszystkie zaznaczone sprzedaże
      modals: [], // Tablica odopowiedzialna za status modali

      createdCustomer: null, // Obiekt tworzonego kontrahenta

      error: "", // Zmienna przechowująca treść błędu

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "createdAt", // Nazwa filtrowanej kolumny
      columnOrder: "desc", // Wartość filtrowania

      filter_name: "", // Filtr: Nazwa
      filter_members: [], // Filtr: Osoba odpowiedzialna
      filter_customers: [], // Filtr: Kontrahent
      filter_systems: [], // Filtr: System
      filter_stages: [], // Filtr: Etap
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    totalPLN: function() {
      let result = 0

      this.sales.forEach(item => {
        if (item.currency === "PLN") {
          result += item.value
        }
      })

      return result
    },

    totalEUR: function() {
      let result = 0

      this.sales.forEach(item => {
        if (item.currency === "€") {
          result += item.value
        }
      })

      return result
    }
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getSalesDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_SALE")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data

          this.getSales(this.page)
          this.getSalesAC()
          this.getMembers()
          this.getCustomersFilter()
          this.getSaleSystems()
          this.getSaleStages()

          if (this.$store.state.openedSale) {
            this.getSale(this.$store.state.openedSale)
            this.$store.state.openedSale = null
          }

          if (this.$store.state.createdSale.operation) {
            this.getNewSale(this.$store.state.createdSale.attachments, this.$store.state.createdSale.task, this.$store.state.createdSale.event)
            this.$store.state.createdSale.task = null
            this.$store.state.createdSale.event = null
            this.$store.state.createdSale.operation = false
            this.$store.state.createdSale.attachments = []
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje obiekt wybranej sprzedaży
    getSale(id, operation = "overview") {
      SaleDataService.get(id)
      .then(res => {
        this.sale = res.data
        this.sale.operation = operation
        this.sale.activity = "history"

        if (operation === "edit") {
          if (this.sale.stage) {
            this.sale.stageHistory = {
              id: this.sale.stageHistory.id,
              createdAt: this.moment(this.sale.stageHistory.createdAt).format("YYYY-MM-DD"),
              closedAtStatus: this.sale.stage.closedAtStatus,
              closedAt: this.moment(this.sale.stageHistory.closedAt).format("YYYY-MM-DD"),
              calendarStatus: this.sale.stage.calendarStatus,
              calendar: this.sale.stageHistory.calendar ? this.sale.stageHistory.calendar.id : "",
            }

            this.sale.stage = this.sale.stage.id
          }
          else {
            this.sale.stageHistory = null
            this.sale.stage = ""
          }

          this.sale.system = this.sale.system ? this.sale.system.id : ""
          this.sale.reminder = this.sale.reminder ? this.sale.reminder : ""
        }

        this.getUsers()
        this.getCustomers()
        this.getCalendars()

        this.getSaleHistory(res.data.id)
        this.getSaleComments(res.data.id)
        this.getSaleOffers(res.data.id)
        this.getSaleStageHistories(res.data.id)

        this.sale.newInputs = []
        this.sale.removedInputs = []
        this.sale.newAttachments = []
        this.sale.removedAttachments = []

        this.error = ""
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca obiekt nowej sprzedaży
    getNewSale(attachments = [], task = null, event = null) {
      this.sale = null
      this.sale = {
        name: "",
        value: "",
        currency: "PLN",
        stage: "",
        stageHistory: null,
        system: "",
        member: null,
        customer: null,
        closedAt: "",
        reminder: "",
        note: "",
        inputs: [],
        attachments: attachments,
        task: task,
        event: event,
        operation: "new",
      }

      this.getUsers()
      this.getCustomers()
      this.getCalendars()

      this.error = ""
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.users = []
        this.usersAC = []

        res.data["hydra:member"].forEach(item => {
          if (this.sale.member && item.id !== this.sale.member.id) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
          
          if (!this.sale.member) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich kontrahentów z API
    getCustomers() {
      CustomerDataService.getAll("")
      .then(res => {
        this.customers = []
        this.customersAC = []

        res.data["hydra:member"].forEach(item => {
          if (this.sale.customer && item.id !== this.sale.customer.id) {
            this.customers.push(item)
            this.customersAC.push(item.displayName)
          }
          
          if (!this.sale.customer) {
            this.customers.push(item)
            this.customersAC.push(item.displayName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich kontrahentów z API
    getCustomersFilter() {
      SaleDataService.getAll("")
      .then(res => {
        this.customersFilter = []

        res.data["hydra:member"].forEach(sale => {
          let exist = false

          this.customersFilter.forEach(customer => {
            if (sale.customer.displayName === customer.displayName) {
              exist = true
            }
          })

          if (!exist) {
            this.customersFilter.push(sale.customer)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie kalendarze z API
    getCalendars() {
      CalendarDataService.getAll(`?members.id=${ this.me.id }`)
      .then(res => {
        this.calendars = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wprowadzonych danych
    validationPostSale() {
      let success = true
      this.error = ""

      if (this.sale.name === "") {
        success = false
        this.error = "Nazwa sprzedaży nie może być pusta!"
      }

      if (this.sale.name.length > 65) {
        success = false
        this.error = "Nazwa sprzedaży jest za długa (max. 65 znaków)"
      }

      this.sale.value = this.sale.value ? parseFloat(this.sale.value) : 0

      if (this.sale.stage === "") {
        success = false
        this.error = "Wybierz etap zamówienia!"
      }

      if (this.sale.closedAt === "") {
        success = false
        this.error = "Wybierz datę zakończenia zamówienia!"
      }

      if (this.sale.member === null) {
        success = false
        this.error = "Wybierz osobę odpowiedzialną!"
      }

      if (this.sale.customer === null) {
        success = false
        this.error = "Wybierz kontrahenta!"
      }

      this.sale.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      if (success) {
        this.postSale()
      }
    },

    // Funkcja dodająca nową sprzedaż do API
    postSale() {
      SaleDataService.post(this.sale)
      .then(res => {
        console.log(res.data)

        this.sale.attachments.forEach(item => {
          this.postAttachment(res.data.id, item)
        })

        this.getSales(this.page)
        this.getSalesAC()
        this.sale = null
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchSale() {
      let success = true
      this.error = ""

      if (this.sale.name === "") {
        success = false
        this.error = "Nazwa sprzedaży nie może być pusta!"
      }

      if (this.sale.name.length > 65) {
        success = false
        this.error = "Nazwa sprzedaży jest za długa (max. 65 znaków)"
      }

      this.sale.value = this.sale.value ? parseFloat(this.sale.value) : 0

      if (this.sale.stage === "") {
        success = false
        this.error = "Wybierz etap zamówienia!"
      }

      if (this.sale.closedAt === "") {
        success = false
        this.error = "Wybierz datę zakończenia zamówienia!"
      }

      if (this.sale.member === null) {
        success = false
        this.error = "Wybierz osobę odpowiedzialną!"
      }

      if (this.sale.customer === null) {
        success = false
        this.error = "Wybierz kontrahenta!"
      }

      this.sale.inputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      this.sale.newInputs.forEach(item => {
        if (item.value.length >= 255) {
          success = false
          this.error = "Zawartość pola jest za długa (max. 255 znaków)"
        }
      })

      if (success) {
        this.patchSale()
      }
    },

    // Funkcja edytująca sprzedaż
    patchSale() {
      SaleDataService.patch(this.sale.id, this.sale,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.sale.newAttachments.forEach(item => {
          this.postAttachment(this.sale.id, item)
        })
 
        this.snackbar = "success"

        this.getSales(this.page)
        this.getSalesAC()
        this.sale = null
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowy etap wybranej sprzedaży
    getNewSaleStageHistory(id) {
      SaleStageDataService.get(id)
      .then(res => {
        this.sale.stageHistory = {
          createdAt: this.moment().format("YYYY-MM-DD"),
          closedAtStatus: res.data.closedAtStatus,
          closedAt: this.moment().add(7, "days").format("YYYY-MM-DD"),
          calendarStatus: res.data.calendarStatus,
          calendar: "",
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wybierająca etap w wybranej sprzedaży
    getSaleStageHistory(id) {
      SaleStageDataService.get(id)
      .then(res => {
        this.stageHistory = {
          stage: id,
          name: res.data.name,
          createdAt: this.moment().format("YYYY-MM-DD"),
          closedAtStatus: res.data.closedAtStatus,
          closedAt: this.moment().add(7, "days").format("YYYY-MM-DD"),
          calendarStatus: res.data.calendarStatus,
          calendar: "",
        }

        this.getCalendars()
        this.openModal(0)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca etap w sprzedaży
    onChangeSaleStage(id) {
      this.stageHistory.id = id
      SaleDataService.stage(id, this.stageHistory, 
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getSales(this.page)
        this.getSalesAC()
        this.stageHistory = null
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja aktualizująca wiele etapów w sprzedaży
    onChangeSaleStages() {
      this.checkedSales.forEach(item => {
        this.onChangeSaleStage(item.id)
      })
    },

    // Funkcja tworząca obiekt nowego systemu
    getNewSaleSystem() {
      this.system = {
        name: "",
        error: ""
      }

      this.openModal(1)
    },

    // Funkcja tworząca nowy system
    postSaleSystem() {
      if (this.system.name !== "") {
        if (this.system.name.length <= 65) {
          SaleSystemDataService.post(
            {
              name: this.system.name
            }
          )
          .then(res => {
            console.log(res.data)
            this.getSaleSystems()
            this.closeModal()
          })
          .catch(error => {
            console.log(error)

            if(JSON.stringify(error.response.status) == 422) {
              this.system.error = "Nazwa grupy jest już zajęta!"
            }
          })
        }
        else {
          this.system.error = "Nazwa grupy jest za długa (max. 65 znaków)!"
        }
      }
      else {
        this.system.error = "Nazwa nie może być pusta!"
      }
    },

    // Funkcja usuwająca wybrany system
    deleteSaleSystem(id) {
      SaleSystemDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getSaleSystems()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.status) === 500) {
          this.openModal(3)
        }
      })
    },

    // Funkcja exportująca sprzedaż do pliku
    exportSales() {
      const element = document.getElementById("sales-pdf")
      const parameters = {
        margin: 10,
        filename: "sales.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { format: "a4", orientation: "portrait", putOnlyUsedFonts: true, floatPrecision: "smart" }
      }

      html2pdf().from(element).set(parameters).save()
    },

    // Funkcja dodająca załącznik do listy
    createAttachment(array, event) {
      array.push(
        {
          file: event.target.files[0],
          description: ""
        }
      )
    },

    // Funkcja tworząca nowy załącznik
    postAttachment(id, item) {
      const formData = new FormData()
      formData.append("file", item.file)
      formData.append("description", item.description)
      formData.append("entity", "Sale")
      formData.append("entityId", id)

      AttachmentDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowego kontrahenta
    getNewCustomer() {
      CustomerGroupDataService.getAll("")
      .then(res => {
        this.createdCustomer = {
          type: "Company",
          displayName: "",
          groups: [],
          error: "",
        }

        this.createdCustomer.groups = res.data["hydra:member"]
        this.createdCustomer.groups.forEach(item => {
          item.status = false
        })

        this.openModal(4)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca do API nowego kotnrahenta
    postNewCustomer() {
      this.createdCustomer.error = ""
      if (this.createdCustomer.displayName !== "") {
        if (this.createdCustomer.displayName.length <= 65) {
          if (this.createdCustomer.groups.some(group => group.status === true)) {
            CustomerDataService.post(this.createdCustomer)
            .then(res => {
              if (this.sale.customer) {
                this.customers.push(this.sale.customer)
                this.customersAC.push(this.sale.customer.displayName) 
              }

              this.sale.customer = res.data
              this.closeModal()
            })
            .catch(error => {
              console.log(error)

              if (JSON.stringify(error.response.status) == 400) {
                this.createdCustomer.error = error.response.data.error
              }
            })
          }
          else {
            this.createdCustomer.error = "Kontrahent musi należeć do min. 1 grupy"
          }
        }
        else {
          this.createdCustomer.error = "Nazwa kontrahenta jest za długa (max. 65 znaków)"
        }
      }
      else {
        this.createdCustomer.error = "Podaj nazwę kontrahenta!"
      }
    },

    // Funkcja pobierająca historie wybranej sprzedaży
    getSaleHistory(id) {
      let value = this.order ? "asc" : "desc"
      HistoryDataService.getAll(`?page=1&itemsPerPage=10&order[createdAt]=${ value }&sale.id=${ id }`)
      .then(res => {
        this.sale.histories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca historie do wybranej sprzedaży
    postSaleHistory(id, subject, text) {
      HistoryDataService.post(
        {
          owner: `/users/${ this.me.id }`,
          subject: subject,
          text: text,
          createdAt: this.moment().format(),
          sale: `/sales/${ id }`
        }
      )
      .then(res => {
        console.log(res.data)
        this.getSaleHistory(id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca komentarze wybranej sprzedaży
    getSaleComments(id) {
      let value = this.order ? "asc" : "desc"
      CommentDataService.getAll(`?sale.id=${ id }&order[createdAt]=${ value }`)
      .then(res => {
        this.sale.comments = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca komentarz
    postSaleComment(id) {
      if (this.comment !== "") {
        CommentDataService.post(
          {
            owner: `/users/${ this.me.id }`,
            text: this.comment,
            createdAt: this.moment().format(),
            sale: `/sales/${ id }`
          }
        )
        .then(res => {
          console.log(res.data)
          this.postSaleHistory(id, "dodał/a komentarz", res.data.text)
          this.getSaleComments(id)
          this.comment = ""
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja usuwająca komentarz
    deleteSaleComment(id) {
      CommentDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getSaleComments(this.sale.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca oferty wybranej sprzedaży
    getSaleOffers(id) {
      let value = this.order ? "asc" : "desc"
      OfferDataService.getAll(`?sale.id=${ id }&order[createdAt]=${ value }`)
      .then(res => {
        this.sale.offers = res.data["hydra:member"]
        this.sale.offers.forEach(offer => {
          let discountServices = offer.discountServices > 100 ? 100 : offer.discountServices
          let discountProducts = offer.discountProducts > 100 ? 100 : offer.discountProducts
          let discountExternals = offer.discountExternals > 100 ? 100 : offer.discountExternals

          offer.value = 0
          offer.products.forEach(product => {
            if (product.type === "service") {
              offer.value += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountServices / 100))
            }

            if (product.type === "product") {
              offer.value += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountProducts / 100))
            }

            if (product.type === "external") {
              offer.value += (product.price * product.rbh * product.quantity) - ((product.price * product.rbh * product.quantity) * (discountExternals / 100))
            }
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca historię etapów wybranej sprzedaży
    getSaleStageHistories(id) {
      let value = this.order ? "asc" : "desc"
      StageHistoryDataService.getAll(`?sale.id=${ id }&order[createdAt]=${ value }`)
      .then(res => {
        this.sale.stageHistories = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca sprzedaż
    deleteSale(id) {
      SaleDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getSales(this.page)
        this.getSalesAC()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }

        if(JSON.stringify(error.response.status) == 500) {
          this.openModal(3)
        }
      })
    },

    // Funckja usuwająca wiele sprzedaży
    deleteSales() {
      this.checkedSales.forEach(item => {
        this.deleteSale(item.id)
      })
    },

    // Funkcja przypisująca wybranego użytkownika do sprzedaży
    selectUser(item) {
      this.users.forEach((user, index) => {
        if (item === user.fullName) {
          if (this.sale.member) {
            this.users.push(this.sale.member)
            this.usersAC.push(this.sale.member.fullName) 
          }

          this.sale.member = user
          this.users.splice(index, 1)
          this.usersAC.splice(index, 1)
        }
      })

      this.$refs.usersRef.clearInput()
    },

    // Funkcja przypisująca wybranego kontrahenta do sprzedaży
    selectCustomer(item) {
      this.customers.forEach((customer, index) => {
        if (item === customer.displayName) {
          if (this.sale.customer) {
            this.customers.push(this.sale.customer)
            this.customersAC.push(this.sale.customer.displayName) 
          }

          this.sale.customer = customer
          this.customers.splice(index, 1)
          this.customersAC.splice(index, 1)
        }
      })

      this.$refs.customersRef.clearInput()
    },

    // Funkcja wczytuje przefiltrowane dane sprzedaży z API
    getSales(page) {
      let member = ""
      this.filter_members.forEach(item => {
        member += `&member.fullName[]=${ item }`
      })

      let customer = ""
      this.filter_customers.forEach(item => {
        customer += `&customer.displayName[]=${ item }`
      })

      let system = ""
      this.filter_systems.forEach(item => {
        system += `&system.name[]=${ item }`
      })

      let stage = ""
      this.filter_stages.forEach(item => {
        stage += `&stage.name[]=${ item }`
      })

      SaleDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ member }${ customer }${ system }${ stage }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.sales = res.data["hydra:member"]
        console.log(this.sales)
        this.unselectSales()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane sprzedaży z API z opóźnieniem
    getSalesDelay: _.debounce(function(page) {
      let member = ""
      this.filter_members.forEach(item => {
        member += `&member.fullName[]=${ item }`
      })

      let customer = ""
      this.filter_customers.forEach(item => {
        customer += `&customer.displayName[]=${ item }`
      })

      let system = ""
      this.filter_systems.forEach(item => {
        system += `&system.name[]=${ item }`
      })

      let stage = ""
      this.filter_stages.forEach(item => {
        stage += `&stage.name[]=${ item }`
      })

      SaleDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ member }${ customer }${ system }${ stage }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.sales = res.data["hydra:member"]
        this.unselectSales()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja pobierająca nazwy sprzedaży z API
    getSalesAC() {
      SaleDataService.getAll("")
      .then(res => {
        this.salesAC = []
        res.data["hydra:member"].forEach(item => {
          this.salesAC.push(item.name)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getMembers() {
      UserDataService.getAll("?order[fullName]=ASC")
      .then(res => {
        this.members = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie systemy z API
    getSaleSystems() {
      SaleSystemDataService.getAll("?order[name]=ASC")
      .then(res => {
        this.systems = res.data["hydra:member"]        
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie etapy z API
    getSaleStages() {
      SaleStageDataService.getAll("")
      .then(res => {
        this.stages = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca zaznaczaną sprzedaż do listy
    selectSale(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedSales.push(item)
      }
      else {
        this.checkedSales.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedSales.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkie sprzedaże
    selectSales(event) {
      this.checkedSales = []
      if(event.target.checked) {
        this.sales.forEach(item => {
          item.mark = true
          this.checkedSales.push(item)
        })
      }
      else {
        this.sales.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkie sprzedaże
    unselectSales() {
      this.checkedSales = []
      this.sales.forEach(item => {
        item.mark = false
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getSales(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeMember(event) {
      let exist = false

      this.filter_members.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_members.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_members.push(event)
      }

      this.getSales(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeCustomer(event) {
      let exist = false

      this.filter_customers.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_customers.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_customers.push(event)
      }

      this.getSales(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeSystem(event) {
      let exist = false

      this.filter_systems.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_systems.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_systems.push(event)
      }

      this.getSales(1)
    },

    // Funkcja przypisująca do zmiennej wybrany filtr
    onChangeStage(event) {
      let exist = false

      this.filter_stages.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_stages.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_stages.push(event)
      }

      this.getSales(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getSales(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getSales(page)
      }
      else {
        this.getSales(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getSales(page)
      }
      else {
        this.getSales(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder == "asc") {
        this.columnOrder = "desc"
        this.getSales(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getSales(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>