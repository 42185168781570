import http from "@/http-common"

class PrintTemplateDataService {
    getAll(url) {
        return http.get(`print_templates${ url }`)
    }

    get(id) {
        return http.get(`print_templates/${ id }`)
    }

    post(data) {
        return http.post('print_templates', data)
    }

    patch(id, data, headers) {
        return http.patch(`print_templates/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`print_templates/${ id }`)
    }
}

export default new PrintTemplateDataService()