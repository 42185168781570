<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1 d-flex align-items-center">
            <h5 class="me-3" :class="table === 'Calendars' ? '' : 'opacity-3 cursor-pointer'" @click="table !== 'Calendar' ? onChangeTable('Calendars') : null">Kalendarze</h5>
            <h5 :class="table === 'Tasks' ? '' : 'opacity-3 cursor-pointer'" @click="table !== 'Tasks' ? onChangeTable('Tasks') : null">Zadania</h5>
          </div>
          <div class="card-body pt-1 overflow-auto">
            <table border="1" class="text-sm">
              <tr>
                <td
                  rowspan="2"
                  class="font-weight-bold px-3 py-2"
                  style="position: -webkit-sticky; position: sticky; left: -30px; z-index: 100;"
                  :style="isDarkMode ? 'background: linear-gradient(to right, #202940 calc(100% - 1px), white calc(100% - 1px))' : 'background: linear-gradient(to right, white calc(100% - 1px), black calc(100% - 1px))'"
                >
                  {{ table === "Calendars" ? "Kalendarz" : "Zadania" }}
                </td>
                <td
                  v-for="user in users"
                  :key="user"
                  class="text-center px-3 py-2"
                >
                  {{ user.fullName }}
                </td>
              </tr>

              <tr>
                <td v-for="user in users" :key="user">
                  <div v-if="table === 'Calendars'" class="d-flex align-items-center justify-content-between">
                    <div class="w-50 py-1 text-center" style="border-right: 1px solid">O</div>
                    <div class="w-50 py-1 text-center">Z</div>
                  </div>

                  <div v-else class="d-flex align-items-center justify-content-between">
                    <div class="w-100 py-1 text-center">O</div>
                  </div>
                </td>
              </tr>

              <tr v-for="item in items" :key="item">
                <td
                  class="px-3 py-2"
                  style="position: -webkit-sticky; position: sticky; left: -30px; z-index: 100;"
                  :style="isDarkMode ? 'background: linear-gradient(to right, #202940 calc(100% - 1px), white calc(100% - 1px))' : 'background: linear-gradient(to right, white calc(100% - 1px), black calc(100% - 1px))'"
                >
                  {{ item.name }}
                </td>
                <td v-for="member in item.members" :key="member" class="text-center">
                  <div v-if="table === 'Calendars'">
                    <div v-if="member.id == item.owner.id" class="d-flex align-items-center justify-content-between">
                      <div class="w-50 py-1 text-center" style="border-right: 1px solid;">
                        <i class="material-symbols-outlined mt-1 text-lg">{{ member.readable ? "done" : "&nbsp;" }}</i>
                      </div>

                      <div class="w-50 py-1 text-center">
                        <i class="material-symbols-outlined mt-1 text-lg">{{ member.writable ? "done" : "&nbsp;" }}</i>
                      </div>
                    </div>

                    <div v-else class="d-flex align-items-center justify-content-between">
                      <div class="w-50 py-1 text-center cursor-pointer" style="border-right: 1px solid">
                        <material-checkbox
                          :checked="member.readable"
                          @change="member.readable = $event.target.checked; !member.readable ? member.writable = false : null"
                        />
                      </div>

                      <div class="w-50 py-1 text-center cursor-pointer">
                        <material-checkbox
                          :checked="member.writable"
                          @change="member.writable = $event.target.checked; member.writable ? member.readable = true : null"
                        />                      
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div v-if="member.id == item.owner.id" class="d-flex align-items-center justify-content-between">
                      <div class="w-100 py-1 text-center">
                        <i class="material-symbols-outlined mt-1 text-lg">{{ member.readable ? "done" : "&nbsp;" }}</i>
                      </div>
                    </div>

                    <div v-else class="d-flex align-items-center justify-content-between">
                      <div class="w-100 py-1 text-center cursor-pointer">
                        <material-checkbox
                          :checked="member.readable"
                          @change="member.readable = $event.target.checked"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div class="d-flex justify-content-end m-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="table === 'Calendars' ? patchCalendars() : patchTasks()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="getUsers()"
              >
                Resetuj
              </material-button>
            </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import CalendarDataService from "@/services/CalendarDataService"
import TaskGroupDataService from "@/services/TaskGroupDataService"
import { mapState } from "vuex"

export default {
  name: "Permissions",
  components: {
    MaterialCheckbox,
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      table: "Calendars",

      users: [], // Tablica użytkowników pobierana z API
      items: [], // Tablica kalendarzy pobierana z API

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else if (!res.data.roles.includes("ROLE_PERMISSIONS")) {
          this.$redirect("Kokpit")
        }
        else {
          this.me = res.data
          this.getUsers()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.users = res.data["hydra:member"]

        if (this.table === "Calendars") {
          this.getCalendars()
        }
        else {
          this.getTasks()
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca wybraną tabelę
    onChangeTable(table) {
      this.table = table

      if (table === "Calendars") {
        this.getCalendars()
      }
      else {
        this.getTasks()
      }
    },

    // Funkcja pobierająca wszystkie kalendarze z API
    getCalendars() {
      CalendarDataService.getAll("")
      .then(res => {
        this.items = []
        res.data["hydra:member"].forEach(calendar => {
          if (calendar.members.length > 0) {
            let users = []
            this.users.forEach(user => {
              users.push({
                id: user.id,
                readable: false,
                writable: false,
              })
            })

            users.forEach(user => {
              calendar.members.forEach(member => {
                if (user.id === member.id) {
                  user.readable = true
                }
              })

              calendar.permissions.forEach(permission => {
                if (user.id === permission.id) {
                  user.writable = true
                }
              })
            })

            calendar.members = users
            this.items.push(calendar)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrany kalendarz
    patchCalendar(calendar) {
      let members = []
      let permissions = []

      calendar.members.forEach(member => {
        if (member.readable) {
          members.push(`/users/${ member.id }`)
        }

        if (member.writable) {
          permissions.push(`/users/${ member.id }`)
        }
      })

      CalendarDataService.patch(calendar.id,
        {
          members: members,
          permissions: permissions,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.snackbar = "success"
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrane kalendarze
    patchCalendars() {
      this.items.forEach(calendar => {
        this.patchCalendar(calendar)
      })
    },

    // Funkcja pobierająca wszystkie zadania z API
    getTasks() {
      TaskGroupDataService.getAll("")
      .then(res => {
        this.items = []
        res.data["hydra:member"].forEach(task => {
          if (task.members.length > 0) {
            let users = []
            this.users.forEach(user => {
              users.push({
                id: user.id,
                readable: false,
              })
            })

            users.forEach(user => {
              task.members.forEach(member => {
                if (user.id === member.id) {
                  user.readable = true
                }
              })
            })

            task.members = users
            this.items.push(task)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrane zadanie
    patchTask(task) {
      let members = []

      task.members.forEach(member => {
        if (member.readable) {
          members.push(`/users/${ member.id }`)
        }
      })

      TaskGroupDataService.patch(task.id,
        {
          members: members,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.snackbar = "success"
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrane zadania
    patchTasks() {
      this.items.forEach(task => {
        this.patchTask(task)
      })
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

td {
  border: 1px solid;
}
</style>